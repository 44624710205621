// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
  max-height: calc(50% - 2em);
  overflow-y: scroll;
  margin-top: 1em;
  margin-bottom: 1em;
}

.title {
  margin: 0.5em;
}

.pointer {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/user-assign-mask/user-assign-mask.component.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":[".list {\n  max-height: calc(50% - 2em);\n  overflow-y: scroll;\n  margin-top: 1em;\n  margin-bottom: 1em;\n}\n\n.title {\n  margin: .5em;\n}\n\n.pointer {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
