import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Property } from '@core/models/interfaces/property.interface';
import { Router } from '@angular/router';
import { apiSettings } from '@env/environment';
import { UnitRelationship } from '@core/models/interfaces/unit-relationship.interface';
import { Unit } from '@core/models/interfaces/unit.interface';
import { Image } from '@core/models/datatypes/image.interface';
import { getThumbnailUrl } from '@core/models/utils/media.utils';
import { Logger } from '@core/logger/logger.service';
import { getTranslationForPropertyType } from '@core/models/utils/type.translation';
import { ProblemService } from '@app/@core/api/problem-service/problem.service';
import { UsesCacheComponent } from '@app/@core/api/cache/uses-cache';
import { CacheService } from '@app/@core/api/cache/cache.service';
import { TranslateService } from '@ngx-translate/core';

const logger = new Logger('a.@s.c.p.PropertyCardComponent');

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.scss'],
})
export class PropertyCardComponent extends UsesCacheComponent implements OnInit, OnChanges {

  @Input() property: Property;
  @Input() isCardClickable = false;
  @Input() showMenu = true;
  @Input() showTenantStats = true;
  @Input() invitationMode = false;
  @Output() editClicked = new EventEmitter<Property>();
  @Output() deleteClicked = new EventEmitter<Property>();
  @Output() editManagersClicked = new EventEmitter<Property>();
  @Output() reportProblemClicked = new EventEmitter<Property>();

  private readonly internalUniqueId: string;
  private internalUsage: number;
  private internalPresentableUnits: Unit[] = [];
  private internalAdditionalUnits = 0;
  private internalProblemCount = 0;

  constructor(
    private readonly router: Router,
    private readonly problemService: ProblemService,
    protected readonly cacheService: CacheService,
    private translateService :TranslateService,
  ) {
    super(cacheService);
    this.internalUniqueId = 'card-' + (Math.random() * 1000).toString(16);
    this.internalUsage = 0;
  }

  get usage() {
    return this.internalUsage;
  }

  get id() {
    return this.internalUniqueId;
  }

  get additionalUnits() {
    return this.internalAdditionalUnits;
  }

  get presentableUnits() {
    return this.internalPresentableUnits;
  }

  get problemCount() {
    return this.internalProblemCount;
  }

  private static isRelationshipActive(relationship: UnitRelationship) {
    const now = new Date();
    const start = new Date(relationship.startDate);
    const end = relationship.endDate ? new Date(relationship.endDate) : null;
    if (!end) {
      return start <= now;
    }
    return start <= now && end >= now;
  }

  public trackById(_, entity: { id: number }) {
    return entity.id;
  }

  async ngOnInit() {
    this.checkIfUnitsAreRentedOut();
    await this.getOpenProblems();
    this.isInitialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (!changes[propName]) {continue;}

      const change = changes[propName];

      const curVal = JSON.stringify(change.currentValue);
      const prevVal = JSON.stringify(change.previousValue);
      const changeLog = `${propName}: currentValue = ${curVal}, previousValue = ${prevVal}`;
      logger.debug(changeLog);

      if (propName === 'property') {
        this.checkIfUnitsAreRentedOut();
      }
    }

  }

  getBackgroundImage() {
    const notFoundUrl = 'assets/images/media-not-found.png';
    const notFound = `background-image: url(${notFoundUrl})`;

    if (!this.property.photo) {
      return notFound;
    }

    const urls = [
      this.property.photo?.formats?.large?.url,
      this.property.photo?.formats?.medium?.url,
      this.property.photo?.formats?.small?.url,
      this.property.photo?.formats?.thumbnail?.url,
    ];

    const sizes = urls.map((url) => {
      if (!url) {
        return undefined;
      }
      return `url(${apiSettings.baseUrl}${url})`;
    }).filter(a => a !== undefined);

    if (sizes.length) {
      return `background-image: ${sizes.join(',')};`;
    } else {
      return notFound;
    }
  }

  edit() {
    this.editClicked.emit(this.property);
  }

  delete() {
    this.deleteClicked.emit(this.property);
  }

  editManagers() {
    this.editManagersClicked.emit(this.property);
  }

  async showDetails() {
    await this.router.navigate(['/', 'app', 'properties', this.property.id]);
  }

  public getPicture(profilePicture: Image): string {
    return getThumbnailUrl(profilePicture) ?? '/assets/images/avatar.svg';
  }

  public getTranslationForType(type: string) {
    return getTranslationForPropertyType(type, this.translateService);
  }

  public createProblem() {
    this.reportProblemClicked.emit(this.property);
  }

  async onCacheCleared(lastRefresh: Date): Promise<void> {
    this.checkIfUnitsAreRentedOut();
    await this.getOpenProblems();
  }

  private checkIfUnitsAreRentedOut() {
    this.internalAdditionalUnits = 0;
    this.internalPresentableUnits = [];

    const availableUnits = this.property.units?.length ?? 0;
    if (availableUnits === 0) {
      return;
    }

    let currentlyRentedUnits = 0;

    this.property?.units?.forEach((unit) => {
      this.internalAdditionalUnits++;
      for (const relationship of unit?.relationships) {
        if (relationship.rentee != null && PropertyCardComponent.isRelationshipActive(relationship)) {
          currentlyRentedUnits++;
          if (this.internalPresentableUnits.length < 3) {
            this.internalPresentableUnits.push({
              ...unit,
              relationships: [relationship],
            } as Unit);
            this.internalAdditionalUnits--;
            break;
          }
        }
      }
    });
    if (!this.internalPresentableUnits?.length) {
      this.internalAdditionalUnits = 0;
    }

    this.internalUsage = currentlyRentedUnits / availableUnits;
  }

  private async getOpenProblems() {
    if (!this.showTenantStats) {
      this.internalProblemCount = 0;
      return;
    }

    try {
      const result = await this.problemService.getProblemCountByPropertyId(this.property.id);
      this.internalProblemCount = result.openOrInProgress.count;
    } catch (e) {
      logger.error(e);
      this.internalProblemCount = 0;
    }
  }
}
