// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-card {
  display: flex;
  align-items: center;
}
.flex-card ion-button {
  margin-left: auto;
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
}

.justify-self-end {
  justify-self: flex-end;
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/contractor-company-card/contractor-company-card.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EAEA,mBAAA;AAAF;AAEE;EACE,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;AAAJ;;AAIA;EACE,sBAAA;EACA,iBAAA;AADF","sourcesContent":[".flex-card {\n  display: flex;\n\n  align-items: center;\n\n  ion-button {\n    margin-left: auto;\n    --padding-top: 0;\n    --padding-bottom: 0;\n    --padding-start: 0;\n    --padding-end: 0;\n  }\n}\n\n.justify-self-end {\n  justify-self: flex-end;\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
