import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Unit } from '@app/@core/models/interfaces/unit.interface';
import { AlertController, ModalController } from '@ionic/angular';
import { Meter } from '@core/models/interfaces/meter.interface';
import { MeterService } from '@app/@core/api/meter/meter.service';
import { getTranslationForMeterType } from '@core/models/utils/type.translation';
import { Logger } from '@core/logger/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

const logger = new Logger('a.@s.c.m.MeterMaskComponent');

@Component({
  selector: 'app-meter-mask',
  templateUrl: './meter-mask.component.html',
  styleUrls: ['./meter-mask.component.scss'],
})
export class MeterMaskComponent implements OnInit {
  public static MODAL_ID = 'meter-mask-component';

  @Input() unit: Unit;
  @Input() meter?: Meter;

  public types: string[];

  private form?: UntypedFormGroup;

  constructor(
    private readonly modalController: ModalController,
    private readonly meterService: MeterService,
    private readonly alertController: AlertController,
    private readonly formBuilder: UntypedFormBuilder,
    private translateService :TranslateService,
    ) {
  }

  get meterForm() {
    return this.form;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      identifier: [this.meter?.identifier ?? '', [Validators.required]],
      type: [this.meter?.type ?? '', [Validators.required]],
    });

    this.meterService.getMeterTypes().then((types) => this.types = types);
  }

  async dismiss() {
    await this.modalController.dismiss(undefined, undefined, MeterMaskComponent.MODAL_ID);
  }

  async submitMeter() {
    if (this.form.invalid || this.form.disabled) {
      return;
    }
    this.form.disable();

    const { identifier, type } = this.form.value;
    try {
      await this.modalController.dismiss({
        identifier,
        type,
      }, this.meter ? 'edit' : 'add', MeterMaskComponent.MODAL_ID);
      this.form.reset();
    } catch (e) {
      logger.error(e);
    } finally {
      this.form?.enable();
    }
  }

  async delete() {
    const translations = await firstValueFrom(this.translateService.get([
      'buttons.cancel',
      'pages.delete-header'
    ]));
    const alert = await this.alertController.create({
      header: translations['pages.delete-header'],
      message: this.translateService.instant('components.meter-mask.delete-counter-alert-message', {identifier: this.meter.identifier}),
      buttons: [
        {
          text: translations['buttons.cancel'],
          role: 'cancel',
        },
        {
          text: translations['buttons.delete'],
          role: 'destructive',
          handler: async () => {
            await this.modalController.dismiss({}, 'delete', MeterMaskComponent.MODAL_ID);
          },
        },
      ],
    });
    await alert.present();
  }

  getTranslation(type: string) {
    return getTranslationForMeterType(type, this.translateService);
  }
}
