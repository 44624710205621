import { Unit } from '@core/models/interfaces/unit.interface';
import { UnitRelationship } from '@core/models/interfaces/unit-relationship.interface';
import { OnlyRent } from '@core/models/datatypes/unit-relationship/only-rent.interface';
import { FixedRateRent } from '@core/models/datatypes/unit-relationship/fixed-rate-rent.interface';
import { CompoundRent } from '@core/models/datatypes/unit-relationship/compound-rent.interface';

export const getCurrentRelation = (unit: Unit, userToCheck = 'rentee'): UnitRelationship | undefined => {
  const now = new Date();
  let relationshipId: number;

  unit.relationships.forEach((relationship)=>{
    const start = new Date(relationship.startDate);
    if (!(userToCheck in relationship) || !relationship[userToCheck]) {
      return;
    }
    if (!relationship.endDate) {
      if (start <= now) {
        relationshipId = relationship.id;
      }
      return;
    }

    const end = new Date(relationship.endDate);

    if(start <= now && end >= now){
      relationshipId = relationship.id;
    }
  });

  const relationIndex = unit.relationships?.findIndex((relationship) => {
    return relationship.id === relationshipId;
  });
  return relationIndex === -1 || relationIndex == null ? undefined : unit.relationships[relationIndex];
}

export const getCurrentRelationshipFromRelationships = (relationships: UnitRelationship[]) => {
  const now = new Date();
  let relationshipId: number;

  relationships.forEach((relationship)=>{
    const start = new Date(relationship.startDate);
    if (relationship.rentee === null) {
      return;
    }
    if (!relationship.endDate) {
      if (start <= now) {
        relationshipId = relationship.id;
      }
      return;
    }

    const end = new Date(relationship.endDate);

    if(start <= now && end >= now){
      relationshipId = relationship.id;
    }
  });

  const relationIndex = relationships?.findIndex((relationship) => {
    return relationship.id === relationshipId;
  });

  return relationIndex === -1 || relationIndex == null ? undefined : relationships[relationIndex];
}

export const getCurrentRent = (rents: (OnlyRent | FixedRateRent | CompoundRent)[]): OnlyRent | FixedRateRent | CompoundRent | undefined => {
  let currentRent : OnlyRent | FixedRateRent | CompoundRent;
  if (rents.length > 1){
    const now = new Date();
    rents.forEach((rent)=>{
      const start = new Date(rent.startDate);
      if (start <= now){
        currentRent = rent;
      }
    });
  }
  return currentRent? currentRent : undefined;
}
