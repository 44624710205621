import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from '@shared/pipes/truncate.pipe';
import { NamePipe } from '@shared/pipes/name.pipe';
import { MarkdownPipe } from '@shared/pipes/markdown.pipe';
import { SanitizePipe } from '@shared/pipes/sanitize.pipe';
import { NewLineToBreakPipe } from '@shared/pipes/nl2br.pipe';

const exports = [
  TruncatePipe,
  NamePipe,
  MarkdownPipe,
  SanitizePipe,
  NewLineToBreakPipe
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: exports,
  exports
})
export class PipesModule {}
