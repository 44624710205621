// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}

.container {
  padding-top: 1em;
  padding-bottom: 1em;
  width: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.item-note {
  --padding-start: 16px;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-basis {
  flex-basis: 0;
}

.popover {
  overflow-y: auto;
}

ion-item-divider {
  font-size: 1rem;
  --inner-padding-top: .75em;
  --inner-padding-bottom: .75em;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/cost-type-field/cost-type-field.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,gBAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,eAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,cAAA;AACF;;AACA;EACE,aAAA;AAEF;;AACA;EACE,gBAAA;AAEF;;AAAA;EACE,eAAA;EACA,0BAAA;EACA,6BAAA;AAGF","sourcesContent":[":host {\n  width: 100%;\n}\n\n.container {\n  padding-top: 1em;\n  padding-bottom: 1em;\n  width: 100%;\n  overflow-y: auto;\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n}\n\n.flex-column {\n  flex-direction: column;\n}\n\n.item-note {\n  --padding-start: 16px;\n}\n\n.flex-grow {\n  flex-grow: 1;\n}\n\n.flex-shrink {\n  flex-shrink: 1;\n}\n.flex-basis {\n  flex-basis: 0;\n}\n\n.popover {\n  overflow-y: auto;\n}\nion-item-divider {\n  font-size: 1rem;\n  --inner-padding-top: .75em;\n  --inner-padding-bottom: .75em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
