import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();

  private canDrop: boolean;

  constructor() { }

  @Input()
  set appDnd(value: boolean) {
    this.canDrop=value;
  }

  @HostListener('dragover', ['$event']) onDragOver(e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.canDrop) {
      return false;
    }

    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.canDrop) {
      return false;
    }

    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) onDrop(e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.canDrop) {
      return false;
    }

    this.fileOver = false;
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
