// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  margin: 0 0 1rem;
  padding: 1em;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

ion-card.integrate {
  margin: 0;
  box-shadow: none;
}

.full-length-item {
  --padding-start: 0;
  --inner-padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
}

ion-card-header {
  padding-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/company-contact-card/company-contact-card.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;AACF;;AAEA;EACE,SAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,wBAAA;EACA,gBAAA;EACA,sBAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":["ion-card {\n  margin: 0 0 1rem;\n  padding: 1em;\n}\n\n.buttons {\n  display: flex;\n  justify-content: flex-end;\n}\n\nion-card.integrate {\n  margin: 0;\n  box-shadow: none;\n}\n\n.full-length-item {\n  --padding-start: 0;\n  --inner-padding-start: 0;\n  --padding-end: 0;\n  --inner-padding-end: 0;\n}\n\nion-card-header {\n  padding-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
