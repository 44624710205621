// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons {
  display: flex;
  width: 100%;
}

.w-100 {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/property-contacts-card/property-contacts-card.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".buttons {\n  display: flex;\n  width: 100%;\n}\n\n.w-100 {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
