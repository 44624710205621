import { Injectable } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { Pagination } from '@core/models/datatypes/pagination.interface';
import { CostType } from '@core/models/interfaces/cost-type.interface';
import { CostTypeMapper } from '@core/models/map-struct/cost-type.mapper';

@Injectable({
  providedIn: 'root',
})
export class CostTypeService {
  private readonly entityName = 'api/cost-types';

  constructor(
    private readonly apiService: ApiService,
    private readonly costTypeMapper: CostTypeMapper,
  ) {}

  public async getCostTypes(propertyId?: number, page = 1, pageSize = 25): Promise<{ data: CostType[]; meta: Pagination }> {
    return this.apiService.getWithCaching(this.entityName, {
      pagination: {
        page,
        pageSize,
      },
      ...(propertyId != null ? { property: propertyId } : {}),
      populate: [
        'property',
        'company'
      ]
    }).then((res) => ({
      data: res.data.map((post) => this.costTypeMapper.toEntity(post)),
      meta: 'pagination' in res.meta ? res.meta.pagination : res.meta as Pagination,
    }));
  }

  public async createCostType(post: CostType): Promise<CostType> {
    return this.apiService.post(this.entityName, {
      data: {
        ...post,
        ...(post.company ? { company: post.company.id } : {}),
        ...(post.property ? { property: post.property.id } : {}),
      },
    }).then(r => this.costTypeMapper.toEntity(r.data));
  }

  public async getCostTypeById(costTypeId: number) {
    return this.apiService.get(`${this.entityName}/${costTypeId}`, {})
      .then((res => this.costTypeMapper.toEntity(res.data)));
  }

  public async deleteCostType(costType: CostType) {
    return this.apiService.delete(`${this.entityName}/${costType.id}`);
  }
}
