import { Component, Input, OnInit } from '@angular/core';
import { Unit } from '@core/models/interfaces/unit.interface';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { UnitRelationshipService } from '@core/api/unit/unit-relationship.service';
import { addYears, format, subYears } from 'date-fns';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { isDateValidator } from '@core/validators/is-date.validator';
import { UsesCacheComponent } from '@core/api/cache/uses-cache';
import { CacheService } from '@core/api/cache/cache.service';
import { TranslateService } from '@ngx-translate/core';
import { OnlyRent } from '@core/models/datatypes/unit-relationship/only-rent.interface';
import { FixedRateRent } from '@core/models/datatypes/unit-relationship/fixed-rate-rent.interface';
import { CompoundRent } from '@core/models/datatypes/unit-relationship/compound-rent.interface';
import { FormArray } from '@angular/forms';

interface RentDetailsData {
  baseRent: string | number,
  totalAdvancePayment?: string | number,
  operatingCosts?: string | number,
  heatingCosts?: string | number,
  startDate: string | Date,
  note?: string,
  hasTotalAdvancePayment:boolean,
  hasOperatingCostsHeatingCosts:boolean,
}

@Component({
  selector: 'app-relationship-rent-mask',
  templateUrl: './relationship-rent-mask.component.html',
  styleUrls: ['./relationship-rent-mask.component.scss'],
})

export class RelationshipRentMaskComponent extends UsesCacheComponent implements OnInit {
  public static MODAL_ID = 'relationship-rent-mask-component';
  @Input() unit: Unit;
  @Input() rent: ((OnlyRent | FixedRateRent | CompoundRent)[]) | undefined;
  @Input() relationshipId: number;
  @Input() routerOutlet: IonRouterOutlet;
  private hasChangedData = false;
  public loading = true;
  public editRentForm!: UntypedFormGroup;
  public rentDetailsArray:RentDetailsData[] = [];
  public rentType: string ;
  public minimumStartDate: string;
  public maximumStartDate: string;


  constructor(
    private readonly modalController: ModalController,
    private readonly unitRelationshipService: UnitRelationshipService,
    private readonly formBuilder: UntypedFormBuilder,
    protected readonly cacheService: CacheService,
    private translateService: TranslateService,
  ) {
    super(cacheService);
  }

  async ngOnInit() {
    await this.loadRent();
    this.isInitialized = true;
  }

  onCacheCleared(lastRefresh: Date): void | Promise<void> {
    return this.loadRent();
  }

  public async dismiss(): Promise<void> {
    await this.modalController.dismiss({
      dismissed: true,
      hasChangedData: this.hasChangedData
    }, "closed", RelationshipRentMaskComponent.MODAL_ID);
  }

  async loadRent() {
    this.rentType = this.rent?.[0]?.__component ?? '';
    this.createRentForm();
  }

  createRentForm(){
    for (const individualRent of this.rent ) {
      // Fill rentDetailsArray due to rent type
      if (individualRent.__component === 'relationships.only-rent'){
        this.rentDetailsArray.push({
          baseRent: individualRent.rent ?? '',
          totalAdvancePayment: "",
          operatingCosts: "",
          heatingCosts: "",
          startDate: individualRent.startDate ?? '',
          note: individualRent.note ?? '',
          hasTotalAdvancePayment: false,
          hasOperatingCostsHeatingCosts: false,
        });
      }
      else if (individualRent.__component === 'relationships.fixed-rate-rent'){
        this.rentDetailsArray.push({
          baseRent: individualRent.rent ?? '',
          totalAdvancePayment: individualRent.fixedRate ?? '',
          operatingCosts: "",
          heatingCosts: "",
          startDate: individualRent.startDate ?? '',
          note:individualRent.note ?? '',
          hasTotalAdvancePayment: true,
          hasOperatingCostsHeatingCosts: false,
        });
      }
      else if (individualRent.__component === 'relationships.compound-rent'){
        this.rentDetailsArray.push({
          baseRent: individualRent.rent ?? '',
          totalAdvancePayment: "",
          operatingCosts: individualRent.operatingCosts ?? '',
          heatingCosts: individualRent.heatingCosts ?? '',
          startDate: individualRent.startDate ?? '',
          note: individualRent.note ?? '',
          hasTotalAdvancePayment: false,
          hasOperatingCostsHeatingCosts:true,
        });
      }
    }

    this.editRentForm = this.formBuilder.group({
      rentDetails: this.formBuilder.array([
        this.formBuilder.group({
          startDate: [this.rentDetailsArray[0].startDate ?? '' , Validators.compose([Validators.required, isDateValidator])],
          rent: [this.rentDetailsArray[0].baseRent ?? '', Validators.required],
          fixedRate: [this.rentDetailsArray[0].totalAdvancePayment ?? ''],
          operatingCosts: [this.rentDetailsArray[0].operatingCosts ?? ''],
          heatingCosts: [this.rentDetailsArray[0].heatingCosts ?? ''],
          note: [this.rentDetailsArray[0].note ?? ''],
          hasTotalAdvancePayment:[this.rentDetailsArray[0].hasTotalAdvancePayment ?? false],
          hasOperatingCostsHeatingCosts:[this.rentDetailsArray[0].hasOperatingCostsHeatingCosts ?? false],
        }, {
          validators: Validators.compose([
            ifHasTotalAdvancePayment,
            ifHasOperatingCostsHeatingCosts
          ]),
        }),
      ]),
    });

    //send rent type values to addRentDetails, to add form fields
    this.addRentDetailsForFirstLoad(this.rentDetailsArray);
  }

  get rentDetails() {
    return this.editRentForm.get('rentDetails') as FormArray;
  }

  addRentDetails(){
    this.rentDetails.push(this.formBuilder.group({
      startDate: ['', Validators.compose([Validators.required, isDateValidator])],
      rent: ['', Validators.required],
      fixedRate: [''],
      operatingCosts: [''],
      heatingCosts: [''],
      note: [''],
      hasTotalAdvancePayment:[this.rentType === 'relationships.fixed-rate-rent' ?? false],
      hasOperatingCostsHeatingCosts:[this.rentType === 'relationships.compound-rent' ?? false],
    }, {
      validators: Validators.compose([
        ifHasTotalAdvancePayment,
        ifHasOperatingCostsHeatingCosts
      ]),
    }));
  }

  addRentDetailsForFirstLoad(rentDetailsArray?: Array<RentDetailsData>){
    if (rentDetailsArray.length > 0) {
      for (const [key, item] of rentDetailsArray.entries()){
        if (key === 0 ){
          //escape the first iteration, it was filled with the form definition
        }
        else {
          this.rentDetails.push(this.formBuilder.group({
            startDate: [item.startDate, Validators.compose([Validators.required, isDateValidator])],
            rent: [item.baseRent, Validators.required],
            fixedRate: [item.totalAdvancePayment],
            operatingCosts: [item.operatingCosts],
            heatingCosts: [item.heatingCosts],
            note: [item.note],
            hasTotalAdvancePayment:[item.hasTotalAdvancePayment],
            hasOperatingCostsHeatingCosts:[item.hasOperatingCostsHeatingCosts],
          }, {
            validators: Validators.compose([
              ifHasTotalAdvancePayment,
              ifHasOperatingCostsHeatingCosts
            ]),
          }));
        }
      }
    }
  }

  deleteRentDetails(i: number){
    this.rentDetails.removeAt(i);
  }

  public updateStartDate(value: string | string[], index: number) {
    this.rentDetails.controls[index].patchValue({ startDate: value });
  }

  private resetDates() {
    this.minimumStartDate = format(subYears(new Date(), 100), 'yyyy-MM-dd');
    this.maximumStartDate = format(addYears(new Date(), 100), 'yyyy-MM-dd');
  }

  async saveRelationRent($event: any) {
    this.hasChangedData = true;

    $event.preventDefault();
    $event.stopPropagation();

    if (this.rentDetails.invalid || this.rentDetails.disabled) {
      return;
    }

    //prepare paymentForm or rent modal data to send to backend
    let rentPayment= {};
    const rentCollection = [];
    let data: any = {};

    for (const rentDetail of this.rentDetails.controls) {
      const {
        startDate,
        rent,
        fixedRate,
        operatingCosts,
        heatingCosts,
        note,
        hasTotalAdvancePayment,
        hasOperatingCostsHeatingCosts,
      } = rentDetail.value;

      rentPayment = {
        startDate,
        note,
      };

      if (hasTotalAdvancePayment) {
        rentPayment = {
          ...rentPayment,
          rent,
          fixedRate,
          __component: 'relationships.fixed-rate-rent',
        };
      } else if (hasOperatingCostsHeatingCosts) {
        rentPayment = {
          ...rentPayment,
          rent,
          operatingCosts,
          heatingCosts,
          __component: 'relationships.compound-rent',
        };
      } else {
        rentPayment = {
          ...rentPayment,
          rent,
          __component: 'relationships.only-rent',
        };
      }

      rentCollection.push(rentPayment);
    }

    data = {
      ...data,
      'rent': rentCollection,
    }

    await this.unitRelationshipService.updateRelationshipRent(this.relationshipId, data);
    this.editRentForm.disable();
    await this.clearCacheAndWait();
    await this.resetForm();
    await this.dismiss();
    return;
  }

  public async resetForm() {
    this.hasChangedData = false;
    this.editRentForm?.enable();
    this.editRentForm?.reset();
    this.editRentForm?.clearValidators();
    this.editRentForm = undefined;
    this.rentDetailsArray = [];
    this.rentType = '';
    this.resetDates();
  }


  getTranslated(key: string) {
    return this.translateService.get(key);
  }
}

const ifHasTotalAdvancePayment: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (!control) {
    return null;
  }

  const hasTotalAdvancePayment = control.get('hasTotalAdvancePayment');
  const totalAdvancePayment = control.get('fixedRate');
  if (hasTotalAdvancePayment.value === true && !totalAdvancePayment.hasValidator(Validators.required)) {
    totalAdvancePayment.addValidators(Validators.required);
    totalAdvancePayment.updateValueAndValidity();
    return totalAdvancePayment.errors;
  } else if (hasTotalAdvancePayment.value === false && totalAdvancePayment.hasValidator(Validators.required)) {
    totalAdvancePayment.removeValidators(Validators.required);
    totalAdvancePayment.updateValueAndValidity();
  }
  return null;
};

const ifHasOperatingCostsHeatingCosts: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control) {
    return null;
  }

  const hasOperatingCostsHeatingCosts = control.get('hasOperatingCostsHeatingCosts');
  const operatingCosts = control.get('operatingCosts');
  const heatingCosts = control.get('heatingCosts');
  if (
    hasOperatingCostsHeatingCosts.value === true &&
    (!operatingCosts.hasValidator(Validators.required) && !heatingCosts.hasValidator(Validators.required))
  ) {
    operatingCosts.addValidators(Validators.required);
    operatingCosts.updateValueAndValidity();
    heatingCosts.addValidators(Validators.required);
    heatingCosts.updateValueAndValidity();
    return [operatingCosts.errors, heatingCosts.errors];
  } else if (hasOperatingCostsHeatingCosts.value === false &&
    operatingCosts.hasValidator(Validators.required)
  ) {
    operatingCosts.removeValidators(Validators.required);
    operatingCosts.updateValueAndValidity();
    heatingCosts.removeValidators(Validators.required);
    heatingCosts.updateValueAndValidity();
  }
  return null;

};
