import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@core/models/interfaces/user.interface';
import { getName } from '@core/utils/get-name';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'name',
  pure: true
})

export class NamePipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  transform(value: User | undefined | null, includeBreaks = false): SafeHtml {
    if (includeBreaks) {
      return this.sanitizer.bypassSecurityTrustHtml(getName(value, includeBreaks));
    }
    return getName(value, includeBreaks);
  }
}
