import { Injectable } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { DocumentMapper } from '@core/models/map-struct/document.mapper';
import { Document, DocumentCreateDto } from '@core/models/interfaces/document.interface';
import { Pagination } from '@core/models/datatypes/pagination.interface';
import { UserService } from '../user/user.service';
import { MediaService } from '@core/api/media/media.service';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root',
})
export class DocumentService {

  public readonly entityName = 'api/documents';
  public readonly entityNameTemplate = 'api/document-templates';
  public readonly entityRef = 'api::document.document';
  public readonly entityRefTemplate = 'api::document-template.document-template';

  constructor(
    private readonly apiService: ApiService,
    private readonly mediaService: MediaService,
    private readonly userService: UserService,
    private readonly documentMapper: DocumentMapper,
    private translateService :TranslateService,

  ) { }

  public async getDocumentById(id: number, template = false) {
    const populate = ['file',
      'company',
      'owner'];

    if (!template) {
      populate.push('participants', 'participants.role');
    }

    return this.apiService.getWithCaching(`${template ? this.entityNameTemplate : this.entityName}/${id}`, {
      populate,
    }).then(res => this.documentMapper.toEntity(res.data));
  }

  public async getDocuments(
    search: string = undefined,
    fuzzySearch = false,
    filterMode: FilterMode = 'participation',
    folder?: number,
    property?: number,
    unit?: number,
    page = 1,
    pageSize = 25,
    sortField = 'date',
    sortMode = 'asc',
  ): Promise<{
    data: Document[];
    meta: Pagination;
  }> {
    let filter = {};

    if (search !== undefined) {
      const parts = search.trim().split(' ');
      const searches = [
        { title: { $containsi: search } },
        { description: { $containsi: search } },
      ];
      if (fuzzySearch && parts.length > 1) {
        const more = parts.splice(5).join(' ');
        if (more) {
          parts.push(more);
        }
        parts.forEach((part) => {
          searches.push(
            ...[
              { title: { $containsi: part } },
              { description: { $containsi: part } },
            ],
          );
        });
      }
      filter = {
        $or: searches,
      };
    }

    const user = await this.userService.getMe();

    let occurrence;
    if (filterMode === 'company' && !!(user?.employeeIn)) {
      occurrence = {
        company: {
          id: {
            $eq: user.employeeIn.id,
          },
        },
      };
    } else {
      occurrence = {
        participants: {
          id: user.id,
        },
      };
    }

    const recursiveFilters = {};

    if (property) {
      recursiveFilters['property'] = {
        id: {$eq: property}
      };
    } else {
      recursiveFilters['property'] = {
        id: {$null: true}
      };
    }
    if (unit) {
      recursiveFilters['unit'] = {
        id: {$eq: unit}
      };
    } else {
      recursiveFilters['unit'] = {
        id: {$null: true}
      };
    }

    if (folder) {
      recursiveFilters['documentFolder'] = {
        id: {$eq: folder}
      };
    } else {
      recursiveFilters['documentFolder'] = {
        id: {$null: true}
      };
    }

    filter = {
      ...filter,
      ...occurrence,
      ...recursiveFilters,
    };

    return this.apiService.getWithCaching(
      this.entityName,
      {
        populate: [
          'file',
          'company',
          'owner',
          'participants',
          'participants.role',
          'participants.profilePicture',
        ],
        filters: filter,
        pagination: {
          page,
          pageSize,
        },
        sort: [`${sortField}:${sortMode}`, 'id:asc'],
      },
    ).then((res) => ({
      data: res.data.map(
        (doc) => this.documentMapper.toEntity(doc),
      ),
      meta: ('pagination' in res.meta ? res.meta.pagination : res.meta) as Pagination,
    }));
  }

  public async getDocumentTemplates(
    search: string = undefined,
    fuzzySearch = false,
    filterMode: FilterModeTemplate = 'tenant',
    page = 1,
    pageSize = 25,
    sortField = 'date',
    sortMode = 'asc',
  ): Promise<{
    data: Document[];
    meta: Pagination;
  }> {
    let filter = {};

    if (search !== undefined) {
      const parts = search.trim().split(' ');
      const searches = [
        { title: { $containsi: search } },
        { description: { $containsi: search } },
      ];
      if (fuzzySearch && parts.length > 1) {
        const more = parts.splice(5).join(' ');
        if (more) {
          parts.push(more);
        }
        parts.forEach((part) => {
          searches.push(
            ...[
              { title: { $containsi: part } },
              { description: { $containsi: part } },
            ],
          );
        });
      }
      filter = {
        $or: searches,
      };
    }

    const user = await this.userService.getMe();

    let occurrence;
    if (filterMode === 'company' && !!(user?.employeeIn)) {
      occurrence = {
        company: {
          id: {
            $eq: user.employeeIn.id,
          },
        },
      };
    } else {
      occurrence = {};
    }

    filter = {
      ...filter,
      ...occurrence,
    };

    return this.apiService.getWithCaching(
      this.entityNameTemplate,
      {
        populate: [
          'file',
          'company',
          'owner',
        ],
        filters: filter,
        pagination: {
          page,
          pageSize,
        },
        sort: [`${sortField}:${sortMode}`, 'id:asc'],
      },
    ).then((res) => ({
      data: res.data.map(
        (doc) => this.documentMapper.toEntity(doc),
      ),
      meta: ('pagination' in res.meta ? res.meta.pagination : res.meta) as Pagination,
    }));
  }

  public async updateDocument(document: Document | (Omit<Document, 'participants'> & {participants: any}), template = false) {
    const me = await this.userService.getMe();

    let participants;
    if (!template) {
      if (Array.isArray(document.participants)) {
        participants = document.participants?.map((p) => {
          if (typeof p === 'number') {
            return p;
          } else {
            return p.id ?? undefined;
          }
        })?.filter(Boolean) ?? [];
      } else {
        participants = document.participants
      }
    }

    return this.apiService.put(`${template ? this.entityNameTemplate : this.entityName}/${document.id}`, {
      data: {
        ...document,
        company: document.company?.id ?? me.employeeIn?.id,
        ...(!template ? { participants } : {}),
        owner: document.owner?.id ?? me.id,
      },
    });
  }

  public async createDocument(document: DocumentCreateDto, template = false) {
    const me = await this.userService.getMe();
    if (!me || !me.employeeIn) {
      throw new Error(this.translateService.instant('errors.message.services.document-service-create-document'));
    }

    // @ts-ignore
    if (!document.participants?.includes(me.id)) {
      if (!document.participants) {
        document.participants = [];
      }
      // @ts-ignore
      document.participants.push(me.id);
    }

    return this.apiService.post(`${template ? this.entityNameTemplate : this.entityName}`, {
      data: {
        ...document,
        owner: me.id,
        company: me.employeeIn.id,
      },
    }).then((res) => this.documentMapper.toEntity(res.data));
  }

  public async deleteDocument(document: Document, template = false) {
    return this.apiService.delete(`${template ? this.entityNameTemplate : this.entityName}/${document.id}`);
  }

  public async updateFile(id: number, file: string | File | Blob, template = false) {
    return this.mediaService.uploadFiles(id, template ? this.entityRefTemplate : this.entityRef, 'file', file);
  }
}

type FilterMode = 'participation' | 'company';
type FilterModeTemplate = 'tenant' | 'company';
