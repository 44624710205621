import { Component, OnInit, signal, TrackByFunction } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavParams } from '@ionic/angular';
import { CostTypeService } from '@core/api/cost-type/cost-type.service';
import { ApportionmentFormula, CostType } from '@core/models/interfaces/cost-type.interface';
import { PropertyService } from '@core/api/property/property.service';
import { Property } from '@core/models/interfaces/property.interface';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-cost-type-modal',
  templateUrl: './add-cost-type-modal.component.html',
  styleUrls: ['./add-cost-type-modal.component.scss'],
})
export class AddCostTypeModalComponent implements OnInit {

  apportionableCategories = signal<CostType[]>([]);
  apportionableCategoriesWithoutFormula = signal<CostType[]>([]);
  nonApportionableCategories = signal<CostType[]>([]);
  searchTerm: string;
  form = this.formBuilder.group({
    customTypes: this.formBuilder.array([]),
  });
  readonly property = signal<Property | undefined>(undefined);
  #apportionableCategories: CostType[] = [];
  #apportionableCategoriesWithoutFormula: CostType[] = [];
  #nonApportionableCategories: CostType[] = [];
  readonly #propertyId: number;

  constructor(
    public modalController: ModalController,
    private loadingController: LoadingController,
    private propertyService: PropertyService,
    private navParams: NavParams,
    private costTypeService: CostTypeService,
    private formBuilder: FormBuilder,
    private alertController: AlertController,
  ) {
    this.#propertyId = this.navParams.get('data').propertyId;
  }

  trackById: TrackByFunction<CostType> = (index, costType) => costType.id;

  async ngOnInit() {
    const loading = await this.loadingController.create();
    await loading.present();
    try {
      await this.loadCostTypes();
      this.property.set(await this.propertyService.getPropertyById(this.#propertyId));
    } catch (e) {
      console.error(e);
    }
    await loading.dismiss();
  }

  mapFormula(apportionmentFormula: ApportionmentFormula | undefined) {
    switch (apportionmentFormula) {
      case 'direct':
        return 'pages.manager-page.finance.revenues.income.refund.apportionment-formula.direct';
      case 'living_space':
        return 'pages.manager-page.finance.revenues.income.refund.apportionment-formula.living-space';
      case 'people':
        return 'pages.manager-page.finance.revenues.income.refund.apportionment-formula.people';
      case 'units':
        return 'pages.manager-page.finance.revenues.income.refund.apportionment-formula.residential-units';
      case 'water':
        return 'pages.manager-page.finance.revenues.income.refund.apportionment-formula.water-consumption';
      default:
        return '';
    }
  }

  addCustomCostType() {
    (<FormArray> this.form.get('customTypes')).push(this.createCustomCostTypeGroup());
  }

  async save() {
    const types = this.form.get('customTypes').getRawValue();
    const newTypes = new Set();
    for (const type of types) {
      newTypes.add(await this.costTypeService.createCostType({
        name: type.name as string,
        apportionmentFormula: type.apportionmentFormula as ApportionmentFormula | undefined,
        nonApportionable: type.nonApportionable as boolean,
        property: { id: this.#propertyId } as Property,
      } as CostType));
    }

    await this.modalController.dismiss([...newTypes], 'confirm');
  }

  async showDeleteAlert(costType: CostType) {
    const alert = await this.alertController.create({
      header: 'Wirklich löschen?',
      message: `Die Kostenart "${costType.name}" wird gelöscht.`,
      buttons: [{
        text: 'Löschen',
        role: 'destructive',
        handler: () => this.deleteCostType(costType)
      }, {
        text: 'Abbrechen',
        role: 'cancel',
      }],
    });
    return alert.present();
  }

  private async loadCostTypes() {
    let page = 1;
    const pageSize = 25;
    let hasMore = false;
    let costTypes: CostType[] = [];
    do {
      const types = await this.costTypeService.getCostTypes(this.#propertyId, page, pageSize);
      if (types.meta.pageCount > page) {
        page++;
        hasMore = true;
      } else {
        hasMore = false;
      }

      costTypes = costTypes.concat(types.data);
    } while (hasMore);
    this.#apportionableCategories = costTypes.filter(c => c.nonApportionable === false && c.apportionmentFormula != undefined);
    this.#apportionableCategoriesWithoutFormula = costTypes.filter(c => c.nonApportionable === false && c.apportionmentFormula == undefined);
    this.#nonApportionableCategories = costTypes.filter(c => c.nonApportionable === true);

    this.apportionableCategories.set(this.#apportionableCategories);
    this.apportionableCategoriesWithoutFormula.set(this.#apportionableCategoriesWithoutFormula);
    this.nonApportionableCategories.set(this.#nonApportionableCategories);
  }

  private createCustomCostTypeGroup(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      nonApportionable: [false, Validators.required],
      apportionmentFormula: ['', Validators.required],
      propertyId: [this.#propertyId, Validators.required],
    });
  }

  private async deleteCostType(costType: CostType) {
    await this.costTypeService.deleteCostType(costType);
    const index = this.#apportionableCategories.findIndex(f => f.id === costType.id);
    if (index !== -1) {
      this.#apportionableCategories.splice(index, 1);
      this.apportionableCategories.set(this.#apportionableCategories);
    }

  }
}
