import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Contact } from '@core/models/interfaces/contact.interface';

@Component({
  selector: 'app-company-contact-card',
  templateUrl: './company-contact-card.component.html',
  styleUrls: ['./company-contact-card.component.scss'],
})
export class CompanyContactCardComponent {

  @Input() contact: Contact;
  @Input() showOptions = true;
  @Input() integrate = false;
  @Output() deleteClicked = new EventEmitter<any>();
  @Output() editClicked = new EventEmitter<any>();

  constructor() { }

  getLink(websiteUrl: string) {
    if (websiteUrl.startsWith('http')) {
      return websiteUrl;
    }
    return 'https://' + websiteUrl;
  }

  edit() {
    this.editClicked.emit();
  }

  delete() {
    this.deleteClicked.emit();
  }

  trackById(_, entity: { id: number }) {
    return entity.id;
  }
}
