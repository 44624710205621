export const fuzzy = (input, term, ratio = 0.5) => {
  const searchValue = input.toLowerCase();
  const compare = term.toLowerCase();
  let matches = 0;
  if (searchValue.indexOf(compare) > -1) {return true;} // covers basic partial matches
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < compare.length; i++) {
    if (searchValue.indexOf(compare[i]) > -1) {
      matches += 1;
    } else {
      matches -= 1;
    }
  }
  return (matches / input.length >= ratio || term === '');
};
