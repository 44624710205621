import { Injectable } from '@angular/core';
import { StorageService } from '@core/storage/storage.service';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '@core/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private internalClearCacheStream: BehaviorSubject<Date>;

  constructor(
    private apiService: ApiService
  ) {
    this.internalClearCacheStream = new BehaviorSubject<Date>(new Date());
  }

  public get clearCacheStream() {
    return this.internalClearCacheStream;
  }

  public async clearCache() {
    await this.apiService.clearItemCache();
    this.internalClearCacheStream.next(new Date());
  }
}
