import { Problem } from '@core/models/interfaces/problem.interface';
import { Ticket } from '@core/models/interfaces/ticket.interface';
import { TranslateService } from '@ngx-translate/core';

export const getStatusColor = (ticket?: Ticket | Problem | { status?: string }, isTicket = false) => {
  if (isTicket) {
    switch (ticket?.status ?? 'open') {
      case 'open':
        return 'medium';
      case 'in_progress':
        return 'tertiary';
      case 'closed':
        return 'dark';
      default:
        return 'medium';
    }
  } else {
    switch (ticket?.status ?? 'open') {
      case 'open':
        return 'danger';
      case 'in_progress':
        return 'tertiary';
      case 'closed':
        return 'success';
      default:
        return 'medium';
    }
  }
};

export const getStatusIcon = (ticket?: Ticket | Problem | { status?: string }) => {
  switch (ticket?.status ?? 'open') {
    case 'open':
      return 'ellipse-outline';
    case 'in_progress':
      return 'sync-circle-outline';
    case 'closed':
      return 'checkmark-circle-outline';
    default:
      return 'add-circle-outline';
  }
};

export const getStatusLabel = (translateService: TranslateService, ticket?: Ticket | Problem | { status: string }) => {
  switch (ticket?.status ?? 'open') {
    case 'open':
      return translateService.instant('components.open');
    case 'in_progress':
      return translateService.instant('components.in-Progress');
    case 'closed':
      return translateService.instant('pages.completed');
    default:
      return translateService.instant('components.Unknown');
  }
};
