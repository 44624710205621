// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-grid, ion-col {
  padding: 0;
  margin: 0;
}

.image-label {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .input-following {
    margin-right: 5px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/property-mask/property-mask.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,SAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE;IACE,iBAAA;EACF;AACF","sourcesContent":["ion-grid, ion-col {\n  padding: 0;\n  margin: 0;\n}\n\n.image-label {\n  margin-bottom: 10px;\n}\n\n@media (min-width: 768px) {\n  .input-following {\n    margin-right: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
