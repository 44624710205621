import { Component, EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core';
import { Meter } from '@core/models/interfaces/meter.interface';
import { MeterReading } from '@core/models/interfaces/meter-reading.interface';
import { MeterReadingService } from '@app/@core/api/meter/meter-reading.service';
import { getTranslationForMeterType } from '@core/models/utils/type.translation';
import { getThumbnailUrl } from '@core/models/utils/media.utils';
import { Capacitor } from '@capacitor/core';
import { apiSettings } from '@env/environment';
import { MediaCarouselModalComponent } from '@shared/components/media-carousel-modal/media-carousel-modal.component';
import { PreviewAnyFile } from '@awesome-cordova-plugins/preview-any-file/ngx';
import { IonRouterOutlet, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { Logger } from '@core/logger/logger.service';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

const logger = new Logger('a.@s.c.m.MeterReadoutComponent');

/**
 * This Component does not implement UsesCase as the Component does not provide any data changing implementation and relies on outside data.
 * When ever cache is cleared and this element should react is does because of the set reading method.
 */
@Component({
  selector: 'app-meter-readout',
  templateUrl: './meter-readout.component.html',
  styleUrls: ['./meter-readout.component.scss'],
})
export class MeterReadoutComponent {
  @Input() showControls = false;
  @Output() addReadingClicked = new EventEmitter<Meter>();
  @Output() editClicked = new EventEmitter<Meter>();

  public reading?: MeterReading;
  public change?: number;
  private internalMeter;

  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    private readonly meterReadingService: MeterReadingService,
    private readonly loadingController: LoadingController,
    private readonly previewAnyFile: PreviewAnyFile,
    private readonly toastController: ToastController,
    private readonly modalController: ModalController,
    private readonly routerOutlet: IonRouterOutlet,
    private translateService :TranslateService,
  ) { }

  get locale() {
    return this.translateService.currentLang;
  }

  get meter() {
    return this.internalMeter;
  }

  @Input() set meter(meter: Meter | undefined) {
    this.internalMeter = meter;
    this.getReadings();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get readingIsFromToday() {
    if (!this.reading) {
      return false;
    }

    const date = new Date(this.reading.date);
    const now = new Date();
    return date.getUTCFullYear() === now.getUTCFullYear()
      && date.getUTCMonth() === now.getUTCMonth()
      && date.getUTCDate() === now.getUTCDate();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get readingThumbnailUrl(): string {
    return getThumbnailUrl(this.reading.photo);
  }

  addMeterReading() {
    if (this.readingIsFromToday || !this.meter) {
      return;
    }
    this.addReadingClicked.emit(this.meter);
  }

  edit() {
    if (!this.meter) {
      return;
    }
    this.editClicked.emit(this.meter);
  }

  getTranslation(type = '') {
    return getTranslationForMeterType(type, this.translateService);
  }

  async openImage() {
    if (!this.reading?.photo) {
      return;
    }

    const translations = await firstValueFrom(this.translateService.get([
      'buttons.okay',
      'errors.header.preview-document-error',
      'components.meter-readout.open-image-modal-title'
    ]));
    if (Capacitor.isNativePlatform()) {
      const loading = await this.loadingController.create({});
      await loading.present();
      this.previewAnyFile.previewPath(apiSettings.baseUrl + this.reading.photo.url, {
        name: this.reading.photo.name,
        mimeType: this.reading.photo.mime,
      }).subscribe(async () => {
        await loading.dismiss();
      }, async (e) => {
        await loading.dismiss();
        logger.error(e);
        await (await this.toastController.create({
          header: translations['errors.header.preview-document-error'],
          message: this.translateService.instant('errors.message.error-message', {error: e}),
          buttons: [translations['buttons.okay']],
          color: 'danger',
          duration: 5000,
          position: 'top',
        })).present();
      });
    } else {
      const modal = await this.modalController.create({
        component: MediaCarouselModalComponent,
        id: MediaCarouselModalComponent.MODAL_ID,
        canDismiss: true,
        presentingElement: this.routerOutlet.nativeEl,
        componentProps: {
          media: [this.reading.photo],
          title: translations['components.meter-readout.open-image-modal-title'] + ' ' + formatDate(this.reading.date, 'mediumDate', this._locale),
        },
      });
      await modal.present();
    }
  }

  private async getReadings() {
    this.reading = await this.meterReadingService.getLastReadingByMeterId(this.meter.id);
    const previous = await this.meterReadingService.getPreviousReadingByMeterId(this.meter.id);

    if
    (this.reading && previous) {
      this.change = this.reading.reading / previous.reading * 100.0 - (100);
    } else {
      this.change = undefined;
    }
  }
}
