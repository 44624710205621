import { Injectable, Injector } from '@angular/core';
import { Mapper } from '@core/models/map-struct/mapper.interface';
import { Company } from '@core/models/interfaces/company.interface';
import { CostTypeMapper } from '@core/models/map-struct/cost-type.mapper';

@Injectable({
  providedIn: 'root',
})
export class CompanyMapper implements Mapper<Company> {
  private costTypeMapper: CostTypeMapper;

  constructor(private injector: Injector) {}


  toEntity(apiData: any): Company | undefined {
    if (!('id' in apiData)) {
      return undefined;
    }

    const mappedObject = {
      id: apiData.id,
      name: apiData.attributes.name
    } as Company;

    if ('costTypes' in apiData.attributes && apiData.attributes.costTypes != null) {
      if (apiData.attributes.costTypes?.data != null) {
        if (!this.costTypeMapper) {
          this.costTypeMapper = this.injector.get(CostTypeMapper);
          this.costTypeMapper.setCompanyMapper(this);
        }
        mappedObject.costTypes = apiData.attributes.costTypes.data.map((costType) => this.costTypeMapper.toEntity(costType));
      } else if (!('data' in apiData.attributes.properties)) {
        mappedObject.costTypes = apiData.attributes.costTypes;
      }
    }

    return mappedObject;
  }

  setCostTypeMapper(mapper: CostTypeMapper) {
    this.costTypeMapper = mapper;
  }
}
