import { Injectable, Injector } from '@angular/core';
import { Mapper } from '@core/models/map-struct/mapper.interface';
import { Comment } from '@core/models/datatypes/comment.interface';
import { UserMapper } from '@core/models/map-struct/user.mapper';

@Injectable({
  providedIn: 'root',
})
export class CommentMapper implements Mapper<Comment> {

  private userMapper: UserMapper;

  constructor(private injector: Injector) {

  }

  toEntity(apiData: any): Comment | undefined {
    if (!('id' in apiData)) {
      return undefined;
    }

    const mappedObject = {
      id: apiData.id,
      message: apiData.message,
      date: apiData.date,
      createdAt: apiData.createdAt,
      updatedAt: apiData.updatedAt,
    } as Comment;

    if ('user' in apiData && apiData.user != null) {
      if (apiData.user?.data != null) {
        if (!this.userMapper) {
          this.userMapper = this.injector.get(UserMapper);
        }
        mappedObject.user = this.userMapper.toEntity(apiData.user.data);
      } else if (!('data' in apiData.user)) {
        mappedObject.user = apiData.user;
      }
    }

    return mappedObject;
  }
}
