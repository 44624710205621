import { Injectable } from '@angular/core';
import { PermissionStatus, PushNotifications, Token } from '@capacitor/push-notifications';
import { PushDeviceService } from '@core/api/push-device/push-device.service';
import { StorageService } from '@core/storage/storage.service';
import { PushTokenData } from '@core/models/app/push-notification.interface';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {

  private readonly componentName = 'PushNotificationService';

  constructor(
    private readonly pushDeviceService: PushDeviceService,
    private readonly storageService: StorageService,
  ) {
    PushNotifications.addListener('registration', async (token: Token) => {
      const newToken = token.value;
      const currentToken: PushTokenData = await this.storageService.get(this.componentName, 'CurrentPushToken');

      const language = 'de';

      if (currentToken) {
        if (currentToken.pushToken === newToken && currentToken.language === language) {
          const now = new Date();
          const lastRegistrationTime = new Date(currentToken.registerTime);

          const timeDifference = now.valueOf() - lastRegistrationTime.valueOf();
          const minDifference = 7 * 24 * 60 * 60 * 1000; // 7 days

          if (timeDifference < minDifference) {
            // Token is the same as last state sent to the API. Don't refresh it within given timespan.
            return;
          }
        } else {
          // Token has changed. Deregister old token from API.
          await this.deregisterNotifications(currentToken);
        }
      }

      // Register new push token with API
      const pushRegistration = await this.pushDeviceService.add(newToken, language);

      // Save push token for later de-registration
      await this.storageService.set(this.componentName, 'CurrentPushToken', {
        userId: pushRegistration.userId,
        pushToken: pushRegistration.pushToken,
        registerTime: (new Date()).toISOString(),
        language,
      } as PushTokenData);
    })
      .catch(() => undefined); // Ignore errors on unsupported platforms
  }

  /**
   * Register with Apple / Google and Firebase to receive push via FCM
   */
  private static async registerNotifications(permissions: PermissionStatus): Promise<void> {
    if (permissions.receive === 'granted') {
      await PushNotifications.register();
    }
  }

  /**
   * Check existing push notification permissions and register them if allowed
   *
   * This should be run on every app start, so we register push notifications if
   * the user allowed them later on in the settings.
   */
  public async checkNotifications(): Promise<void> {
    await PushNotifications.checkPermissions()
      .then((result) => PushNotificationService.registerNotifications(result))
      .catch(() => null); // Ignore errors on unsupported platforms

    await PushNotifications.removeAllDeliveredNotifications()
      .catch(() => undefined); // Ignore errors on unsupported platforms
  }

  /**
   * Prompt user for push notifications and register them if allowed
   *
   * This runs when the user should specifically be prompted for notifications.
   */
  public async promptNotifications(): Promise<void> {
    await PushNotifications.requestPermissions()
      .then((result) => PushNotificationService.registerNotifications(result))
      .catch(() => null); // Ignore errors on unsupported platforms
  }

  /**
   * De-register existing push token with API
   */
  public async deregisterNotifications(tokenData?: PushTokenData): Promise<void> {
    const currentToken: PushTokenData = tokenData ?? await this.storageService.get(this.componentName, 'CurrentPushToken');

    if (currentToken) {
      await this.pushDeviceService.remove(currentToken.userId, currentToken.pushToken);
      await this.storageService.remove(this.componentName, 'CurrentPushToken');
    }
  }

}
