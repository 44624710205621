import { Mapper } from '@core/models/map-struct/mapper.interface';
import { MeterReading } from '@core/models/interfaces/meter-reading.interface';
import { UserMapper } from '@core/models/map-struct/user.mapper';
import { ImageMapper } from '@core/models/map-struct/image.mapper';
import { MeterMapper } from './meter.mapper';
import { Injectable, Injector } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MeterReadingMapper implements Mapper<MeterReading> {

  private userMapper: UserMapper;
  private imageMapper: ImageMapper;
  private meterMapper: MeterMapper;

  constructor(private injector: Injector) {

  }

  toEntity(apiData: any): MeterReading | undefined {
    if (!('attributes' in apiData)) {
      return undefined;
    }
    const mappedObject = {
      id: apiData.id,
      date: apiData.attributes.date,
      reading: apiData.attributes.reading,
    } as MeterReading;

    if ('meter' in apiData.attributes && apiData.attributes.meter) {
      if (apiData.attributes.meter?.data != null) {
        if (!this.meterMapper) {
          this.meterMapper = this.injector.get(MeterMapper);
          this.meterMapper.setMeterReadingMapper(this);
        }
        mappedObject.meter = this.meterMapper.toEntity(apiData.attributes.meter.data);
      } else if (!('data' in apiData.attributes.meter)) {
        mappedObject.meter = apiData.attributes.meter;
      }
    }

    if ('photo' in apiData.attributes && apiData.attributes.photo) {
      if (apiData.attributes.photo?.data != null) {
        if (!this.imageMapper) {
          this.imageMapper = this.injector.get(ImageMapper);
        }
        mappedObject.photo = this.imageMapper.toEntity(apiData.attributes.photo.data);
      } else if (!('data' in apiData.attributes.photo)) {
        mappedObject.photo = apiData.attributes.photo;
      }

    }
    if ('addedBy' in apiData.attributes && apiData.attributes.addedBy) {
      if (apiData.attributes.addedBy?.data != null) {
        if (!this.userMapper) {
          this.userMapper = this.injector.get(UserMapper);
        }
        mappedObject.addedBy = this.userMapper.toEntity(apiData.attributes.addedBy.data);
      } else if (!('data' in apiData.attributes.addedBy)) {
        mappedObject.addedBy = apiData.attributes.addedBy;
      }
    }
    return mappedObject;
  }

  setUserMapper(value: UserMapper) {
    this.userMapper = value;
  }

  setImageMapper(value: ImageMapper) {
    this.imageMapper = value;
  }

  setMeterMapper(value: MeterMapper) {
    this.meterMapper = value;
  }
}
