import { Mapper } from '@core/models/map-struct/mapper.interface';
import { Injectable, Injector } from '@angular/core';
import { PropertyMapper } from '@core/models/map-struct/property.mapper';
import { Unit } from '@core/models/interfaces/unit.interface';
import { UnitRelationshipMapper } from '@core/models/map-struct/unit-relationship.mapper';
import { IncomeMapper } from '@core/models/map-struct/income.mapper';
import { ExpenseMapper } from '@core/models/map-struct/expense.mapper';

@Injectable({
  providedIn: 'root',
})
export class UnitMapper implements Mapper<Unit> {

  private propertyMapper: PropertyMapper;
  private incomeMapper: IncomeMapper;
  private expenseMapper: ExpenseMapper;
  private unitRelationshipMapper: UnitRelationshipMapper;

  constructor(private readonly injector: Injector) {}


  toEntity(apiData: any): Unit | undefined {
    if (!('attributes' in apiData)) {
      return undefined;
    }

    const mappedObject = {
      id: apiData.id,
      description: apiData.attributes.description,
      label: apiData.attributes.label,
      floor: apiData.attributes.floor,
      area: apiData.attributes.area ?? 0,
    } as Unit;

    if ('property' in apiData.attributes && apiData.attributes.property?.data != null) {
      if (!this.propertyMapper) {
        this.propertyMapper = this.injector.get(PropertyMapper);
        this.propertyMapper.setUnitMapper(this);
      }

      mappedObject.property = this.propertyMapper.toEntity(apiData.attributes.property.data);
    }

    if ('relationships' in apiData.attributes && apiData.attributes.relationships?.data != null) {
      if (!this.unitRelationshipMapper) {
        this.unitRelationshipMapper = this.injector.get(UnitRelationshipMapper);
        this.unitRelationshipMapper.setUnitMapper(this);
      }
      mappedObject.relationships =
        apiData.attributes.relationships.data.map((relation) => this.unitRelationshipMapper.toEntity(relation));
    }

    return mappedObject;
  }

  setPropertyMapper(mapper: PropertyMapper) {
    this.propertyMapper = mapper;
  }

  setIncomeMapper(mapper: IncomeMapper) {
    this.incomeMapper = mapper;
  }

  setExpenseMapper(mapper: ExpenseMapper) {
    this.expenseMapper = mapper;
  }

  setUnitRelationshipMapper(mapper: UnitRelationshipMapper) {
    this.unitRelationshipMapper = mapper;
  }
}
