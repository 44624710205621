import { Injectable, Injector } from '@angular/core';
import { Mapper } from '@core/models/map-struct/mapper.interface';
import { Contact } from '@core/models/interfaces/contact.interface';
import { PropertyMapper } from '@core/models/map-struct/property.mapper';
import { CompanyMapper } from './company.mapper';

@Injectable({
  providedIn: 'root',
})
export class ContactMapper implements Mapper<Contact> {

  private propertyMapper: PropertyMapper;
  private companyMapper: CompanyMapper;

  constructor(private injector: Injector) {}

  toEntity(apiData: any): Contact | undefined {
    if (!('attributes' in apiData)) {
      return undefined;
    }

    const mappedObject = {
      id: apiData.id ?? undefined,
      position: apiData.attributes.position,
      companyName: apiData.attributes.companyName,
      contactName: apiData.attributes.contactName,
      phoneNumber: apiData.attributes.phoneNumber,
      email: apiData.attributes.email ?? null,
      websiteUrl: apiData.attributes.websiteUrl ?? null,
      address: apiData.attributes.address ?? null,
    } as Contact;

    if ('properties' in apiData.attributes && apiData.attributes.properties != null) {
      if (apiData.attributes.properties?.data != null) {
        if (!this.propertyMapper) {
          this.propertyMapper = this.injector.get(PropertyMapper);
          this.propertyMapper.setContactMapper(this);
        }
        mappedObject.properties = apiData.attributes.properties.data.map((property) => this.propertyMapper.toEntity(property));
      } else if (!('data' in apiData.attributes.properties)) {
        mappedObject.properties = apiData.attributes.properties;
      }
    }

    if ('ownerCompany' in apiData.attributes && apiData.attributes.ownerCompany != null) {
      if (apiData.attributes.ownerCompany?.data != null) {
        if (!this.companyMapper) {
          this.companyMapper = this.injector.get(CompanyMapper);
        }
        mappedObject.ownerCompany = this.companyMapper.toEntity(apiData.attributes.ownerCompany.data);
      } else if (!('data' in apiData.attributes.ownerCompany)) {
        mappedObject.ownerCompany = apiData.attributes.ownerCompany;
      }
    }

    return mappedObject;
  }

  setPropertyMapper(mapper: PropertyMapper) {
    this.propertyMapper = mapper;
  }

  setCompanyMapper(mapper: CompanyMapper) {
    this.companyMapper = mapper;
  }
}
