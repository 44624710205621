// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-avatar ion-img::part(image) {
  background: linear-gradient(var(--ion-color-primary), var(--ion-color-secondary), var(--ion-color-dark));
}

.clickable {
  cursor: pointer;
}

.link {
  cursor: pointer;
}

.bold {
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/blackboard-post/blackboard-post.component.scss"],"names":[],"mappings":"AACE;EACE,wGAAA;AAAJ;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,gBAAA;AADF","sourcesContent":["ion-avatar {\n  ion-img::part(image) {\n    background: linear-gradient(var(--ion-color-primary), var(--ion-color-secondary), var(--ion-color-dark));\n  }\n}\n\n.clickable {\n  cursor: pointer;\n}\n\n.link {\n  cursor: pointer;\n}\n\n.bold {\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
