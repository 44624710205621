import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { PipesModule } from '@shared/pipes/pipes.module';

import { BlackboardMaskComponent } from '@shared/components/blackboard-mask/blackboard-mask.component';
import { BlackboardPostComponent } from '@shared/components/blackboard-post/blackboard-post.component';
import { CenterInfoComponent } from '@shared/components/center-info/center-info.component';
import { ChatGroupMaskComponent } from '@shared/components/chat-group-mask/chat-group-mask.component';
import { CommentComponent } from '@shared/components/comment/comment.component';
import { CompanyContactCardComponent } from '@shared/components/company-contact-card/company-contact-card.component';
import { CompanyContactMaskComponent } from '@shared/components/company-contact-mask/company-contact-mask.component';
import { ContactCardComponent } from '@shared/components/contact-card/contact-card.component';
import { ContractorCompanyCardComponent } from '@shared/components/contractor-company-card/contractor-company-card.component';
import { ContractorCompanyMaskComponent } from '@shared/components/contractor-company-mask/contractor-company-mask.component';
import { DirectivesModule } from '@shared/directives/directives.module';
import { DocumentListComponent } from '@shared/components/document-list/document-list.component';
import { DocumentMaskComponent } from './document-mask/document-mask.component';
import { DropzoneComponent } from '@shared/components/dropzone/dropzone.component';
import { ImagePreviewComponent } from '@shared/components/image-preview/image-preview.component';
import { ManagerMaskComponent } from './manager-mask/manager-mask.component';
import { MediaCarouselModalComponent } from '@shared/components/media-carousel-modal/media-carousel-modal.component';
import { MeterMaskComponent } from './meter-mask/meter-mask.component';
import { MeterReadingMaskComponent } from './meter-reading-mask/meter-reading-mask.component';
import { MeterReadoutComponent } from '@shared/components/meter-readout/meter-readout.component';
import { PaginationComponent } from '@shared/components/pagination/pagination.component';
import { ProblemCardComponent } from '@shared/components/problem-card/problem-card.component';
import { ProblemChooserMaskComponent } from '@shared/components/problem-chooser-mask/problem-chooser-mask.component';
import { ProblemCreatorMaskComponent } from './problem-creator-mask/problem-creator-mask.component';
import { PropertyCardComponent } from '@shared/components/property-card/property-card.component';
import { PropertyContactsCardComponent } from '@shared/components/property-contacts-card/property-contacts-card.component';
import { PropertyGridComponent } from '@shared/components/property-grid/property-grid.component';
import { PropertyListComponent } from '@shared/components/property-list/property-list.component';
import { PropertyMaskComponent } from '@shared/components/property-mask/property-mask.component';
import { RelationshipMaskComponent } from '@shared/components/relationship-mask/relationship-mask.component';
import { TenantCardComponent } from './tenant-card/tenant-card.component';
import { UnitMaskComponent } from './unit-mask/unit-mask.component';
import { UnitsCardComponent } from '@shared/components/units-card/units-card.component';
import { UserAssignMaskComponent } from '@shared/components/user-assign-mask/user-assign-mask.component';
import { UserCardComponent } from './user-card/user-card.component';
import {
  ContractorCompanyAssignMaskComponent,
} from '@shared/components/contractor-company-assign-mask/contractor-company-assign-mask.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChatGroupParticipantListComponent } from '@shared/components/chat-group-participant-list/chat-group-participant-list.component';
import { TranslateModule } from '@ngx-translate/core';

import { register } from 'swiper/element/bundle';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { RelationshipRentMaskComponent } from '@shared/components/relationship-rent-mask/relationship-rent-mask.component';
import { CostTypeFieldComponent } from '@shared/components/cost-type-field/cost-type-field.component';
import { ChooseFormulaModalComponent } from '@shared/components/cost-type-field/choose-formula-modal/choose-formula-modal.component';
import { AddCostTypeModalComponent } from '@shared/components/cost-type-field/add-cost-type-modal/add-cost-type-modal.component';
import { ChooseContactMaskComponent } from '@shared/components/choose-contact-mask/choose-contact-mask.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BulkUserAssignMaskComponent } from '@shared/components/bulk-user-assign-mask/bulk-user-assign-mask.component';


register();

const components = [
  AddCostTypeModalComponent,
  BlackboardMaskComponent,
  BlackboardPostComponent,
  BulkUserAssignMaskComponent,
  ChooseFormulaModalComponent,
  CenterInfoComponent,
  ChatGroupMaskComponent,
  ChooseContactMaskComponent,
  CommentComponent,
  CompanyContactCardComponent,
  CompanyContactMaskComponent,
  ContactCardComponent,
  ContractorCompanyAssignMaskComponent,
  ContractorCompanyCardComponent,
  ContractorCompanyMaskComponent,
  CostTypeFieldComponent,
  DocumentListComponent,
  DocumentMaskComponent,
  DropzoneComponent,
  ImagePreviewComponent,
  ManagerMaskComponent,
  MediaCarouselModalComponent,
  MeterMaskComponent,
  MeterReadingMaskComponent,
  MeterReadoutComponent,
  PaginationComponent,
  ProblemCardComponent,
  ProblemChooserMaskComponent,
  ProblemCreatorMaskComponent,
  PropertyCardComponent,
  PropertyContactsCardComponent,
  PropertyGridComponent,
  PropertyListComponent,
  PropertyMaskComponent,
  RelationshipMaskComponent,
  TenantCardComponent,
  UnitMaskComponent,
  UnitsCardComponent,
  UserAssignMaskComponent,
  UserCardComponent,
  ChatGroupParticipantListComponent,
  RelationshipRentMaskComponent,
];

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule,
    DirectivesModule,
    MatTooltipModule,
    TranslateModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatCheckboxModule,
    MatRadioModule,
    MatIconModule,
    PdfViewerModule,
    NgOptimizedImage,
  ],
  declarations: components,
  exports: components,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedComponentsModule {

}
