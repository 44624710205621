import { Injectable } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { User } from '@core/models/interfaces/user.interface';
import { Pagination } from '@core/models/datatypes/pagination.interface';
import { RoleStatus, UserMapper } from '@core/models/map-struct/user.mapper';
import { MediaService } from '@core/api/media/media.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  private readonly entityName = 'api/users';
  private readonly entityRef = 'plugin::users-permissions.user';

  constructor(
    private readonly apiService: ApiService,
    private readonly mediaService: MediaService,
    private readonly userMapper: UserMapper,
  ) {
  }

  public async getMe(): Promise<User> {
    return await this.apiService.getSingletonItem(`${this.entityName}/me`, {
      populate: ['profilePicture', 'address', 'role', 'employeeIn'],
    }) as User;
  }

  public async getAdvancedRoleStatus(id: number | string = 'me'): Promise<RoleStatus> {
    const user = await this.apiService.getSingletonItem(`${this.entityName}/${id}`, {
      populate: ['renteeIn', 'proprietorIn'],
    }) as User;

    return UserMapper.getAdvancedRole(user);
  }

  public async updateUser(user: User): Promise<any> {
    return this.apiService.put(`${this.entityName}/${user.id}`, user);
  }

  public async updatePhoto(id: number, photo: string | File | Blob, fileName: string): Promise<Response> {
    return this.mediaService.uploadFiles(id, this.entityRef, 'profilePicture', photo, undefined, fileName);
  }

  public async getUsers(
    searchValue?: string,
    searchMode: SearchModes = 'any',
    fuzzySearch = false,
    propertyIds: number[] = undefined,
    page = 1,
    pageSize = 25,
  ): Promise<{ data: any; meta: Pagination }> {
    let filters = {};
    if (searchMode && searchValue) {
      switch (searchMode) {
        case 'address': {
          filters = {
            address: {
              $or: [
                { street: { $containsi: searchValue } },
                { streetNumber: { $containsi: searchValue } },
                { zip: { $containsi: searchValue } },
                { city: { $containsi: searchValue } },
                { country: { $containsi: searchValue } },
              ],
            },
          };
          break;
        }
        case 'property': {
          filters = {
            $or: [
              {
                renteeIn: {
                  unit: {
                    property: {
                      id: {
                        $in: propertyIds,
                      },
                    },
                  },
                },
              },
              {
                proprietorIn: {
                  unit: {
                    property: {
                      id: {
                        $in: propertyIds,
                      },
                    },
                  },
                },
              },
            ],
          };
          break;
        }
        case 'uid': {
          filters = {
            id: searchValue,
          };
          break;
        }
        default: {
          const parts = searchValue.trim().split(' ');
          const searches: any = [
            { firstname: { $containsi: searchValue } },
            { lastname: { $containsi: searchValue } },
            { companyName: { $containsi: searchValue } },
            { email: { $containsi: searchValue } },
            { id: { $containsi: searchValue } },
            {
              address: {
                $or: [
                  { street: { $containsi: searchValue } },
                  { streetNumber: { $containsi: searchValue } },
                  { zip: { $containsi: searchValue } },
                  { city: { $containsi: searchValue } },
                  { country: { $containsi: searchValue } },
                ],
              },
            },
          ];

          if (fuzzySearch && parts.length > 1) {
            const more = parts.splice(5).join(' ');
            if (more) {
              parts.push(more);
            }
            parts.forEach((part) => {
              searches.push(
                ...[
                  { firstname: { $containsi: part } },
                  { lastname: { $containsi: part } },
                  { companyName: { $containsi: part } },
                  {
                    address: {
                      $or: [
                        { street: { $containsi: part } },
                        { streetNumber: { $containsi: part } },
                        { zip: { $containsi: part } },
                        { city: { $containsi: part } },
                        { country: { $containsi: part } },
                      ],
                    },
                  },
                ],
              );
            });
          }

          filters = {
            $or: searches,
          };
        }
      }
    }

    return this.apiService.getWithCaching(this.entityName, {
      filters,
      populate: [
        'role',
        'address',
        'profilePicture',
        'renteeIn',
        'proprietorIn',
      ],
      pagination: {
        page,
        pageSize,
      },
      sort: ['id'],
    }).then((res) => ({
      data: res.data.map(
        (user) => this.userMapper.toEntity(user),
      ),
      meta: ('pagination' in res.meta ? res.meta.pagination : res.meta) as Pagination,
    }));
  }

  public async getTenants(
    page = 1,
    pageSize = 25,
  ): Promise<{ data: any; meta: Pagination }> {
    return this.apiService.getWithCaching(this.entityName, {
      filters: {
        employeeIn: {
          id: { $null: true },
        }
      },
      populate: [
        'role',
        'address',
        'profilePicture',
        'renteeIn',
        'proprietorIn',
      ],
      pagination: {
        page,
        pageSize,
      },
      sort: ['id'],
    }).then((res) => ({
      data: res.data.map(
        (user) => this.userMapper.toEntity(user),
      ),
      meta: ('pagination' in res.meta ? res.meta.pagination : res.meta) as Pagination,
    }));
  }

  public async resetPassword(email): Promise<any> {
    return this.apiService.post('api/auth/forgot-password', { email });
  }

  public async findManagerByEmail(email: string): Promise<User | undefined> {
    return (this.apiService.get(`${this.entityName}`, {
        filters: {
          email: {
            $eq: email,
          },
          role: {
            type: 'manager',
          },
        },
        populate: ['profilePicture', 'address', 'role'],
      })
        .then(res => res.data.map((u) => this.userMapper.toEntity(u)))
        .then(users => users.filter(u => !!u))
        .then(users => users.length >= 1 ? users[0] : undefined)
    );
  }

  public getUserById(id: number): Promise<User> {
    return this.apiService.get(`${this.entityName}/${id}`, {
      populate: ['profilePicture', 'address', 'role'],
    }).then(user => user as User);
  }

  public async blockUserById(id: number, blocked = true): Promise<any> {
    return this.apiService.put(`${this.entityName}/${id}`, {
      blocked,
    });
  }

  public async deleteUserById(userId: number): Promise<any> {
    return this.apiService.delete(`${this.entityName}/${userId}`);
  }
}

export type SearchModes = 'any' | 'uid' | 'property' | 'address';
