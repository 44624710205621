import { Injectable, Injector } from '@angular/core';
import { User } from '@core/models/interfaces/user.interface';
import { Mapper } from '@core/models/map-struct/mapper.interface';
import { ImageMapper } from '@core/models/map-struct/image.mapper';
import { PostMapper } from '@core/models/map-struct/post.mapper';
import { CompanyMapper } from '@core/models/map-struct/company.mapper';
import { UnitRelationshipMapper } from './unit-relationship.mapper';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UserMapper implements Mapper<User> {
  private imageMapper: ImageMapper;
  private postMapper: PostMapper;
  private companyMapper: CompanyMapper;
  private unitRelationshipMapper: UnitRelationshipMapper;

  constructor(
    private readonly injector: Injector,
    private translateService :TranslateService,
    ) {}

  static getAdvancedRole(user: User): RoleStatus {
    let isRentee = false;
    let isProprietor = false;

    if (user.renteeIn != null && user.renteeIn.length) {
      isRentee = true;
    }

    if (user.proprietorIn != null && user.proprietorIn.length) {
      isProprietor = true;
    }

    if (isRentee && isProprietor) {
      return 'both';
    }
    if (isRentee) {
      return 'rentee';
    }
    if (isProprietor) {
      return 'proprietor';
    }
    return 'none';
  }

  toEntity(apiData: any): User | undefined {
    if (!('attributes' in apiData)) {
      return undefined;
    }
    let mappedObject = {
      id: apiData.id,
      username: apiData.attributes.username,
      email: apiData.attributes.email,
      provider: apiData.attributes.provider,
      confirmed: apiData.attributes.confirmed,
      blocked: apiData.attributes.blocked,
      companyName: apiData.attributes.companyName,
      firstname: apiData.attributes.firstname,
      lastname: apiData.attributes.lastname,
      phoneNumber: apiData.attributes.phoneNumber,
    } as User;

    if ('profilePicture' in apiData.attributes && apiData.attributes.profilePicture) {
      if (apiData.attributes.profilePicture?.data != null) {
        if (!this.imageMapper) {
          this.imageMapper = this.injector.get(ImageMapper);
        }
        mappedObject.profilePicture = this.imageMapper.toEntity(apiData.attributes.profilePicture.data);
      } else if (!('data' in apiData.attributes.profilePicture)) {
        mappedObject.profilePicture = apiData.attributes.profilePicture;
      }
    }

    if ('address' in apiData.attributes && apiData.attributes.address) {
      if (apiData.attributes.address?.data != null) {
        mappedObject.address = { ...apiData.attributes.address.data };
      } else if (!('data' in apiData.attributes.address)) {
        mappedObject.address = { ...apiData.attributes.address };
      }
    }

    if ('role' in apiData.attributes && apiData.attributes.role) {
      if (apiData.attributes.role?.data != null) {
        mappedObject.role = { ...apiData.attributes.role.data };
      } else if (!('data' in apiData.attributes.role)) {
        mappedObject.role = { ...apiData.attributes.role };
      }
    }

    if ('posts' in apiData.attributes && apiData.attributes.posts) {
      if (apiData.attributes.posts?.data != null) {
        if (!this.postMapper) {
          this.postMapper = this.injector.get(PostMapper);
          this.postMapper.setUserMapper(this);
        }
        mappedObject.posts = apiData.attributes.posts?.data.map((post) => this.postMapper.toEntity(post));
      } else if (!('data' in apiData.attributes.posts)) {
        mappedObject.role = { ...apiData.attributes.role };
      }
    }

    if ('employeeIn' in apiData.attributes && apiData.attributes.employeeIn) {
      if (apiData.attributes.employeeIn?.data != null) {
        if (!this.companyMapper) {
          this.companyMapper = this.injector.get(CompanyMapper);
        }
        mappedObject.employeeIn = this.companyMapper.toEntity(apiData.attributes.employeeIn.data);
      } else if (!('data' in apiData.attributes.employeeIn)) {
        mappedObject.employeeIn = apiData.attributes.employeeIn;
      }
    }

    if ('renteeIn' in apiData.attributes && apiData.attributes.renteeIn) {
      if (apiData.attributes.renteeIn?.data != null) {
        if (!this.unitRelationshipMapper) {
          this.unitRelationshipMapper = this.injector.get(UnitRelationshipMapper);
          this.unitRelationshipMapper.setUserMapper(this);
        }
        mappedObject.renteeIn = apiData.attributes.renteeIn.data.map(
          (relationship) => this.unitRelationshipMapper.toEntity(relationship)
        );
      } else if (!('data' in apiData.attributes.renteeIn)) {
        mappedObject.renteeIn = apiData.attributes.renteeIn;
      }
    }

    if ('proprietorIn' in apiData.attributes && apiData.attributes.proprietorIn) {
      if (apiData.attributes.proprietorIn?.data != null) {
        if (!this.unitRelationshipMapper) {
          this.unitRelationshipMapper = this.injector.get(UnitRelationshipMapper);
          this.unitRelationshipMapper.setUserMapper(this);
        }
        mappedObject.proprietorIn = apiData.attributes.proprietorIn.data.map(
          (relationship) => this.unitRelationshipMapper.toEntity(relationship)
        );
      } else if (!('data' in apiData.attributes.proprietorIn)) {
        mappedObject.proprietorIn = apiData.attributes.proprietorIn;
      }
    }

    if (mappedObject.role?.type === 'tenant') {
      const status = UserMapper.getAdvancedRole(mappedObject);
      let role = mappedObject.role?.name;
      switch (status) {
        case 'both':
          role = this.translateService.instant('pages.renter-owner');
          break;
        case 'rentee':
          role = this.translateService.instant('pages.renter');
          break;
        case 'proprietor':
          role = this.translateService.instant('pages.owner');
          break;
        default:
          break;
      }
      mappedObject = {...mappedObject, advancedRole: role};
    }

    return mappedObject;
  }

  setImageMapper(value: ImageMapper) {
    this.imageMapper = value;
  }

  setPostMapper(mapper: PostMapper) {
    this.postMapper = mapper;
  }

  setCompanyMapper(mapper: CompanyMapper) {
    this.companyMapper = mapper;
  }

  setUnitRelationshipMapper(mapper: UnitRelationshipMapper) {
    this.unitRelationshipMapper = mapper;
  }
}

export type RoleStatus = 'rentee' | 'proprietor' | 'both' | 'none';
