import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nl2br',
  pure: true,
})

export class NewLineToBreakPipe implements PipeTransform {
  transform(value?: string): string {
    if (!value) {
      return '';
    }
    return value.replace(/\n/g, '<br />');
  }
}
