import { Injectable } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { Pagination } from '@core/models/datatypes/pagination.interface';
import { DocumentFolderMapper } from '@core/models/map-struct/document-folder.mapper';
import { Document, DocumentFolder } from '@core/models/interfaces/document.interface';
import { UserService } from '@core/api/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentFolderService {

  private readonly entityName = 'api/document-folders';
  private readonly entityRef = 'api::document-folder.document-folder';

  constructor(
    private readonly apiService: ApiService,
    private readonly documentFolderMapper: DocumentFolderMapper,
    private readonly userService: UserService,
  ) {

  }

  getFolders(parent?: number, propertyId?: number, unitId?: number, page = 1, pageSize = 25) {
    const filters: Record<string, any> = {};

    if (propertyId) {
      filters.property = propertyId;
    } else {
      filters.property = { id: { $null: true } };
    }
    if (unitId) {
      filters.unit = unitId;
    } else {
      filters.unit = { id: { $null: true } };
    }

    if (parent) {
      filters.parent = parent;
    } else {
      filters.parent = { id: { $null: true } };
    }

    return this.apiService.getWithCaching(
      this.entityName,
      {
        populate: [
          'company',
          'property',
          'unit',
          'documents',
          'children',
          'parent',
          'parent.property',
          'parent.unit'
        ],
        filters,
        pagination: {
          page,
          pageSize,
        },
        sort: [`name:asc`, 'id:asc'],
      },
    ).then((res) => ({
      data: res.data.map(
        (doc) => this.documentFolderMapper.toEntity(doc),
      ),
      meta: ('pagination' in res.meta ? res.meta.pagination : res.meta) as Pagination,
    }));
  }

  async createFolder(folderName: string, parent?: number, property?: number, unit?: number) {
    return this.apiService.post(this.entityName, {
      data: {
        name: folderName,
        parent: parent,
        property: property,
        unit: unit,
      },
    }, {
      populate: [
        'company',
        'property',
        'unit',
        'documents',
        'children',
        'parent',
      ],
    }).then(res => this.documentFolderMapper.toEntity(res.data));
  }

  public async updateFolder(folder: DocumentFolder | (Omit<DocumentFolder, 'participants'> & {participants: any})) {
    const me = await this.userService.getMe();

    let participants = folder.participants;
    if (Array.isArray(participants)) {
      participants = folder.participants?.map((p) => {
        if (typeof p === 'number') {
          return p;
        } else {
          return p.id ?? undefined;
        }
      })?.filter(Boolean) ?? [];
    }

    return this.apiService.put(`${this.entityName}/${folder.id}`, {
      data: {
        ...folder,
        company: folder.company?.id ?? me.employeeIn?.id,
        participants
      },
    });
  }

  async deleteFolder(folder: DocumentFolder) {
      return this.apiService.delete(`${this.entityName}/${folder.id}`);
  }
}
