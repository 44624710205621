import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { ContractorMapper } from '@core/models/map-struct/contractor.mapper';
import { Contractor } from '@core/models/interfaces/contractor.interface';
import { Pagination } from '@core/models/datatypes/pagination.interface';

@Injectable({
  providedIn: 'root',
})
export class ContractorService {

  private readonly entityName = 'api/contractors';
  private readonly entityRef = 'api::contractor.contractor';

  constructor(
    private readonly apiService: ApiService,
    private readonly contractorMapper: ContractorMapper,
  ) { }

  async getContractors(
    search?: string,
    fuzzySearch = true,
    page = 1,
    pageSize = 100,
  ): Promise<{data: Contractor[]; meta: Pagination}> {
    let filter = {};

    if (search !== undefined && search.trim().length > 0) {
      const parts = search.trim().split(' ');
      const searches: any[] = [
        { companyName: { $containsi: search } },
        { service: { $containsi: search } },
        { companyName: { $containsi: search } },
        { service: { $containsi: search } },
        {
          isUser: {
            $or: [
              { firstname: { $containsi: search } },
              { lastname: { $containsi: search } },
              { email: { $containsi: search } },
              { address: {
                  $or: [
                    { street: { $containsi: search } },
                    { streetNumber: { $containsi: search } },
                    { zip: { $containsi: search } },
                    { city: { $containsi: search } },
                    { country: { $containsi: search } },
                  ],
                },
              },
            ],
          },
        },
      ];
      if (fuzzySearch && parts.length > 1) {
        const more = parts.splice(5).join(' ');
        if (more) {
          parts.push(more);
        }
        parts.forEach((part) => {
          searches.push(
            ...[
              { companyName: { $containsi: part } },
              { service: { $containsi: part } },
              {
                isUser: {
                  $or: [
                    { firstname: { $containsi: part } },
                    { lastname: { $containsi: part } },
                    { email: { $containsi: part } },
                    { address: {
                        $or: [
                          { street: { $containsi: part } },
                          { streetNumber: { $containsi: part } },
                          { zip: { $containsi: part } },
                          { city: { $containsi: part } },
                          { country: { $containsi: part } },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
          );
        });
      }

      filter = {
        $or: searches,
      };
    }

    return this.apiService.getWithCaching(this.entityName, {
      filters: filter,
      populate: [
        'isUser',
        'isUser.role',
        'isUser.address',
        'isUser.profilePicture',
      ],
      pagination: {
        page,
        pageSize,
      },
    }).then(res => ({
      data: res.data.map((item) => this.contractorMapper.toEntity(item)),
      meta: ('pagination' in res.meta ? res.meta.pagination : res.meta),
    }));
  }

  async updateById(id: number, data: Omit<Contractor, 'id' | 'ownerCompany' | 'isUser'>) {
    return this.apiService.put(`${this.entityName}/${id}`,{
      data
    });
  }

  async deleteById(id: number) {
    return this.apiService.delete(`${this.entityName}/${id}`);
  }
}
