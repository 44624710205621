import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Contractor } from '@core/models/interfaces/contractor.interface';
import { Logger } from '@core/logger/logger.service';

const logger = new Logger('a.@s.c.c.ContractorCompanyMaskComponent');

@Component({
  selector: 'app-contractor-company-mask',
  templateUrl: './contractor-company-mask.component.html',
  styleUrls: ['./contractor-company-mask.component.scss'],
})
export class ContractorCompanyMaskComponent implements OnInit {
  public static readonly MODAL_ID = 'contractor-company-mask-component';

  @Input() contractor?: Contractor;

  form: UntypedFormGroup;

  constructor(
    private readonly modalController: ModalController,
    private readonly formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      service: [this.contractor?.service ?? '', Validators.compose([Validators.required])],
      companyName: [this.contractor?.companyName ?? '', Validators.compose([Validators.required])],
      firstname: [
        {
          value: this.contractor?.isUser?.firstname ?? '',
          disabled: !!this.contractor,
        },
        Validators.compose([Validators.required]),
      ],
      lastname: [
        {
          value: this.contractor?.isUser?.lastname ?? '',
          disabled: !!this.contractor,
        },
        Validators.compose([Validators.required]),
      ],
      email: [
        {
          value: this.contractor?.isUser?.email ?? '',
          disabled: !!this.contractor,
        },
        Validators.compose([Validators.required, Validators.email]),
      ],
    });
  }

  async dismiss() {
    await this.modalController.dismiss(undefined, undefined, ContractorCompanyMaskComponent.MODAL_ID);
  }

  async submit() {
    if (this.form.invalid || this.form.disabled) {
      return;
    }

    this.form.disable();
    try {
      await this.modalController.dismiss(this.form.value, this.contractor ? 'edit' : 'add', ContractorCompanyMaskComponent.MODAL_ID);
      this.form.reset();
    } catch (e) {
      logger.error(e);
    } finally {
      this.form?.enable();
    }

  }

}
