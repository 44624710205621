import { Component, Input, OnInit } from '@angular/core';
import { ContractorService } from '@app/@core/api/contractor/contractor.service';
import { Contractor } from '@core/models/interfaces/contractor.interface';
import { ModalController, NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { fuzzy } from '@core/utils/fuzzy';
import { UsesCacheComponent } from '@core/api/cache/uses-cache';
import { CacheService } from '@app/@core/api/cache/cache.service';

@Component({
  selector: 'app-contractor-company-assign-mask',
  templateUrl: './contractor-company-assign-mask.component.html',
  styleUrls: ['./contractor-company-assign-mask.component.scss'],
})
export class ContractorCompanyAssignMaskComponent extends UsesCacheComponent implements OnInit {

  public static readonly SUCCESS_ROLE = 'assign';
  public static readonly MODAL_ID = 'contractor-company-assign-mask-component';

  @Input() assigned?: Contractor;

  private internalContractors?: Contractor[];
  private currentContractors = new BehaviorSubject<Contractor[]>(null);

  private searchValue = '';

  constructor(
    private readonly contractorService: ContractorService,
    private readonly modalController: ModalController,
    private readonly navController: NavController,
    protected readonly cacheService: CacheService,
  ) {
    super(cacheService);
  }

  get contractors() {
    return this.currentContractors;
  }

  public trackById = (i, entity) => entity.id;

  async ngOnInit() {
    await this.loadData();
    this.isInitialized = true;
  }

  public async dismiss(): Promise<void> {
    await this.modalController.dismiss({
      dismissed: true,
    }, undefined, ContractorCompanyAssignMaskComponent.MODAL_ID);
  }

  search($event: any) {
    this.searchValue = $event.detail.value;
    this.currentContractors.next(this.internalContractors.filter((c) => fuzzy(
          `${c.companyName} ${c.service}`,
          this.searchValue,
          .5,
        ) || c.isUser && (
          fuzzy(`${c.isUser?.firstname} ${c.isUser?.lastname}`, this.searchValue, .75)
          || fuzzy(c.isUser?.email, this.searchValue, .90)
        ),
      ),
    );
  }

  resetSearch() {
    this.searchValue = undefined;
    this.currentContractors.next(this.internalContractors);
  }

  async select(contractor: Contractor) {
    if (contractor.id === this.assigned?.id) {
      return;
    }
    await this.modalController.dismiss({
      id: contractor.id,
      contractor,
    }, ContractorCompanyAssignMaskComponent.SUCCESS_ROLE, ContractorCompanyAssignMaskComponent.MODAL_ID);
  }

  async createContractor(event) {
    event.preventDefault();
    event.stopPropagation();
    await this.dismiss();
    await this.navController.navigateForward(['/', 'app', 'contractors']);
  }

  onCacheCleared(lastRefresh: Date): Promise<void> | void {
    return this.loadData();
  }

  private async loadData() {
    let hasMore = false;
    let page = 1;
    this.internalContractors = [];
    do {
      const { data, meta } = await this.contractorService.getContractors(undefined, false, page++, 100);
      data.forEach((contractor) => this.internalContractors.push(contractor));
      hasMore = meta.pageCount > page;
    } while (hasMore);
    this.currentContractors.next(this.internalContractors);
  }
}
