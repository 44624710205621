import { Injectable } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { AddPushDeviceResponse, RemovePushDeviceResponse } from '@core/models/app/push-notification.interface';

@Injectable({
  providedIn: 'root',
})
export class PushDeviceService {

  private readonly entityName = 'api/account/push-devices';

  constructor(
    private readonly apiService: ApiService,
  ) { }

  public add(pushToken: string, language: string): Promise<AddPushDeviceResponse> {
    return this.apiService.post(this.entityName, { pushToken, language });
  }

  public remove(userId: string | number, pushToken: string): Promise<RemovePushDeviceResponse> {
    return this.apiService.delete(`${this.entityName}/${pushToken}`, { userId });
  }

}
