// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  width: 100%;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 1fr;
  display: grid;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 580px) {
  .pagination {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  .controls {
    width: 100%;
    display: grid;
    grid-auto-flow: row;
  }
}
.controls {
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: center;
  flex-wrap: wrap;
}
.controls div {
  flex-wrap: nowrap;
}

.amount {
  justify-self: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/pagination/pagination.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kCAAA;EACA,uBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE;IACE,0BAAA;IACA,+BAAA;EACF;EAEA;IACE,WAAA;IACA,aAAA;IACA,mBAAA;EAAF;AACF;AAGA;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,uBAAA;EACA,eAAA;AADF;AAGE;EACE,iBAAA;AADJ;;AAKA;EACE,sBAAA;AAFF","sourcesContent":[".pagination {\n  width: 100%;\n  grid-template-columns: 1fr 3fr 1fr;\n  grid-template-rows: 1fr;\n  display: grid;\n  justify-content: space-between;\n  align-items: center;\n}\n\n@media screen and (max-width: 580px) {\n  .pagination {\n    grid-template-columns: 1fr;\n    grid-template-rows: 1fr 1fr 1fr;\n  }\n\n  .controls {\n    width: 100%;\n    display: grid;\n    grid-auto-flow: row;\n  }\n}\n\n.controls {\n  display: flex;\n  align-items: center;\n  justify-self: center;\n  justify-content: center;\n  flex-wrap: wrap;\n\n  div {\n    flex-wrap: nowrap;\n  }\n}\n\n.amount {\n  justify-self: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
