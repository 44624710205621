import { Mapper } from '@core/models/map-struct/mapper.interface';
import { Injectable, Injector } from '@angular/core';
import { CompanyMapper } from '@core/models/map-struct/company.mapper';
import { DocumentFolder } from '../interfaces/document.interface';
import { DocumentMapper } from '@core/models/map-struct/document.mapper';
import { PropertyMapper } from '@core/models/map-struct/property.mapper';
import { UnitMapper } from '@core/models/map-struct/unit.mapper';
import { UserMapper } from '@core/models/map-struct/user.mapper';

@Injectable({
  providedIn: 'root',
})
export class DocumentFolderMapper implements Mapper<DocumentFolder> {

  private documentMapper: DocumentMapper;
  private companyMapper: CompanyMapper;
  private propertyMapper: PropertyMapper;
  private unitMapper: UnitMapper;
  private userMapper: UserMapper;

  constructor(private injector: Injector) {

  }

  toEntity(apiData: any, recursive = true): DocumentFolder | undefined {
    if (!('attributes' in apiData)) {
      return undefined;
    }

    const mappedObject = {
      id: apiData.id,
      name: apiData.attributes.name,
      createdAt: apiData.attributes.createdAt ? new Date(apiData.attributes.createdAt) : undefined,
    } as DocumentFolder;

    if ('company' in apiData.attributes && apiData.attributes.company != null) {
      if (apiData.attributes.company?.data != null) {
        if (!this.companyMapper) {
          this.companyMapper = this.injector.get(CompanyMapper);
        }
        mappedObject.company = this.companyMapper.toEntity(apiData.attributes.company.data);
      } else if (!('data' in apiData.attributes.company)) {
        mappedObject.company = apiData.attributes.company;
      }
    }

    if ('property' in apiData.attributes && apiData.attributes.property != null) {
      if (apiData.attributes.property?.data != null) {
        if (!this.propertyMapper) {
          this.propertyMapper = this.injector.get(PropertyMapper);
        }
        mappedObject.property = this.propertyMapper.toEntity(apiData.attributes.property.data);
      } else if (!('data' in apiData.attributes.property)) {
        mappedObject.property = apiData.attributes.property;
      }
    }

    if ('unit' in apiData.attributes && apiData.attributes.unit != null) {
      if (apiData.attributes.unit?.data != null) {
        if (!this.unitMapper) {
          this.unitMapper = this.injector.get(UnitMapper);
        }
        mappedObject.unit = this.unitMapper.toEntity(apiData.attributes.unit.data);
      } else if (!('data' in apiData.attributes.unit)) {
        mappedObject.unit = apiData.attributes.unit;
      }
    }

    if ('parent' in apiData.attributes && apiData.attributes.parent != null) {
      if (apiData.attributes.parent?.data != null) {
        mappedObject.parent = this.toEntity(apiData.attributes.parent.data, false);
      } else if (!('data' in apiData.attributes.parent)) {
        mappedObject.parent = apiData.attributes.parent;
      }
    }

    if ('participants' in apiData.attributes && apiData.attributes.participants != null) {
      if (apiData.attributes.participants?.data != null && Array.isArray(apiData.attributes.participants.data)) {
        if (!this.userMapper) {
          this.userMapper = this.injector.get(UserMapper);
        }
        mappedObject.participants = apiData.attributes.participants.data.map(user => this.userMapper.toEntity(user)).filter(Boolean);
      } else if (!('data' in apiData.attributes.participants)) {
        mappedObject.participants = apiData.attributes.participants;
      }
    }

    if (recursive) {
      if ('children' in apiData.attributes && apiData.attributes.children != null) {
        if (apiData.attributes.children?.data != null && Array.isArray(apiData.attributes.children?.data)) {
          mappedObject.children = apiData.attributes.children.data.map(post => this.toEntity(post, false));
        } else if (!('data' in apiData.attributes.children)) {
          mappedObject.children = apiData.attributes.children;
        }
      }

      if ('documents' in apiData.attributes && apiData.attributes.documents != null) {
        if (apiData.attributes.documents?.data != null && Array.isArray(apiData.attributes.documents?.data)) {
          if (!this.documentMapper) {
            this.documentMapper = this.injector.get(DocumentMapper);
            this.documentMapper.setDocumentFolderMapper(this);
          }
          mappedObject.documents = apiData.attributes.documents.data.map(post => this.documentMapper.toEntity(post));
        } else if (!('data' in apiData.attributes.documents)) {
          mappedObject.documents = apiData.attributes.documents;
        }
      }
    }

    return mappedObject;
  }

  setDocumentMapper(mapper: DocumentMapper) {
    this.documentMapper = mapper;
  }
}
