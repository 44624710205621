import { Injectable } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { Post } from '@core/models/interfaces/post.interface';
import { PostMapper } from '@core/models/map-struct/post.mapper';
import { Pagination } from '@core/models/datatypes/pagination.interface';

@Injectable({
  providedIn: 'root',
})
export class PostService {
  private readonly entityName = 'api/posts';

  constructor(
    private readonly apiService: ApiService,
    private readonly postMapper: PostMapper,
  ) {}

  public async getPosts(page = 1, pageSize = 25): Promise<{ data: Post[]; meta: Pagination }> {
    return this.apiService.getWithCaching(this.entityName, {
      populate: {
        property: {
          populate: ['address'],
        },
        creator: {
          populate: ['profilePicture'],
        },
      },
      pagination: {
        page,
        pageSize,
      },
      sort: ['date:desc'],
    }).then((res) => ({
      data: res.data.map((post) => this.postMapper.toEntity(post)),
      meta: 'pagination' in res.meta ? res.meta.pagination : res.meta as Pagination,
    }));
  }

  public async getPostsByPropertyId(propertyId: number, page = 1, pageSize = 25):
    Promise<{ data: Post[]; meta: Pagination }> {
    return this.apiService.get(this.entityName, {
      filters: {
        property: {
          id: {
            $eq: propertyId,
          },
        },
      },
      pagination: {
        page,
        pageSize,
      },
      populate: ['creator', 'creator.profilePicture'],
      sort: ['date:desc'],
    }).then((res) => ({
        data: res.data.map((post) => this.postMapper.toEntity(post)),
        meta: ('pagination' in res.meta ? res.meta.pagination : res.meta) as Pagination,
      }),
    );
  }

  public async createPostForProperty(propertyId: number, userId: number, post: Post) {
    return this.apiService.post(this.entityName, {
      data: { ...post, property: propertyId, creator: userId },
    });
  }

  public async getPostById(postId: number) {
    return this.apiService.get(`${this.entityName}/${postId}`, {
      populate: ['creator', 'creator.profilePicture'],
    })
      .then((res => this.postMapper.toEntity(res.data)));
  }

  public async deletePost(post: Post) {
    return this.apiService.delete(`${this.entityName}/${post.id}`);
  }
}
