import { Injectable, Injector } from '@angular/core';
import { Mapper } from '@core/models/map-struct/mapper.interface';
import { UserMapper } from '@core/models/map-struct/user.mapper';
import { Contractor } from '@core/models/interfaces/contractor.interface';
import { CompanyMapper } from '@core/models/map-struct/company.mapper';

@Injectable({
  providedIn: 'root',
})
export class ContractorMapper implements Mapper<Contractor> {

  private userMapper: UserMapper;
  private companyMapper: CompanyMapper;

  constructor(private injector: Injector) {
  }

  toEntity(apiData: any): Contractor | undefined {
    if (!('attributes' in apiData)) {
      return undefined;
    }

    const mappedObject = {
      id: apiData.id,
      companyName: apiData.attributes.companyName,
      service: apiData.attributes.service,
    } as Contractor;

    if ('isUser' in apiData.attributes && apiData.attributes.isUser != null) {
      if (apiData.attributes.isUser?.data != null) {
        if (!this.userMapper) {
          this.userMapper = this.injector.get(UserMapper);
        }
        mappedObject.isUser = this.userMapper.toEntity(apiData.attributes.isUser.data);
      } else if (!('data' in apiData.attributes.isUser)) {
        mappedObject.isUser = apiData.attributes.isUser;
      }
    }

    if ('ownerCompany' in apiData.attributes && apiData.attributes.ownerCompany != null) {
      if (apiData.attributes.ownerCompany?.data != null) {
        if (!this.companyMapper) {
          this.companyMapper = this.injector.get(CompanyMapper);
        }
        mappedObject.ownerCompany = this.companyMapper.toEntity(apiData.attributes.ownerCompany.data);
      } else if (!('data' in apiData.attributes.user)) {
        mappedObject.ownerCompany = apiData.attributes.ownerCompany;
      }
    }

    return mappedObject;
  }
}
