import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Unit } from '@app/@core/models/interfaces/unit.interface';
import { User } from '@core/models/interfaces/user.interface';
import { Property } from '@core/models/interfaces/property.interface';
import { UnitRelationship } from '@core/models/interfaces/unit-relationship.interface';
import { getImageUrl } from '@core/models/utils/media.utils';

@Component({
  selector: 'app-tenant-card',
  templateUrl: './tenant-card.component.html',
  styleUrls: ['./tenant-card.component.scss'],
})
export class TenantCardComponent {

  @Input() tenant: User;
  @Input() property?: Property;
  @Input() unit?: Unit;
  @Input() relationship?: UnitRelationship;
  @Input() integrate = false;
  @Input() isManagerView = false;

  @Output() editClicked = new EventEmitter<Unit>();
  @Output() deleteClicked = new EventEmitter<Unit>();
  @Output() editRelationshipsClicked = new EventEmitter<Unit>();
  @Output() addMeterClicked = new EventEmitter<any>();

  constructor() { }

  get actualTenant() {
    return this.tenant;
  }

  getTenantProfilePicture() {
    if (this.tenant?.profilePicture) {
      return getImageUrl(this.tenant.profilePicture);
    }
    return '/assets/images/avatar.svg';
  }

  delete() {
    this.deleteClicked.emit(this.unit);
  }

  edit() {
    this.editClicked.emit(this.unit);
  }

  editRelationships() {
    this.editRelationshipsClicked.emit(this.unit);
  }

  addMeter() {
    this.addMeterClicked.emit();
  }
}
