import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { Unit } from '@core/models/interfaces/unit.interface';
import { Meter } from '@core/models/interfaces/meter.interface';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { dataURLtoBlob } from '@core/utils/data-to-blob';
import { UserService } from '@app/@core/api/user/user.service';
import { getTranslationForMeterType } from '@core/models/utils/type.translation';
import { UsesCacheComponent } from '@core/api/cache/uses-cache';
import { CacheService } from '@app/@core/api/cache/cache.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-meter-reading-mask',
  templateUrl: './meter-reading-mask.component.html',
  styleUrls: ['./meter-reading-mask.component.scss'],
})
export class MeterReadingMaskComponent extends UsesCacheComponent implements OnInit, OnDestroy {
  public static MODAL_ID = 'meter-reading-mask-component';

  @Input() unit: Unit;
  @Input() meter?: Meter;

  private form?: UntypedFormGroup;

  private timeout: any;
  private filename: string;

  constructor(
    private readonly modalController: ModalController,
    private readonly alertController: AlertController,
    private readonly toastController: ToastController,
    private readonly userService: UserService,
    private readonly formBuilder: UntypedFormBuilder,
    protected readonly cacheService: CacheService,
    private translateService :TranslateService,) {
    super(cacheService);
  }

  get meterReadingForm() {
    return this.form;
  }

  async ngOnInit() {
    const me = await this.userService.getMe();

    // Managers have optional photo upload, proprietors have to upload a picture
    const photoValidators = ['admin', 'manager'].includes(me?.role?.type ?? 'public') ? [] : [Validators.required];

    this.form = this.formBuilder.group({
      reading: ['', Validators.compose([Validators.required, Validators.min(0)])],
      meter: [{
        value: this.meter.identifier,
        disabled: true,
      }],
      'meter-type': [{
        value: getTranslationForMeterType(this.meter.type, this.translateService),
        disabled: true,
      }],
      now: [{
        value: new Date().toLocaleString(),
        disabled: true,
      }],
      photo: [''],
      photoFile: [null, photoValidators],
    });

    this.timeout = setInterval(() => {
      this.form.get('now')?.setValue(new Date().toLocaleString());
    }, 250);
    this.isInitialized = true;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    clearInterval(this.timeout);
  }

  async dismiss() {
    await this.modalController.dismiss(undefined, undefined, MeterReadingMaskComponent.MODAL_ID);
  }

  async submitMeterReading() {
    if (this.form.invalid || this.form.disabled) {
      return;
    }

    this.form.disable();
    const { reading, photoFile } = this.form.value;
    await this.modalController.dismiss({
      reading,
      photoFile,
      filename: this.filename,
    }, 'update', MeterReadingMaskComponent.MODAL_ID);
    this.form.enable();
  }

  async takePhoto() {
    const translations = await firstValueFrom(this.translateService.get([
      'buttons.okay',
      'pages.take-photo-camera-denied-header',
      'pages.take-photo-camera-denied-message'
    ]));
    if (Capacitor.isNativePlatform()) {
      const permissions = await Camera.checkPermissions();
      if (permissions.camera === 'denied') {
        await (await this.toastController.create({
          header: translations['pages.take-photo-camera-denied-header'],
          message: translations['pages.take-photo-camera-denied-message'],
          buttons: [translations['buttons.okay']],
          color: 'danger',
          duration: 5000,
          position: 'top',
        })).present();

        return;
      }
      if (permissions.camera === 'prompt' || permissions.camera === 'prompt-with-rationale') {
        await Camera.requestPermissions({ permissions: ['camera'] });
        return await this.takePhoto();
      }
    }

    const image = await Camera.getPhoto({
      source: CameraSource.Camera,
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
    });
    const dataUrl = image.dataUrl;
    this.filename = 'photo' + new Date().toISOString()
        .replace(/:/g, '-')
        .replace(/\./g, '_')
      + '.' + image.format;

    this.form.patchValue({
      photo: dataUrl,
      photoFile: dataURLtoBlob(dataUrl),
    });
  }

  async chooseFromGallery() {
    const translations = await firstValueFrom(this.translateService.get([
      'buttons.okay',
      'pages.choose-from-gallery-denied-header',
      'pages.choose-from-gallery-denied-message'
    ]));
    if (Capacitor.isNativePlatform()) {
      const permissions = await Camera.checkPermissions();
      if (permissions.photos === 'denied') {
        await (await this.toastController.create({
          header: translations['pages.choose-from-gallery-denied-header'],
          message: translations['pages.choose-from-gallery-denied-message'],
          buttons: [translations['buttons.okay']],
          color: 'danger',
          duration: 5000,
          position: 'top',
        })).present();

        return;
      }
      if (permissions.photos === 'prompt' || permissions.photos === 'prompt-with-rationale') {
        await Camera.requestPermissions({ permissions: ['photos'] });
        return await this.chooseFromGallery();
      }
    }

    const image = await Camera.getPhoto({
      source: CameraSource.Photos,
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.DataUrl,
    });
    const dataUrl = image.dataUrl;
    this.filename = 'photo' + new Date().toISOString()
        .replace(/:/g, '-')
        .replace(/\./g, '_')
      + '.' + image.format;
    this.form.patchValue({
      photo: dataUrl,
      photoFile: dataURLtoBlob(dataUrl),
    });
  }

  onCacheCleared(lastRefresh: Date): Promise<void> | void {
    return this.ngOnInit();
  }
}
