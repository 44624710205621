import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { User } from '@core/models/interfaces/user.interface';
import { getName } from '@core/utils/get-name';
import { getThumbnailUrl } from '@core/models/utils/media.utils';

@Component({
  selector: 'app-chat-group-participant-list',
  templateUrl: './chat-group-participant-list.component.html',
  styleUrls: ['./chat-group-participant-list.component.scss'],
})
export class ChatGroupParticipantListComponent {

  @Input()
  public participants: User[];

  constructor(
    private readonly modalController: ModalController,
  ) {}

  public getProfilePicture(participant) {
    return getThumbnailUrl(participant?.profilePicture) ?? '/assets/images/avatar.svg';
  }

  public getParticipantName(participant) {
    return getName(participant);
  }

  public async dismiss(): Promise<void> {
    await this.modalController.dismiss();
  }

}
