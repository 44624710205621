import { MediaFormat } from './media-format.interface';

export interface Image extends MediaFormat {
  readonly formats: ImageFormats | null;
}

export interface ImageFormats {
  readonly thumbnail?: MediaFormat;
  readonly large?: MediaFormat;
  readonly medium?: MediaFormat;
  readonly small?: MediaFormat;
}

export type ImageResolution = keyof ImageFormats;

export const imageResolutions: ImageResolution[] = [
  'large',
  'medium',
  'small',
  'thumbnail',
];
