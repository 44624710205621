import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class StorageService {

  private store: Storage;

  constructor(
    private readonly storage: Storage,
  ) {}

  /**
   * Convert an item key to a string representation
   *
   * @param key The requested item's key
   */
  private static getItemKey(key: string | string[]): string {
    return Array.isArray(key) ? key.filter((k) => k !== '').join('.') : key;
  }

  /**
   * Get an item
   */
  public async get(
    component: string,
    key: string | string[],
    defaultValue: any = null,
  ): Promise<any> {
    if (!this.store) {
      await this.init();
    }
    return (
      (await this.store.get(
        `${component}.${StorageService.getItemKey(key)}`,
      )) ?? defaultValue
    );
  }

  /**
   * Set an item
   */
  public async set(
    component: string,
    key: string | string[],
    value: any,
  ): Promise<any> {
    if (!this.store) {
      await this.init();
    }
    return this.store.set(
      `${component}.${StorageService.getItemKey(key)}`,
      value,
    );
  }

  /**
   * Remove an item
   */
  public async remove(component: string, key: string | string[]): Promise<any> {
    if (!this.store) {
      await this.init();
    }
    return this.store.remove(`${component}.${StorageService.getItemKey(key)}`);
  }

  /**
   * Remove all items of a certain component and prefix
   */
  public async clear(
    component: string,
    prefix: string | string[] = [],
  ): Promise<void> {
    if (!this.store) {
      await this.init();
    }

    const keyPrefix = `${component}.${StorageService.getItemKey(prefix)}`;

    return this.store.forEach((value, key) => {
      if (key.startsWith(keyPrefix)) {
        this.store.remove(key);
      }
    });
  }

  public async clearAll() {
    if (!this.store) {
      return;
    }

    await this.store.clear();
  }

  /**
   * Initialize the store
   *
   * @private
   */
  public async init(): Promise<void> {
    this.store = await this.storage.create();
  }

}
