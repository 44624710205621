// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item ion-img {
  height: 60px;
  width: 60px;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  object-fit: cover;
  border-radius: 50%;
}
ion-item p {
  margin-left: 2rem;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--ion-color-black);
  font-size: 13px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/chat-group-participant-list/chat-group-participant-list.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,WAAA;EACA,qBAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,iBAAA;EACA,aAAA;EACA,gBAAA;EACA,6BAAA;EACA,eAAA;EACA,iBAAA;AADJ","sourcesContent":["ion-item {\n  ion-img {\n    height: 60px;\n    width: 60px;\n    margin-bottom: .5rem;\n    margin-top: .5rem;\n    object-fit: cover;\n    border-radius: 50%;\n  }\n\n  p {\n    margin-left: 2rem;\n    margin-top: 0;\n    margin-bottom: 0;\n    color: var(--ion-color-black);\n    font-size: 13px;\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
