import { Component, OnInit } from '@angular/core';
import { UserService } from '@core/api/user/user.service';
import { User } from '@core/models/interfaces/user.interface';
import { getThumbnailUrlOrPlaceholder } from '@core/models/utils/media.utils';
import { AuthenticationService } from '@app/auth/authentication.service';
import { TicketService } from '@core/api/ticket/ticket.service';
import { PushNotificationService } from '@core/push-notification/push-notification.service';
import { UsesCacheComponent } from '@core/api/cache/uses-cache';
import { CacheService } from '@app/@core/api/cache/cache.service';
import { LanguageService } from '@core/i18n/language.service';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent extends UsesCacheComponent implements OnInit {
  public appPages = undefined;
  private internalUser?: User;
  private internalRole?: string;

  constructor(
    private readonly userService: UserService,
    private readonly authService: AuthenticationService,
    private readonly ticketService: TicketService,
    private readonly languageService: LanguageService,
    private readonly pushNotificationService: PushNotificationService,
    protected readonly cacheService: CacheService,
    private translateService :TranslateService,
  ) {
    super(cacheService);
  }

  get currentLanguage(){
    return this.languageService.lang;
  }

  get languages() {
    const map = this.languageService.available;
    const codes = [];
    for (const [code, lang] of map.entries()) {
      codes.push({
        value: code,
        label: lang
      })
    }
    return codes;
  }

  get native() {
    return Capacitor.isNativePlatform();
  }

  public get user(): User | undefined {
    return this.internalUser;
  }

  get role() {
    return this.user?.role?.type ?? 'public';
  }

  get advancedRole() {
    return this.internalRole;
  }

  async ngOnInit() {
    await this.languageService.getLanguage();
    await this.loadMenu();
    this.isInitialized = true;
  }

  trackByUrl(_, page: { url: string }) {
    return page.url;
  }

  getUserAvatar() {
    return getThumbnailUrlOrPlaceholder(this.internalUser?.profilePicture);
  }

  async logout() {
    await this.pushNotificationService.deregisterNotifications();
    await this.authService.logout();
  }

  public hasPermissions(url, hasTicket) {
    switch (url) {
      case '/app/properties':
      case '/app/chat-rooms':
      case '/app/documents':
      case '/app/document-templates':
      case '/app/problems':
      case '/app/support':
      case '':
        return ['manager', 'admin', 'tenant'].includes(this.role);

      case '/app/tickets': {
        return hasTicket || ['manager', 'admin', 'contractor'].includes(this.role);
      }

      case '/app/contacts':
      case '/app/contractors':
      case '/app/meter-readings':
      case '/app/finance/revenues':
      case '/app/finance/properties':
      case '/app/finance/operating-costs':
      case '/app/users':
      default:
        return ['manager', 'admin'].includes(this.role);
    }
  }

  onCacheCleared(lastRefresh: Date): Promise<void> | void {
    return this.loadMenu();
  }

  private async loadMenu() {
    this.internalUser = await this.userService.getMe();
    const hasTicket = await this.ticketService.hasTicket();

    if (this.role === 'tenant') {
      const status = await this.userService.getAdvancedRoleStatus('me');
      switch (status) {
        case 'both':
          this.internalRole = this.getTranslated('pages.renter-owner');
          break;
        case 'rentee':
          this.internalRole = this.getTranslated('pages.renter');
          break;
        case 'proprietor':
          this.internalRole = this.getTranslated('pages.owner');
          break;
        default:
          this.internalRole = this.internalUser.role.name;
          break;
      }
    } else {
      this.internalRole = this.internalUser.role.name;
    }

    this.appPages = [
      { title: this.getTranslated('components.menu.dashboard'), url: '', icon: 'calendar-clear' },
      { title: this.getTranslated('components.menu.notices'), url: '', icon: 'chatbox' },
      { title: this.getTranslated('components.menu.object-overview'), url: '/app/properties', icon: 'business' },
      { title: this.getTranslated('components.menu.finance.revenues'), url: '/app/finance/revenues', icon: 'bar-chart' },
      { title: this.getTranslated('components.menu.finance.operating-costs'), url: '/app/finance/operating-costs', icon: 'bar-chart' },
      { title: this.getTranslated('components.menu.counter-readings'), url: '/app/meter-readings', icon: 'speedometer' },
      { title: this.getTranslated('components.menu.glitches-problems'), url: '/app/problems', icon: 'warning' },
      { title: this.getTranslated('components.menu.ticket-system'), url: '/app/tickets', icon: 'ticket' },
      { title: this.getTranslated('components.menu.protocols'), url: '', icon: 'document' },
      { title: this.getTranslated('components.menu.documents'), url: '/app/documents', icon: 'document-text' },
      { title: this.getTranslated('components.menu.templates'), url: '/app/document-templates', icon: 'document' },
      { title: this.getTranslated('components.menu.chat-room'), url: '/app/chat-rooms', icon: 'chatbubbles' },
      { title: this.getTranslated('components.menu.service-manger'), url: '/app/contractors', icon: 'build' },
      { title: this.getTranslated('components.menu.contacts'), url: '/app/contacts', icon: 'id-card' },
      { title: this.getTranslated('components.menu.user'), url: '/app/users', icon: 'people' },
      { title: this.getTranslated('components.menu.support'), url: '/app/support', icon: 'chatbubble-ellipses' }

      // { title: this.getTranslated('components.menu.finance.properties'), url: '/app/finance/properties', icon: 'bar-chart' },
    ].filter((a) => this.hasPermissions(a.url, hasTicket));
  }

  setLanguage($event: any) {
    this.languageService.lang = $event.detail.value;
  }

  getTranslated(key: string){
    return this.translateService.instant(key);
  }
}
