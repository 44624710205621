import { Mapper } from '@core/models/map-struct/mapper.interface';
import { Injectable, Injector } from '@angular/core';
import { UserMapper } from '@core/models/map-struct/user.mapper';
import { CompanyMapper } from '@core/models/map-struct/company.mapper';
import { MediaMapper } from './media.mapper';
import { Document } from '../interfaces/document.interface';
import { PropertyMapper } from '@core/models/map-struct/property.mapper';
import { UnitMapper } from '@core/models/map-struct/unit.mapper';
import { DocumentFolderMapper } from '@core/models/map-struct/document-folder.mapper';

@Injectable({
  providedIn: 'root',
})
export class DocumentMapper implements Mapper<Document> {

  private userMapper: UserMapper;
  private companyMapper: CompanyMapper;
  private mediaMapper: MediaMapper;
  private documentFolderMapper: DocumentFolderMapper;
  private propertyMapper: PropertyMapper;
  private unitMapper: UnitMapper;

  constructor(private injector: Injector) {

  }

  toEntity(apiData: any): Document | undefined {
    if (!('attributes' in apiData)) {
      return undefined;
    }

    const mappedObject = {
      id: apiData.id,
      title: apiData.attributes.title,
      description: apiData.attributes.description ?? null,
      date: apiData.attributes.date,
    } as Document;

    if (!this.userMapper) {
      this.userMapper = this.injector.get(UserMapper);
    }

    if ('file' in apiData.attributes && apiData.attributes.file != null) {
      if (apiData.attributes.file?.data != null) {
        if (!this.mediaMapper) {
          this.mediaMapper = this.injector.get(MediaMapper);
        }
        mappedObject.file = this.mediaMapper.toEntity(apiData.attributes.file.data);
      } else if (!('data' in apiData.attributes.file)) {
        mappedObject.file = apiData.attributes.file;
      }
    }

    if ('company' in apiData.attributes && apiData.attributes.company != null) {
      if (apiData.attributes.company?.data != null) {
        if (!this.companyMapper) {
          this.companyMapper = this.injector.get(CompanyMapper);
        }
        mappedObject.company = this.companyMapper.toEntity(apiData.attributes.company.data);
      } else if (!('data' in apiData.attributes.company)) {
        mappedObject.company = apiData.attributes.company;
      }
    }

    if ('owner' in apiData.attributes && apiData.attributes.owner != null) {
      if (apiData.attributes.owner?.data != null) {
        mappedObject.owner = this.userMapper.toEntity(apiData.attributes.owner.data);
      } else if (!('data' in apiData.attributes.owner)) {
        mappedObject.owner = apiData.attributes.owner;
      }
    }

    if ('participants' in apiData.attributes && apiData.attributes.participants != null) {
      if (apiData.attributes.participants?.data != null && Array.isArray(apiData.attributes.participants.data)) {
        mappedObject.participants = apiData.attributes.participants.data.map(user => this.userMapper.toEntity(user)).filter(Boolean);
      } else if (!('data' in apiData.attributes.participants)) {
        mappedObject.participants = apiData.attributes.participants;
      }
    }

    if ('property' in apiData.attributes && apiData.attributes.property != null) {
      if (apiData.attributes.property?.data != null) {
        if (!this.propertyMapper) {
          this.propertyMapper = this.injector.get(PropertyMapper);
        }
        mappedObject.property = this.propertyMapper.toEntity(apiData.attributes.property.data);
      } else if (!('data' in apiData.attributes.property)) {
        mappedObject.property = apiData.attributes.property;
      }
    }

    if ('unit' in apiData.attributes && apiData.attributes.unit != null) {
      if (apiData.attributes.unit?.data != null) {
        if (!this.unitMapper) {
          this.unitMapper = this.injector.get(UnitMapper);
        }
        mappedObject.unit = this.unitMapper.toEntity(apiData.attributes.unit.data);
      } else if (!('data' in apiData.attributes.unit)) {
        mappedObject.unit = apiData.attributes.unit;
      }
    }

    if ('documentFolder' in apiData.attributes && apiData.attributes.documentFolder != null) {
      if (apiData.attributes.documentFolder?.data != null) {
        if (!this.documentFolderMapper) {
          this.documentFolderMapper = this.injector.get(DocumentFolderMapper);
          this.documentFolderMapper.setDocumentMapper(this);
        }
        mappedObject.documentFolder = this.documentFolderMapper.toEntity(apiData.attributes.documentFolder.data);
      } else if (!('data' in apiData.attributes.documentFolder)) {
        mappedObject.documentFolder = apiData.attributes.documentFolder;
      }
    }

    return mappedObject;
  }

  setDocumentFolderMapper(value: DocumentFolderMapper) {
    this.documentFolderMapper = value;
  }
}
