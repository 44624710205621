import { Component, inject, Input } from '@angular/core';
import { User } from '@core/models/interfaces/user.interface';
import { getImageUrl, getThumbnailUrlOrPlaceholder } from '@core/models/utils/media.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent {
  @Input() user: User;
  @Input() currentUser: User;
  @Input() link?: any;

  @Input() advancedRole: string = undefined;
  private translateService = inject(TranslateService);

  get role() {
    if (this.user?.role?.type) {
      if (this.user?.role?.type === 'tenant') {
        return this.advancedRole ?? '-';
      } else {
        return this.user.role.name;
      }
    } else {
      return '-';
    }
  }

  get acceptedInvite() {
    return this.user?.confirmed;
  }

  getUserAvatar(user: User) {
    return getImageUrl(user.profilePicture) ?? getThumbnailUrlOrPlaceholder(user.profilePicture);
  }

  stopPropagation($event: MouseEvent) {
    $event.stopPropagation();
  }

  getTranslated(key: string){
    return this.translateService.get(key);
  }
}
