import { Injectable } from '@angular/core';
import { Contact } from '@app/@core/models/interfaces/contact.interface';
import { ApiService } from '@core/api/api.service';
import { ContactMapper } from '@core/models/map-struct/contact.mapper';
import { UserService } from '../user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Pagination } from '@core/models/datatypes/pagination.interface';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private readonly entityName = 'api/contacts';

  constructor(
    private readonly apiService: ApiService,
    private readonly userService: UserService,
    private readonly contactMapper: ContactMapper,
    private translateService: TranslateService,
  ) { }

  public async getAll(searchTerm: string = undefined, populateProperties = false, page = 1, pageSize =100): Promise<{ contacts: Contact[]; pagination: Pagination }> {
    let filters = {};
    if (searchTerm) {
      const csi = {
        $containsi: searchTerm,
      };
      filters = {
        $or: [
          { companyName: csi },
          { contactName: csi },
          { position: csi },
          { phoneNumber: csi },
          { email: csi },
          { websiteUrl: csi },
          {
            address: {
              $or: [
                { street: csi },
                { streetNumber: csi },
                { zip: csi },
                { city: csi },
                { country: csi },
              ],
            },
          }
        ],
      };
    }
    return this.apiService.getWithCaching(this.entityName, {
      filters,
      ...(populateProperties ? {
        populate: [
          'address',
          'properties',
          'properties.address',
        ],
      } : {
        populate: [
          'address'
        ]
      }),
      pagination: {
        pageSize,
        page,
      },
    }).then((res) => ({ contacts: res.data.map(contact => this.contactMapper.toEntity(contact)), pagination: res.meta.pagination }));
  }

  public async deleteById(id: number): Promise<void> {
    return this.apiService.delete(`${this.entityName}/${id}`);
  }

  async create(data: ContactDto) {
    const user = await this.userService.getMe();
    if (!user?.employeeIn?.id) {
      throw new Error(this.translateService.instant('errors.message.services.contact-service-create-error'));
    }
    return this.apiService.post(this.entityName, {
      data: {
        ...data,
        ownerCompany: user.employeeIn.id,
      },
    });
  }

  async updateById(id: number, data: ContactDto) {
    return this.apiService.put(`${this.entityName}/${id}`, {
      data,
    });
  }

  async getContactsByPropertyId(propertyId: number): Promise<Contact[]> {
    return this.apiService.getWithCaching(this.entityName, {
      filters: {
        properties: {
          id: { $eq: propertyId },
        },
      },
      populate: ['address'],
      pagination: {
        pageSize: 100,
      },
    }).then((res) => res.data.map(contact => this.contactMapper.toEntity(contact)));
  }
}

interface ContactDto {
  position: string;
  companyName: string;
  contactName: string;
  phoneNumber: string;

  // Optionals
  email: string | null;
  websiteUrl: string | null;

  address: {
    street: string;
    streetNumber: string;
    city: string;
    zip: string;
    country: string;
  } | null;
}
