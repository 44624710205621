import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ProblemService } from '@core/api/problem-service/problem.service';
import { Property } from '@core/models/interfaces/property.interface';
import { Problem } from '@core/models/interfaces/problem.interface';
import { Unit } from '@app/@core/models/interfaces/unit.interface';
import { AnimationController } from '@ionic/angular';
import { Logger } from '@core/logger/logger.service';
import { Pagination } from '@app/@core/models/datatypes/pagination.interface';
import { getStatusColor, getStatusLabel } from '@core/utils/ticket-status.helper';
import { UsesCacheComponent } from '@core/api/cache/uses-cache';
import { CacheService } from '@app/@core/api/cache/cache.service';
import { TranslateService } from '@ngx-translate/core';

const logger = new Logger('a.@s.c.p.ProblemCardComponent');

@Component({
  selector: 'app-problem-card',
  templateUrl: './problem-card.component.html',
  styleUrls: ['./problem-card.component.scss'],
})
export class ProblemCardComponent extends UsesCacheComponent implements OnInit {
  @Input() property: Property;
  @Input() unit: Unit;
  @ViewChild('editorContainer') editorContainer: ElementRef<HTMLDivElement>;
  @ViewChild('listContainer') listContainer: ElementRef<HTMLDivElement>;
  private internalProblems?: Problem[];
  private internalPagination?: Pagination;
  private currentPage = 1;
  private pageSize = 10;
  private editorIsActive = false;
  private listIsActive = true;
  private isLoading = false;

  constructor(
    private readonly problemService: ProblemService,
    private readonly animationController: AnimationController,
    protected readonly cacheService: CacheService,
    private translateService :TranslateService,
  ) {
    super(cacheService);
  }

  get pagination() {
    return this.internalPagination;
  }

  get showList() {
    return this.listIsActive;
  }

  get showEditor() {
    return this.editorIsActive;
  }

  get problems() {
    return this.internalProblems;
  }

  get loading() {
    return this.isLoading;
  }

  onCacheCleared(lastRefresh: Date): void | Promise<void> {
    return this.loadProblems();
  }

  async ngOnInit() {
    await this.loadProblems();
    this.isInitialized = true;
  }

  public trackById(_, entity: { id: number }) {
    return entity?.id ?? _;
  }

  statusColor(problem: Problem) {
    return getStatusColor(problem);
  }

  status(problem: Problem) {
    return getStatusLabel(this.translateService, problem);
  }

  async openEditor() {
    const animation = this.animationController.create()
      .addElement(this.listContainer.nativeElement)
      .duration(300)
      .fromTo('opacity', 1, 0)
      .easing('ease-in-out');
    animation.play().then();
    this.editorIsActive = true;

    const animation2 = this.animationController.create()
      .addElement(this.editorContainer.nativeElement)
      .duration(300)
      .delay(100)
      .fromTo('opacity', 0, 1)
      .beforeAddClass('position-absolute')
      .afterRemoveClass('position-absolute')
      .easing('ease-in-out');
    await animation2.play();
    this.listIsActive = false;
  }

  async closeEditor() {
    const animation = this.animationController.create()
      .addElement(this.editorContainer.nativeElement)
      .duration(300)
      .fromTo('opacity', 1, 0)
      .easing('ease-in-out');
    animation.play().then();
    this.listIsActive = true;

    const animation2 = this.animationController.create()
      .addElement(this.listContainer.nativeElement)
      .duration(300)
      .delay(100)
      .fromTo('opacity', 0, 1)
      .beforeAddClass('position-absolute')
      .afterRemoveClass('position-absolute')
      .easing('ease-in-out');
    await animation2.play();
    this.editorIsActive = false;
    await this.clearCacheAndWait();
    await this.loadProblems();
  }

  async goToNextPage() {
    if (!this.pagination) {
      return;
    }

    if (this.pagination.page === this.pagination.pageCount) {
      return;
    }
    this.currentPage++;
    await this.loadProblems();
  }

  async goToPreviousPage() {
    if (!this.pagination) {
      return;
    }
    if (this.pagination.page === 1) {
      return;
    }
    this.currentPage--;
    await this.loadProblems();
  }

  async changeAmountOfItems($event: number) {
    if (!this.pagination) {
      return;
    }
    if ($event === this.pagination.pageSize) {
      return;
    }

    this.currentPage = 1;
    this.pageSize = $event;
    await this.loadProblems();
  }

  async goToLastPage() {
    if (!this.pagination) {
      return;
    }
    if (this.pagination.page === this.pagination.pageCount) {
      return;
    }

    this.currentPage = this.pagination.pageCount;
    await this.loadProblems();
  }

  async goToFirstPage() {
    if (!this.pagination) {
      return;
    }
    if (this.pagination.page === 1) {
      return;
    }
    this.currentPage = 1;
    await this.loadProblems();
  }

  private async loadProblems() {
    this.isLoading = true;
    try {
      if (this.unit) {
        const { data, meta } = await this.problemService.getProblemsWithStatusByUnitId(
          this.unit.id,
          this.currentPage,
          this.pageSize,
        );
        this.internalProblems = data;
        this.internalPagination = meta;
      } else if(this.property) {
        const { data, meta } = await this.problemService.getProblemsWithStatusByPropertyId(
          this.property.id,
          this.currentPage,
          this.pageSize,
        );
        this.internalProblems = data;
        this.internalPagination = meta;
      }
    } catch (e) {
      logger.error(e);
    }
    this.isLoading = false;
  }
}
