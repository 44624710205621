import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent {
  public static MODAL_ID = 'image-preview-component';

  @Input() imageUrl: string;
  @Input() thumbnailUrl?: string;

  constructor(
    private readonly modalController: ModalController,
    private translateService :TranslateService,
  ) {}

  async dismiss() {
    await this.modalController.dismiss(undefined, undefined, ImagePreviewComponent.MODAL_ID);
  }

  openInNewWindow() {
    window.open(this.imageUrl, '_blank', this.translateService.instant('components.image-preview.image-preview'));
  }
}
