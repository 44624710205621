import { Mapper } from '@core/models/map-struct/mapper.interface';
import { Problem } from '@core/models/interfaces/problem.interface';
import { UnitMapper } from '@core/models/map-struct/unit.mapper';
import { Injectable, Injector } from '@angular/core';
import { UserMapper } from './user.mapper';
import { MediaMapper } from './media.mapper';
import { PropertyMapper } from './property.mapper';

@Injectable({
  providedIn: 'root'
})
export class ProblemMapper implements Mapper<Problem> {

  private unitMapper: UnitMapper;
  private userMapper: UserMapper;
  private mediaMapper: MediaMapper;
  private propertyMapper: PropertyMapper;

  constructor(private injector: Injector) {

  }

  toEntity(apiData: any): Problem | undefined {
    if (!('attributes' in apiData)) {
      return undefined;
    }
    const mappedObject = {
      id: apiData.id,
      body: apiData.attributes.body,
      createdAt: apiData.attributes.createdAt
    } as Problem;

    if ('attachments' in apiData.attributes && apiData.attributes.attachments != null) {
      if (apiData.attributes.attachments?.data != null) {
        if (!this.mediaMapper) {
          this.mediaMapper = this.injector.get(MediaMapper);
        }
        mappedObject.attachments = apiData.attributes.attachments.data.map(media => this.mediaMapper.toEntity(media));
      } else if (!('data' in apiData.attributes.attachments)) {
        mappedObject.attachments = apiData.attributes.attachments;
      }
    }

    if ('unit' in apiData.attributes && apiData.attributes.unit != null) {
      if (apiData.attributes.unit?.data != null) {
        if (!this.unitMapper) {
          this.unitMapper = this.injector.get(UnitMapper);
        }
        mappedObject.unit = this.unitMapper.toEntity(apiData.attributes.unit.data);
      } else if (!('data' in apiData.attributes.unit)) {
        mappedObject.unit = apiData.attributes.unit;
      }
    }

    if ('reporter' in apiData.attributes && apiData.attributes.reporter != null) {
      if (apiData.attributes.reporter?.data != null) {
        if (!this.userMapper) {
          this.userMapper = this.injector.get(UserMapper);
        }
        mappedObject.reporter = this.userMapper.toEntity(apiData.attributes.reporter.data);
      } else if (!('data' in apiData.attributes.reporter)) {
        mappedObject.reporter = apiData.attributes.reporter;
      }
    }

    if ('property' in apiData.attributes && apiData.attributes.property != null) {
      if (apiData.attributes.property?.data != null) {
        if (!this.propertyMapper) {
          this.propertyMapper = this.injector.get(PropertyMapper);
        }
        mappedObject.property = this.propertyMapper.toEntity(apiData.attributes.property.data);
      } else if (!('data' in apiData.attributes.property)) {
        mappedObject.property = apiData.attributes.property;
      }
    }

    return mappedObject;
  }
}
