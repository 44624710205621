import { User } from '@core/models/interfaces/user.interface';
import { Property } from '@core/models/interfaces/property.interface';
import { MediaFormat } from '@core/models/datatypes/media-format.interface';
import { Unit } from '@core/models/interfaces/unit.interface';

export interface Problem {
  id: number;
  body: string;

  status?: StatusType;
  attachments?: MediaFormat[];
  reporter?: User;
  unit?: Unit;
  property?: Property;
  tickets?: unknown[];

  createdAt?: string;
}

export type ProblemCountsByUnit = { [key: string]: number };

export interface ProblemCounts {
  name: string;
  total: number;
  openOrInProgress: {
    count: number;
    entireProperty: number;
    byUnit: ProblemCountsByUnit;
  };
}

export type StatusType = 'open' | 'in_progress' | 'closed';
export const Status: StatusType[] = ['open', 'in_progress', 'closed'];
