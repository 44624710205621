import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Unit } from '@core/models/interfaces/unit.interface';

@Component({
  selector: 'app-unit-mask',
  templateUrl: './unit-mask.component.html',
  styleUrls: ['./unit-mask.component.scss'],
})
export class UnitMaskComponent implements OnInit {
  public static MODAL_ID = 'unit-mask-component';
  @Input() unit?: Unit;

  private unitForm: UntypedFormGroup;

  constructor(
    private readonly modalController: ModalController,
    private readonly formBuilder: UntypedFormBuilder) {
  }

  public get form(): UntypedFormGroup {
    return this.unitForm;
  }

  public ngOnInit(): void {
    this.unitForm = this.createForm();
  }

  public async dismiss(): Promise<void> {
    await this.modalController.dismiss({
      dismissed: true,
    }, undefined, UnitMaskComponent.MODAL_ID);
  }

  public async submit(event: any): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    if (this.form.invalid || this.form.disabled) {
      return;
    }

    this.form.disable();

    const {
      label, description, floor, area,
    } = this.form.value;

    const unit = {
      ...this.unit,
      label,
      description,
      floor,
      area
    } as Unit;
    await this.modalController.dismiss({ unit }, undefined, UnitMaskComponent.MODAL_ID);
    this.form?.enable();
  }

  private createForm(): UntypedFormGroup {
    return this.formBuilder.group({
      label: [this.unit?.label ?? '', Validators.compose([Validators.required, Validators.minLength(3)])],
      description: [this.unit?.description ?? '', Validators.compose([Validators.minLength(3)])],
      floor: [this.unit?.floor ?? 0, Validators.compose([Validators.required])],
      area: [this.unit?.area ?? 0, Validators.compose([Validators.required, Validators.min(0)])],
    });
  }

}
