// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: block;
  overflow: auto;
  width: 95vw;
  max-width: 100%;
}

table {
  min-width: 100%;
  overflow-x: scroll;
}

td, th {
  white-space: nowrap;
  word-wrap: break-word;
  padding: 1em;
}

th {
  font-weight: bold;
}

.mat-header-cell, .mat-cell {
  padding-left: 4px;
  padding-right: 4px;
}

.mat-row:hover {
  background-color: #E8EAF6;
  cursor: pointer;
}
.mat-row:active {
  background-color: var(--ion-color-primary-shade);
  color: var(--ion-color-primary-contrast);
  cursor: pointer;
}

ion-avatar {
  width: 24px;
  height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/problem-chooser-mask/problem-chooser-mask.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,cAAA;EACA,WAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,mBAAA;EACA,qBAAA;EACA,YAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;AACF;;AAGE;EACE,yBAAA;EACA,eAAA;AAAJ;AAGE;EACE,gDAAA;EACA,wCAAA;EACA,eAAA;AADJ;;AAKA;EACE,WAAA;EACA,YAAA;AAFF","sourcesContent":[".container {\n  display: block;\n  overflow: auto;\n  width: 95vw;\n  max-width: 100%;\n}\n\ntable {\n  min-width: 100%;\n  overflow-x: scroll;\n}\n\ntd, th {\n  white-space: nowrap;\n  word-wrap: break-word;\n  padding: 1em;\n}\n\nth {\n  font-weight: bold;\n}\n\n.mat-header-cell, .mat-cell {\n  padding-left: 4px;\n  padding-right: 4px;\n}\n\n.mat-row {\n  &:hover {\n    background-color: #E8EAF6;\n    cursor: pointer;\n  }\n\n  &:active {\n    background-color: var(--ion-color-primary-shade);\n    color: var(--ion-color-primary-contrast);\n    cursor: pointer;\n  }\n}\n\nion-avatar {\n  width: 24px;\n  height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
