import { Injectable } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { InvitationMapper } from '@core/models/map-struct/invitation.mapper';
import { Invitation } from '@core/models/interfaces/invitation.interface';
import { InviteResponse } from '@core/models/interfaces/invite-response.interface';

@Injectable({
  providedIn: 'root',
})
export class InvitationService {
  private readonly entityName = 'api/invitations';

  constructor(
    private readonly apiService: ApiService,
    private readonly invitationMapper: InvitationMapper,
  ) {}

  public async getInvitationByToken(token: string): Promise<Invitation> {
    return this.apiService.get(`${this.entityName}/${token}`)
      .then((res) => this.invitationMapper.toEntity(res.data ?? res))
      .then(invitation => ({ ...invitation, inviteToken: token }));
  }

  public async accept(token: string) {
    return this.apiService.post(`${this.entityName}/${token}/accept`, {});
  }

  public async register(token: string, data) {
    return this.apiService.post(`${this.entityName}/${token}/actions/register`, data);
  }

  public async inviteManager(firstname: string, lastname: string, email: string): Promise<InviteResponse> {
    return this.apiService.post(`${this.entityName}/managers`, {
      firstname,
      lastname,
      email,
    });
  }

  public async inviteContractor(
    firstname: string,
    lastname: string,
    email: string,
    companyName: string,
    service: string,
  ): Promise<InviteResponse> {
    return this.apiService.post(`${this.entityName}/contractors`, {
      firstname,
      lastname,
      email,
      companyName,
      service,
    });
  }
}
