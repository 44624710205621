// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  width: 100%;
  height: 200px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.header ion-button, .header .ion-button {
  position: absolute;
  top: 0;
  right: 0;
}

.clickable {
  cursor: pointer;
}

.detail-grid {
  display: flex;
  flex-wrap: wrap;
}
.detail-grid .grid-item {
  padding-right: 0.5em;
  justify-self: flex-start;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.detail-grid .alerts, .detail-grid .additional-units {
  justify-self: center;
}
.detail-grid .units, .detail-grid .alerts {
  padding-bottom: 0;
}
.detail-grid .avatars, .detail-grid .additional-units {
  padding-top: 0.5em;
}
.detail-grid .units, .detail-grid .avatars {
  padding-left: 0;
}
.detail-grid .units ion-icon {
  width: 24.5px;
}
.detail-grid .avatars ion-thumbnail {
  --size: 32px;
  --border-radius: 4em;
}
.detail-grid .avatars ion-thumbnail ion-img::part(image) {
  background: linear-gradient(var(--ion-color-primary), var(--ion-color-secondary), var(--ion-color-dark));
}

.clickable {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/property-card/property-card.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,kCAAA;EACA,4BAAA;EACA,sBAAA;AACF;AAEE;EACE,kBAAA;EACA,MAAA;EACA,QAAA;AAAJ;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,aAAA;EACA,eAAA;AADF;AAGE;EACE,oBAAA;EACA,wBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;AADJ;AAIE;EACE,oBAAA;AAFJ;AAKE;EACE,iBAAA;AAHJ;AAME;EACE,kBAAA;AAJJ;AAOE;EACE,eAAA;AALJ;AASI;EACE,aAAA;AAPN;AAYI;EACE,YAAA;EACA,oBAAA;AAVN;AAYM;EACE,wGAAA;AAVR;;AAgBA;EACE,eAAA;AAbF","sourcesContent":[".header {\n  width: 100%;\n  height: 200px;\n  background-position: center center;\n  background-repeat: no-repeat;\n  background-size: cover;\n\n\n  ion-button, .ion-button {\n    position: absolute;\n    top: 0;\n    right: 0;\n  }\n}\n\n.clickable {\n  cursor: pointer;\n}\n\n.detail-grid {\n  display: flex;\n  flex-wrap: wrap;\n\n  .grid-item {\n    padding-right: 0.5em;\n    justify-self: flex-start;\n    display: flex;\n    align-items: center;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n  }\n\n  .alerts, .additional-units {\n    justify-self: center;\n  }\n\n  .units, .alerts {\n    padding-bottom: 0;\n  }\n\n  .avatars, .additional-units {\n    padding-top: 0.5em;\n  }\n\n  .units, .avatars {\n    padding-left: 0;\n  }\n\n  .units {\n    ion-icon {\n      width: 24.5px;\n    }\n  }\n\n  .avatars {\n    ion-thumbnail {\n      --size: 32px;\n      --border-radius: 4em;\n\n      ion-img::part(image) {\n        background: linear-gradient(var(--ion-color-primary), var(--ion-color-secondary), var(--ion-color-dark));\n      }\n    }\n  }\n}\n\n.clickable {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
