import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Property } from '@core/models/interfaces/property.interface';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { PropertyMaskComponent } from '@shared/components/property-mask/property-mask.component';
import { RoleTypes } from '@core/models/datatypes/role.interface';

@Component({
  selector: 'app-property-grid',
  templateUrl: './property-grid.component.html',
  styleUrls: ['./property-grid.component.scss'],
})
export class PropertyGridComponent {

  @Input() properties: Property[];
  @Input() role: RoleTypes;
  @Output() propertyChanged = new EventEmitter<{ property: Property; photo?: any }>();
  @Output() propertyDeleted = new EventEmitter<Property>();
  @Output() propertyManagersEdit = new EventEmitter<Property>();
  @Output() propertyCreateProblem = new EventEmitter<Property>();

  constructor(private readonly modalController: ModalController,
              private readonly routerOutlet: IonRouterOutlet) { }

  trackById(index: number, property: Property): number {
    return property.id ?? index;
  }

  async editProperty(property: Property) {
    const modal = await this.modalController.create({
      component: PropertyMaskComponent,
      id: PropertyMaskComponent.MODAL_ID,
      canDismiss: true,
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: {
        property,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data?.dismissed === true || !data?.property) {
      return;
    }

    this.propertyChanged.emit(data);
  }

  deleteProperty(property: Property) {
    this.propertyDeleted.emit(property);
  }

  public hasPermissions() {
    return ['manager', 'admin'].includes(this.role);
  }

  createProblem(property: Property) {
    this.propertyCreateProblem.emit(property);
  }
}
