import { Injectable } from '@angular/core';
import { Pagination } from '@core/models/datatypes/pagination.interface';
import { ApiService } from '@core/api/api.service';
import { UserService } from '@core/api/user/user.service';
import { TicketMapper } from '@core/models/map-struct/ticket.mapper';
import { Problem, Status, StatusType } from '@app/@core/models/interfaces/problem.interface';
import { User } from '@core/models/interfaces/user.interface';
import { ChecklistItem, Ticket } from '@core/models/interfaces/ticket.interface';
import { Comment } from '@core/models/datatypes/comment.interface';
import { CommentMapper } from '@core/models/map-struct/comment.mapper';
import { MediaService } from '@core/api/media/media.service';
import { MediaMapper } from '@core/models/map-struct/media.mapper';
import { ImageMapper } from '@core/models/map-struct/image.mapper';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root',
})
export class TicketService {
  public static readonly entityName = 'api/tickets';
  public static readonly entityRef = 'api::ticket.ticket';
  public static readonly attachmentsField = 'attachments';

  constructor(
    private readonly apiService: ApiService,
    private readonly mediaService: MediaService,
    private readonly userService: UserService,
    private readonly ticketMapper: TicketMapper,
    private readonly commentMapper: CommentMapper,
    private readonly mediaMapper: MediaMapper,
    private readonly imageMapper: ImageMapper,
    private translateService :TranslateService,
  ) {}

  async hasTicket() {
    try {
      const response = await this.apiService.getWithCaching(TicketService.entityName, {
        pagination: {
          page: 1,
          pageSize: 1
        }
      });
      return response.data.length > 0;
    } catch (e) {
      return false;
    }
  }

  async getTickets(
    status: StatusType[] = Status,
    sortByUpdatedAt = 'asc',
    propertyId?: number,
    page = 1,
    pageSize = 25,
  ) {
    let filters: any = {
      status: {
        $in: status,
      },
    };

    if (propertyId) {
      filters = {
        ...filters,
        problem: {
          property: propertyId,
        },
      };
    }

    return this.apiService.getWithCaching(TicketService.entityName, {
      filters,
      populate: [
        'creator',
        'creator.profilePicture',
        'problem',
        'problem.property',
        'problem.unit',
      ],
      pagination: {
        page,
        pageSize,
      },
      sort: [`updatedAt:${sortByUpdatedAt}`, 'id:asc'],
    }).then((res => ({
        data: res.data.map((ticket) => this.ticketMapper.toEntity(ticket)),
        meta: ('pagination' in res.meta ? res.meta.pagination : res.meta) as Pagination,
      })
    ));
  }

  async getTicketById(id: number): Promise<Ticket> {
    return this.apiService.getWithCaching(`${TicketService.entityName}/${id}`, {
      populate: [
        'creator',
        'creator.profilePicture',
        'creator.employeeIn',
        'participants',
        'participants.profilePicture',
        'problem',
        'problem.reporter',
        'problem.reporter.profilePicture',
        'problem.reporter.address',
        'problem.property',
        'problem.property.address',
        'problem.unit',
        'problem.attachments',
        'assignedContractor',
        'assignedContractor.isUser',
        'assignedContractor.isUser.profilePicture',
        'assignedContractor.isUser.address',
      ],
    }).then((res => this.ticketMapper.toEntity(res.data)
    ));
  }

  async getCommentsByTicketId(ticketId: number): Promise<Comment[]> {
    const populate = [
      'comments',
      'comments.user',
      'comments.user.role',
      'comments.user.profilePicture',
    ];

    return this.apiService.getWithCaching(`${TicketService.entityName}/${ticketId}`, {
      populate,
      fields: ['id'],
    })
      .then((res) => res.data.attributes.comments)
      .then((comments) => comments.map((comment) => this.commentMapper.toEntity(comment)));
  }

  async getCommentIdsByTicketId(ticketId: string | number): Promise<{ id: number }[]> {
    return this.apiService.get(`${TicketService.entityName}/${ticketId}`, {
      populate: [
        'comments',
      ],
      fields: ['id'],
    }).then((res) => res.data.attributes.comments.map((comment) => ({ id: comment.id })));
  }

  async getAttachmentsByTicketId(ticketId: string | number) {
    return this.apiService.getWithCaching(`${TicketService.entityName}/${ticketId}`, {
      populate: [
        TicketService.attachmentsField
      ],
      fields: ['id'],
    }).then((res) => res.data?.attributes?.attachments?.data?.map((attachment) => 'formats' in attachment.attributes
      ? this.imageMapper.toEntity(attachment)
      : this.mediaMapper.toEntity(attachment)));
  }

  async getTicketCountsWithPropertyNames(): Promise<TicketCount[]> {
    return this.apiService.getWithCaching(`${TicketService.entityName}/count/by-property`, undefined);
  }

  async getTicketCountByPropertyId(id: number): Promise<TicketCount> {
    return this.apiService.getWithCaching(`${TicketService.entityName}/count/by-property/${id}`);
  }

  public async uploadFiles(
    id: number,
    file: string | string[] | File | Blob | FileList,
    existingFiles?: number[],
  ) {
   return this.mediaService.uploadFiles(id, TicketService.entityRef, TicketService.attachmentsField, file, existingFiles);
  }

  async createTicket(title, description, creator: User, problem?: Problem) {
    return this.apiService.post(TicketService.entityName, {
      data: {
        problem: problem?.id,
        title,
        body: description,
        status: 'open',
        creator: creator.id,
      },
    });
  }

  async updateTicketTitle(ticket: Ticket, ticketTitle: string) {
    return this.updateTicket(ticket.id, { title: ticketTitle });
  }

  async updateTicketBody(ticket: Ticket, ticketBody: string) {
    return this.updateTicket(ticket.id, { body: ticketBody });
  }

  async updateTicket(ticketId: number, data: Partial<Omit<Ticket, 'id'>>) {
    return this.apiService.put(`${TicketService.entityName}/${ticketId}`, {
      data,
    });
  }

  async updateTicketStatus(ticket: Ticket, status: StatusType) {
    return this.updateTicket(ticket.id, { status });

  }

  async getTicketsByProblemId(problemId: string | number, page = 1, pageSize = 100) {
    return this.apiService.getWithCaching(TicketService.entityName, {
      filters: {
        problem: problemId,
      },
      pagination: {
        page,
        pageSize,
      },
      sort: ['id:asc'],
    }).then((res => ({
        data: res.data.map((ticket) => this.ticketMapper.toEntity(ticket)),
        meta: ('pagination' in res.meta ? res.meta.pagination : res.meta) as Pagination,
      })
    ));
  }

  async addComment(id: number, comment: string) {
    const user = await this.userService.getMe();
    const now = new Date().toISOString();
    const newComment = {
      message: comment,
      user: user.id,
      date: now,
    };
    const comments: any[] = (await this.getCommentIdsByTicketId(id));
    comments.push(newComment);

    return this.updateTicket(id, {
      comments,
    });
  }

  async getChecklistByTicketId(ticketId: number): Promise<ChecklistItem[]> {
    const populate = [
      'checklist',
    ];

    return this.apiService.getWithCaching(`${TicketService.entityName}/${ticketId}`, {
      populate,
      fields: ['id'],
    })
      .then((res) => res.data.attributes.checklist);
  }

  async getChecklistIdsByTicketId(ticketId: string | number): Promise<{ id: number }[]> {
    return this.apiService.get(`${TicketService.entityName}/${ticketId}`, {
      populate: [
        'checklist',
      ],
      fields: ['id'],
    }).then((res) => res.data.attributes.checklist.map((item) => ({ id: item.id })));
  }

  async updateChecklistItem(ticketId: number, itemToUpdate: ChecklistItem) {
    const checklistItems: any[] = (await this.getChecklistIdsByTicketId(ticketId));

    const indexToUpdate = checklistItems.findIndex((item) => item.id === itemToUpdate.id);
    if (indexToUpdate === -1) {
      throw new Error(this.translateService.instant('errors.message.services.ticket-service-update-check-list-item-error'));
    }

    checklistItems[indexToUpdate] = itemToUpdate;

    return this.updateTicket(ticketId, {
      checklist: checklistItems,
    });
  }

  async addChecklistItem(ticketId: number, item: Omit<ChecklistItem, 'id'>) {
    const checklistItems: any[] = (await this.getChecklistIdsByTicketId(ticketId));
    checklistItems.push(item);

    return this.updateTicket(ticketId, {
      checklist: checklistItems,
    });
  }

  async deleteChecklistItemById(ticketId: number, checklistItemId: number) {
    const checklistItems: any[] = (await this.getChecklistIdsByTicketId(ticketId));

    const indexToRemove = checklistItems.findIndex((item) => item.id === checklistItemId);
    if (indexToRemove === -1) {
      return;
    }

    checklistItems.splice(indexToRemove, 1);

    return this.updateTicket(ticketId, {
      checklist: checklistItems,
    });
  }
}

export interface TicketCount {
  id: number | string;
  name: string;
  count: number;
}

