import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '@app/@core/models/interfaces/user.interface';
import { getThumbnailUrl } from '@app/@core/models/utils/media.utils';
import { Unit } from '@core/models/interfaces/unit.interface';
import { ProblemCountsByUnit } from '@core/models/interfaces/problem.interface';
import { TranslateService } from '@ngx-translate/core';
import { getCurrentRelation, getCurrentRent } from '@core/utils/get-current-relationship';
import { UnitRelationship } from '@core/models/interfaces/unit-relationship.interface';
import { OnlyRent } from '@core/models/datatypes/unit-relationship/only-rent.interface';
import { FixedRateRent } from '@core/models/datatypes/unit-relationship/fixed-rate-rent.interface';
import { CompoundRent } from '@core/models/datatypes/unit-relationship/compound-rent.interface';
import { Property } from '@core/models/interfaces/property.interface';

@Component({
  selector: 'app-units-card',
  templateUrl: './units-card.component.html',
  styleUrls: ['./units-card.component.scss'],
})
export class UnitsCardComponent {

  @Input() property: Property;
  @Input() units: Unit[] = [];
  @Input() user: User;
  @Input() problemCountsByUnit: ProblemCountsByUnit;
  @Input() propertyProblemCount: number;
  @Input() showAddButton = false;
  @Input() viewAllUnits = false;
  @Output() unitClicked = new EventEmitter<Unit>();
  @Output() createUnitClick = new EventEmitter();

  private internalUnitsSorted?: Unit[];

  constructor(
    private translateService :TranslateService,
  ) {
    this.internalUnitsSorted = undefined;
  }

  public get filteredUnits() {
    if (this.viewAllUnits) {
      return this.units;
    } else {
      if (!this.internalUnitsSorted || !this.internalUnitsSorted.length) {
        console.debug(this.units, this.user?.id)
        this.internalUnitsSorted = this.units.filter(u => {
          if (!this.user || !this.user?.id) {
            return false;
          }
          const proprietor = this.getCurrentRelation(u, 'proprietor');

          if (proprietor && proprietor.proprietor?.id === this.user.id) {
            return true;
          }

          let relationship = this.getCurrentRelation(u, 'rentee');
          if (!relationship) {
            // Get the immediate next relationship

            relationship = this.getNextRenteeRelationship(u);
            if (!relationship) {
              return false;
            }
          }
          console.debug(relationship.rentee?.id === this.user.id)
          return relationship.rentee?.id === this.user.id;
        });
      }
      return this.internalUnitsSorted;
    }
  }

  public getProfilePicture(unit: Unit) {
    return getThumbnailUrl(this.getCurrentRelation(unit)?.rentee?.profilePicture) ?? '/assets/images/avatar.svg';
  }

  public trackById(_, entity: { id: number }) {
    return entity.id;
  }

  public onUnitClicked(unit: Unit) {
    this.unitClicked.emit(unit);
  }

  public createUnit() {
    this.createUnitClick.emit();
  }

  public getProblemCount(unitId) {
    return this.problemCountsByUnit?.[String(unitId)] ?? 0;
  }

  public getCurrentRelation(unit: Unit, userToCheck = 'rentee') {
    return getCurrentRelation(unit, userToCheck);
  }

  calculateTotalRent(relationship: UnitRelationship){
    if (relationship.rent.length === 0) {
      return 0;
    }
    else if (relationship.rent.length === 1) {
      return this.getTotalRentSum(relationship.rent[0]);
    }
    else if (relationship.rent.length > 1) {
      const rent = getCurrentRent(relationship.rent);
      return this.getTotalRentSum(rent);
    }
  }

  getTotalRentSum(rent: OnlyRent | FixedRateRent | CompoundRent) : number{
    switch (rent.__component) {
      case 'relationships.only-rent':
        return rent.rent;
      case 'relationships.fixed-rate-rent':
        return rent.rent + rent.fixedRate;
      case 'relationships.compound-rent':
        return rent.rent + rent.operatingCosts + rent.heatingCosts;
    }
  }

  private getNextRenteeRelationship(unit: Unit) {
    const now = new Date();
    const relationshipsInTheFuture = unit.relationships?.filter(
      (a) => a.rentee && a.startDate && new Date(a.startDate) > now && (!a.endDate || a.endDate && new Date(a.endDate) > now),
    );
    return relationshipsInTheFuture?.length > 0 ? relationshipsInTheFuture[0] : undefined;
  }

  getTranslated(key: string, attributeObject: object = {}){
    return this.translateService.get(key, attributeObject);
  }
}
