import { UnitRelationship } from '@core/models/interfaces/unit-relationship.interface';
import { Mapper } from '@core/models/map-struct/mapper.interface';
import { Injectable, Injector } from '@angular/core';
import { UnitMapper } from './unit.mapper';
import { UserMapper } from '@core/models/map-struct/user.mapper';

@Injectable({
  providedIn: 'root',
})
export class UnitRelationshipMapper implements Mapper<UnitRelationship> {

  private unitMapper: UnitMapper;
  private userMapper: UserMapper;

  constructor(private readonly injector: Injector) {}

  toEntity(apiData: any): UnitRelationship | undefined {
    if (!('attributes' in apiData)) {
      return undefined;
    }

    const mappedObject = {
      id: apiData.id,
      startDate: apiData.attributes.startDate,
      endDate: apiData.attributes?.endDate ?? undefined,
      notes: apiData.attributes.notes,
      numberOfPersons: apiData.attributes.numberOfPersons ?? 0,
    } as UnitRelationship;

    if ('unit' in apiData.attributes && apiData.attributes.unit) {
      if (apiData.attributes.unit?.data != null) {
        if (!this.unitMapper) {
          this.unitMapper = this.injector.get(UnitMapper);
          this.unitMapper.setUnitRelationshipMapper(this);
        }
        mappedObject.unit = this.unitMapper.toEntity(apiData.attributes.unit.data);
      } else if (!('data' in apiData.attributes.unit)) {
        mappedObject.unit = apiData.attributes.unit;
      }
    }

    if ('rentee' in apiData.attributes && apiData.attributes.rentee) {
      if (apiData.attributes.rentee?.data != null) {
        if (!this.userMapper) {
          this.userMapper = this.injector.get(UserMapper);
          this.userMapper.setUnitRelationshipMapper(this);
        }
        mappedObject.rentee = this.userMapper.toEntity(apiData.attributes.rentee.data);
      } else if (!('data' in apiData.attributes.rentee)) {
        mappedObject.rentee = apiData.attributes.rentee;
      }
    }

    if ('proprietor' in apiData.attributes && apiData.attributes.proprietor) {
      if (apiData.attributes.proprietor?.data != null) {
        if (!this.userMapper) {
          this.userMapper = this.injector.get(UserMapper);
        }
        mappedObject.proprietor = this.userMapper.toEntity(apiData.attributes.proprietor.data);
      } else if (!('data' in apiData.attributes.proprietor)) {
        mappedObject.proprietor = apiData.attributes.proprietor;
      }
    }

    if ('rent' in apiData.attributes && apiData.attributes.rent) {
      mappedObject.rent = apiData.attributes.rent;
    }

    return mappedObject;
  }

  setUserMapper(mapper: UserMapper) {
    this.userMapper = mapper;
  }

  setUnitMapper(mapper: UnitMapper) {
    this.unitMapper = mapper;
  }
}
