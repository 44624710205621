import { Injectable, Injector } from '@angular/core';
import { Mapper } from '@core/models/map-struct/mapper.interface';
import { Meter } from '@core/models/interfaces/meter.interface';
import { UnitMapper } from '@core/models/map-struct/unit.mapper';
import { MeterReadingMapper } from '@core/models/map-struct/meter-reading.mapper';

@Injectable({
  providedIn: 'root',
})
export class MeterMapper implements Mapper<Meter> {

  private unitMapper: UnitMapper;
  private meterReadingMapper: MeterReadingMapper;

  constructor(private injector: Injector) {

  }

  toEntity(apiData: any): Meter | undefined {
    if (!('attributes' in apiData)) {
      return undefined;
    }
    const mappedObject = {
      id: apiData.id,
      identifier: apiData.attributes.identifier,
      type: apiData.attributes.type,
    } as Meter;

    if ('unit' in apiData.attributes && apiData.attributes.unit != null) {
      if (apiData.attributes.unit?.data != null) {
        if (!this.unitMapper) {
          this.unitMapper = this.injector.get(UnitMapper);
        }
        mappedObject.unit = this.unitMapper.toEntity(apiData.attributes.unit.data);
      } else if (!('data' in apiData.attributes.unit)) {
        mappedObject.unit = apiData.attributes.unit;
      }
    }
    if ('meterReadings' in apiData.attributes && apiData.attributes.meterReadings != null) {
      if (apiData.attributes.meterReadings?.data != null) {
        if (!this.meterReadingMapper) {
          this.meterReadingMapper = this.injector.get(MeterReadingMapper);
          this.meterReadingMapper.setMeterMapper(this);
        }
        mappedObject.meterReadings = apiData.attributes.meterReadings?.data.map((reading) => this.meterReadingMapper.toEntity(reading));
      } else if (!('data' in apiData.attributes.meterReadings)) {
        mappedObject.meterReadings = apiData.attributes.meterReadings;
      }
    }

    return mappedObject;
  }

  setUnitMapper(mapper: UnitMapper) {
    this.unitMapper = mapper;
  }

  setMeterReadingMapper(mapper: MeterReadingMapper) {
    this.meterReadingMapper = mapper;
  }

}
