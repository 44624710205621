import { ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { MediaFormat } from '@core/models/datatypes/media-format.interface';
import { IonicSlides, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { apiSettings } from '@env/environment';
import { Capacitor } from '@capacitor/core';
import { PreviewAnyFile } from '@awesome-cordova-plugins/preview-any-file/ngx';
import { Logger } from '@core/logger/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

const logger = new Logger('a.@s.c.m.MediaCarouselModalComponent');

@Component({
  selector: 'app-media-carousel-modal',
  templateUrl: './media-carousel-modal.component.html',
  styleUrls: ['./media-carousel-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MediaCarouselModalComponent {
  public static MODAL_ID = 'media-carousel-modal-component';

  @Input() media: MediaFormat[];
  @Input() integrate = false;
  @Input() title = '';


  slideOptions = {
    loop: true,
    pagination: {
      clickable: true,
    },
  };

  swiperModules = [IonicSlides];

  public downloading = false;
  public downloaded = 0;

  constructor(
    private readonly modalController: ModalController,
    private readonly previewAnyFile: PreviewAnyFile,
    private readonly loadingController: LoadingController,
    private readonly toastController: ToastController,
    private readonly changeDetector: ChangeDetectorRef,
    private translateService :TranslateService,
  ) {
    if (this.title === ''){
      this.title = this.translateService.instant('pages.attachments');
    }
  }

  get isMobile() {
    return Capacitor.isNativePlatform();
  }

  async dismiss() {
    await this.modalController.dismiss(undefined, undefined, MediaCarouselModalComponent.MODAL_ID);
  }

  getUrl(file: MediaFormat) {
    return apiSettings.baseUrl + file.url;
  }

  trackById(index: number, entity?: { id: any }) {
    return entity?.id ?? 'index-' + index;
  }

  async download(toBeDownloaded: MediaFormat) {
    if (!toBeDownloaded) {
      return;
    }
    const url = this.getUrl(toBeDownloaded);
    const filename = toBeDownloaded.name;

    // if (Capacitor.isNativePlatform()) {
    //   const l = await Http.addListener('progress', (progress) => {
    //     this.downloaded = progress.bytes / progress.contentLength;
    //     this.changeDetector.detectChanges();
    //   });
    //   try {
    //     this.downloading = true;
    //     const options = await getFileDownloadOptions(url, filename);
    //     const result = await Http.downloadFile(options);
    //
    //     const path = result.path.replace('\\', '/');
    //     const slice = path.split('/').slice(-2);
    //     const realPath = (Capacitor.getPlatform() === 'ios' ? 'IRES/' : '') + slice.join('/');
    //
    //     const toast = await this.toastController.create({
    //       message: `Heruntergeladen nach: ${realPath}`,
    //       color: 'success',
    //       duration: 3000,
    //       buttons: ['Okay'],
    //     });
    //     await toast.present();
    //   } catch (e) {
    //     logger.error(e);
    //     const toast = await this.toastController.create({
    //       header: 'Fehler!',
    //       message: 'Es gab einen Fehler: ' + e?.details?.message ?? e,
    //       duration: 5000,
    //       buttons: ['ok'],
    //       color: 'danger',
    //       position: 'top',
    //     });
    //     await toast.present();
    //   } finally {
    //     this.downloading = false;
    //     this.downloaded = 0;
    //     await l.remove();
    //   }
    // } else {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', 'download');
    element.setAttribute('target', '_blank');
    document.body.appendChild(element);
    element.click();
    //}
  }

  async preview(file: MediaFormat) {
    const translations = await firstValueFrom(this.translateService.get(['buttons.okay', 'errors.header.preview-document-error']));
    const loading = await this.loadingController.create({});
    await loading.present();
    logger.debug('Opening file', this.getUrl(file));
    this.previewAnyFile.previewPath(this.getUrl(file), {
      name: file.name,
      mimeType: file.mime,
    }).subscribe(async () => {
      await loading.dismiss();
    }, async (e) => {
      await loading.dismiss();
      logger.error(e);
      await (await this.toastController.create({
        header: translations['errors.header.preview-document-error'],
        message: this.translateService.instant('errors.message.error-message', {error: e}),
        buttons: [translations['buttons.okay']],
        color: 'danger',
        duration: 5000,
        position: 'top',
      })).present();
    });
  }
}
