// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-avatar {
  width: 32px;
  height: 32px;
  margin-right: 1em;
}

.header {
  display: flex;
  align-items: center;
}

.ml-auto {
  margin-left: auto;
}

.clickable {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/comment/comment.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EAEA,iBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;AAAF;;AAGA;EACE,iBAAA;AAAF;;AAGA;EACE,eAAA;AAAF","sourcesContent":["ion-avatar {\n  width: 32px;\n  height: 32px;\n\n  margin-right: 1em;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n}\n\n.ml-auto {\n  margin-left: auto;\n}\n\n.clickable {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
