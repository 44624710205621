import { Injectable } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { UnitRelationshipMapper } from '@core/models/map-struct/unit-relationship.mapper';
import { UnitRelationship } from '@core/models/interfaces/unit-relationship.interface';
import { format, parseISO } from 'date-fns';
import { InviteResponse } from '@core/models/interfaces/invite-response.interface';
import { Pagination } from '@core/models/datatypes/pagination.interface';
import { OnlyRent } from '@core/models/datatypes/unit-relationship/only-rent.interface';
import { CompoundRent } from '@core/models/datatypes/unit-relationship/compound-rent.interface';
import { FixedRateRent } from '@core/models/datatypes/unit-relationship/fixed-rate-rent.interface';

@Injectable({
  providedIn: 'root',
})
export class UnitRelationshipService {
  private readonly entityName = 'api/unit-relationships';
  private readonly invitationNamespace = 'api/invitations/unit-relationships';

  constructor(private readonly apiService: ApiService,
              private readonly unitRelationshipMapper: UnitRelationshipMapper) {}

  public async getTenantRelationshipsByUnitId(unitId: number): Promise<UnitRelationship[]> {
    return this.apiService.getWithCaching(this.entityName, {
      filters: {
        unit: {
          id: {
            $eq: unitId,
          },
        },
        rentee: {
          id: {
            $notNull: true,
          },
        },
      },
      populate: [
        'rentee',
        'rentee.profilePicture',
      ],
      sort: ['startDate'],
    }).then((res => res.data.map(
        (unit) => this.unitRelationshipMapper.toEntity(unit),
      )
    ));
  }

  public async getAllRelationshipsByUnitId(unitId: number, page = 1, pageSize = 25):
    Promise<{ data: UnitRelationship[]; meta: Pagination }> {
    return this.apiService.getWithCaching(this.entityName, {
      filters: {
        unit: {
          id: {
            $eq: unitId,
          },
        },
      },
      populate: [
        'rentee',
        'rentee.profilePicture',
        'proprietor',
        'proprietor.profilePicture',
        'rent'
      ],
      pagination: {
        page,
        pageSize,
      },
      sort: ['startDate'],
    }).then((res => ({
        data: res.data.map(
          (unit) => this.unitRelationshipMapper.toEntity(unit),
        ),
        meta: 'pagination' in res.meta ? res.meta.pagination : res.meta as Pagination,
      })
    ));
  }

  public async getRelationshipByRelationshipId(relationshipId: number, page = 1, pageSize = 25)
    : Promise<{ data: UnitRelationship }> {

    return this.apiService.getWithCaching(this.entityName + '/' + relationshipId, {
      filters: {
        id: relationshipId,
      },
      populate: [
        'id',
        'unit',
        'rent',
      ],
      pagination: {
        page,
        pageSize,
      },
      sort: ['startDate'],
    }).then((res => ({
        data: res.data.attributes,
      })
    ));
  }

  public async getRelationshipById(relationshipId: number)
    : Promise<UnitRelationship> {

    return this.apiService.getWithCaching(this.entityName + '/' + relationshipId, {
      filters: {
        id: relationshipId,
      },
      populate: [
        'id',
        'unit',
        'rent',
      ],
      sort: ['startDate'],
    }).then((res => this.unitRelationshipMapper.toEntity(res.data)));
  }

  public async getLandlordRelationshipsByUnitId(unitId: number): Promise<UnitRelationship[]> {
    return this.apiService.getWithCaching(this.entityName, {
      filters: {
        unit: {
          id: {
            $eq: unitId,
          },
        },
        proprietor: {
          id: {
            $notNull: true,
          },
        },
      },
      populate: [
        'proprietor',
        'proprietor.address',
        'proprietor.profilePicture',
      ],
      sort: ['startDate'],
    }).then((res => res.data.map(
        (unit) => this.unitRelationshipMapper.toEntity(unit),
      )
    ));
  }

  async updateRelationship(relationshipId: number, data: RelationshipData) {
    return this.apiService.put(this.entityName + '/' + relationshipId, {
      data: {
        notes: data.notes,
        startDate: format(parseISO(data.start), 'yyyy-MM-dd'),
        endDate: data.end ? format(parseISO(data.end), 'yyyy-MM-dd') : null,
        numberOfPersons: data.numberOfPersons,
        rent: data.rent,
      },
    });
  }

  async updateRelationshipRent(relationshipId: number, data: RelationshipData) {
    return this.apiService.put(this.entityName + '/' + relationshipId, {
      data: {
        rent: data.rent,
      },
    });
  }

  async createRelationship(unitId: number, data: RelationshipData): Promise<InviteResponse> {
    let body: any = {
      email: data.user.email,
      firstname: data.user.firstname,
      lastname: data.user.lastname,
      unit: unitId,
      numberOfPersons: data.numberOfPersons,
      notes: data.notes,
      inviteAs: data.isProprietor ? 'proprietor' : 'rentee',
      startDate: format(parseISO(data.start), 'yyyy-MM-dd'),
      rent: data.rent,
    };

    if (data.user.companyName) {
      body = {
        ...body,
        companyName: data.user.companyName
      };
    }

    if (data.end) {
      body.endDate = format(parseISO(data.end), 'yyyy-MM-dd');
    }

    return this.apiService.post(this.invitationNamespace, body);
  }

  getCurrentProprietor(unitId: number) {
    const now = new Date();
    return this.apiService.getWithCaching(this.entityName, {
      filters: {
        unit: {
          id: {
            $eq: unitId,
          },
        },
        proprietor: {
          id: {
            $notNull: true,
          },
        },
        startDate: {
          $lte: now,
        },
        $or: [
          {
            endDate: {
              $null: true,
            },
          },
          {
            endDate: {
              $gt: now,
            },
          },
        ],
      },
      populate: [
        'proprietor',
        'proprietor.profilePicture',
      ],
      sort: ['startDate:desc'],
      pagination: {
        pageSize: 1,
        page: 1,
      },
    }).then((res => res.data.map(
        (unit) => this.unitRelationshipMapper.toEntity(unit),
      )
    ));
  }

  async deleteRelationship(id: number) {
    return this.apiService.delete(this.entityName + '/' + id);
  }

  async getRelationshipsByUnitIdAndUser(unitId: number, userId: number, page = 1, pageSize = 25) {
    return this.apiService.getWithCaching(this.entityName, {
      filters: {
        $and: [
          {
            unit: unitId
          },
          {
            $or: [
              {
                proprietor: userId,
              },
              {
                rentee: userId
              }
            ]
          }
        ],
      },
      populate: [
        'rentee',
        'proprietor',
      ],
      pagination: {
        page,
        pageSize,
      },
      sort: ['id'],
    }).then((res => ({
        data: res.data.map(
          (unit) => this.unitRelationshipMapper.toEntity(unit),
        ),
        meta: 'pagination' in res.meta ? res.meta.pagination : res.meta as Pagination,
      })
    ));
  }
}

interface RelationshipData {
  user: { firstname: string; email: string; lastname: string; companyName?: string } | null;
  start: string;
  end?: string | null;
  notes?: string;
  isProprietor: boolean;
  numberOfPersons: number;
  rent?: (OnlyRent | FixedRateRent | CompoundRent)[];
}
