import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Property } from '@core/models/interfaces/property.interface';
import { PropertyMaskComponent } from '@shared/components/property-mask/property-mask.component';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { RoleTypes } from '@core/models/datatypes/role.interface';

@Component({
  selector: 'app-property-list',
  templateUrl: './property-list.component.html',
  styleUrls: ['./property-list.component.scss'],
})
export class PropertyListComponent implements OnInit {

  @Input() properties: Property[];
  @Input() role: RoleTypes;
  @Output() propertyChanged = new EventEmitter<{ property: Property; photo?: any }>();
  @Output() propertyDeleted = new EventEmitter<Property>();
  @Output() propertyManagersEdit = new EventEmitter<Property>();
  @Output() propertyCreateProblem = new EventEmitter<Property>();

  public displayedColumns = [];

  constructor(private readonly modalController: ModalController,
              private readonly routerOutlet: IonRouterOutlet,
              private readonly router: Router) { }

  public ngOnInit() {
    this.displayedColumns = [
      'id', 'name', 'description', 'street', 'zip', 'city', 'country',
    ];
    if (this.hasPermissions()) {
      this.displayedColumns.push('actions');
    }
  }

  public async editProperty(property: Property) {
    const modal = await this.modalController.create({
      component: PropertyMaskComponent,
      id: PropertyMaskComponent.MODAL_ID,
      canDismiss: true,
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: {
        property,
      },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data?.dismissed === true || !data?.property) {
      return;
    }

    this.propertyChanged.emit(data);
  }

  public async delete(property: Property) {
    this.propertyDeleted.emit(property);
  }

  public async showDetails(property: Property) {
    await this.router.navigate(['/', 'app', 'properties', property.id]);
  }

  public hasPermissions() {
    return ['manager', 'admin'].includes(this.role);
  }

  public editManagers(property: Property) {
    this.propertyManagersEdit.emit(property);
  }

  createProblem(property: Property) {
    this.propertyCreateProblem.emit(property);
  }
}
