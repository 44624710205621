import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { ChatRoomService } from '@core/api/chat-room/chat-room.service';
import { MediaService } from '@core/api/media/media.service';
import { UserService } from '@app/@core/api/user/user.service';
import { UserAssignMaskComponent } from '@shared/components/user-assign-mask/user-assign-mask.component';
import { ChatRoom, CreatableChatRoom } from '@core/models/interfaces/chat-room.interface';

@Component({
  selector: 'app-chat-group-mask',
  templateUrl: './chat-group-mask.component.html',
  styleUrls: ['./chat-group-mask.component.scss'],
})
export class ChatGroupMaskComponent implements OnInit {

  @Input() chatRoom?: ChatRoom;
  @Input() owner?: number;
  @Input() participants?: number[];

  private documentForm: UntypedFormGroup;

  constructor(
    private readonly modalController: ModalController,
    private readonly toastController: ToastController,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly chatRoomService: ChatRoomService,
    private readonly imageService: MediaService,
    private readonly userService: UserService,
    private readonly loadingController: LoadingController,
  ) {
  }

  public get form(): UntypedFormGroup {
    return this.documentForm;
  }

  public async ngOnInit() {
    this.documentForm = this.createForm();
  }

  public async dismiss(): Promise<void> {
    await this.modalController.dismiss({
      dismissed: true,
    });
  }

  public async submit(event: any): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    if (this.form.invalid || this.form.disabled) {
      return;
    }
    const loading = await this.loadingController.create({});
    await loading.present();
    this.form.disable();

    const {
      title,
      participants,
      picture, pictureUploaded,
    } = this.form.value;

    // Values we need to create a group or update it
    const chatRoom = {
      id: this.chatRoom?.id,
      title,
      owner: this.owner,
      participants,
    } as CreatableChatRoom;

    try {
      let result;

      if (!this.chatRoom?.id) {
        result = await this.chatRoomService.createChatRoom(chatRoom);

        if (pictureUploaded) {
          await this.chatRoomService.updatePicture(result.data.id, pictureUploaded);
        }
      } else {
        result = await this.chatRoomService.updateChatRoom(chatRoom as ChatRoom);

        if (pictureUploaded && picture !== this.chatRoom.picture?.name) {
          if (this.chatRoom.picture) {
            await this.imageService.deleteFile(this.chatRoom.picture.id);
          }

          await this.chatRoomService.updatePicture(result.data.id, pictureUploaded);
        }
      }

      const name = (result?.data?.name
          ?? result?.data?.title
          ?? result?.data?.attributes?.name
          ?? result?.data?.attributes?.title)
        ?? 'Gruppenbild';

      await this.modalController.dismiss(name, 'success');
      this.form.reset();
    } catch (e) {
      await this.modalController.dismiss(e, 'error');
    } finally {
      this.form?.enable();
      await loading.dismiss();
    }
  }

  changeFile($event: any) {
    this.form.patchValue({ pictureUploaded: $event.target.files[0] });
  }

  async assignUsers() {
    const userAssign = await this.modalController.create({
      component: UserAssignMaskComponent,
      id: UserAssignMaskComponent.MODAL_ID,
      componentProps: {
        assigned: this.form.get('participants')?.value ?? [],
      },
    });

    await userAssign.present();

    const { data, role } = await userAssign.onWillDismiss();
    if (role !== UserAssignMaskComponent.SUCCESS_ROLE) {
      return;
    }

    this.form.patchValue({ participants: data.assigned });
  }

  private createForm(): UntypedFormGroup {
    return this.formBuilder.group({
      title: [this.chatRoom?.title ?? '', Validators.compose([Validators.required, Validators.minLength(3)])],
      participants: [this.participants ?? this.chatRoom?.participants?.map(p => p.id) ?? []],
      picture: [this.chatRoom?.picture?.name ?? null],
      pictureUploaded: [null],
    });
  }
}
