import { Component, Input, OnInit } from '@angular/core';
import { ContactService } from '@app/@core/api/contact/contact.service';
import { User } from '@app/@core/models/interfaces/user.interface';
import { Contact } from '@core/models/interfaces/contact.interface';
import { PropertyService } from '@core/api/property/property.service';
import { ToastController } from '@ionic/angular';
import { Logger } from '@core/logger/logger.service';
import { UsesCacheComponent } from '@core/api/cache/uses-cache';
import { CacheService } from '@app/@core/api/cache/cache.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

const logger = new Logger('a.@s.c.p.PropertyContactsCardComponent');

@Component({
  selector: 'app-property-contacts-card',
  templateUrl: './property-contacts-card.component.html',
  styleUrls: ['./property-contacts-card.component.scss'],
})
export class PropertyContactsCardComponent extends UsesCacheComponent implements OnInit {

  @Input() propertyId: number;
  @Input() user: User;

  public expanded: string = undefined;

  private allContacts: Contact[];
  private internalAssignedContacts: Contact[];
  private internalEditingContacts: Contact[];
  private internalAvailableContacts: Contact[];
  private editMode = false;

  private assignedSet: Set<number>;
  private availableSet: Set<number>;
  private editingSet: Set<number>;

  constructor(
    private readonly contactService: ContactService,
    private readonly propertyService: PropertyService,
    private readonly toastController: ToastController,
    protected readonly cacheService: CacheService,
    private translateService :TranslateService,
  ) {
    super(cacheService);
  }

  get hasContacts() {
    return this.internalAssignedContacts?.length > 0;
  }

  get hasPermissions(): boolean {
    return ['admin', 'manager'].includes(this.user?.role?.type);
  }

  get contacts() {
    return this.internalAssignedContacts;
  }

  get addedContacts() {
    return this.internalEditingContacts;
  }

  get availableContacts() {
    return this.internalAvailableContacts;
  }

  get inEditMode() {
    return this.editMode;
  }

  public trackById(_, contact) {
    return contact.id;
  }

  async loadContacts() {
    this.internalAssignedContacts = await this.contactService.getContactsByPropertyId(this.propertyId);
    this.assignedSet = new Set<number>();
    this.internalAssignedContacts.forEach((c) => this.assignedSet.add(c.id));
  }

  async ngOnInit() {
    await this.loadContacts();
    this.isInitialized = true;
  }

  async edit($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.editMode) {
      return;
    }
    this.expanded = 'expand';
    this.editMode = true;

    if (!this.allContacts) {
      this.contactService.getAll().then(({ contacts }) => {
        this.allContacts = contacts;
        this.resetEditingState();
      });
    } else {
      this.resetEditingState();
    }
  }

  accordionChanged($event: any) {
    this.expanded = $event.detail?.value;
  }

  addContactToList($event: any) {
    $event.preventDefault();
    $event.stopPropagation();

    const value = $event.detail.value;

    $event.target.value = undefined;

    // Find value in available
    const newContactIndex = this.internalAvailableContacts.findIndex((c) => c.id === value);
    if (newContactIndex === -1) {return;}

    // Remove value* from available
    const contact = this.internalAvailableContacts[newContactIndex];
    if (this.availableSet.has(contact.id)) {
      this.internalAvailableContacts.splice(newContactIndex, 1);
      this.availableSet.delete(contact.id);

      // Add value* to editing
      if (!this.editingSet.has(contact.id)) {
        this.internalEditingContacts.push(contact);
        this.editingSet.add(contact.id);
      }
    }
  }

  removeContactFromList(id: number) {
    if (!this.editingSet.has(id)) {
      return;
    }
    // Find value in editing
    const oldContactIndex = this.internalEditingContacts.findIndex((c) => c.id === id);
    if (oldContactIndex === -1) {return;}

    // Remove value* from available
    const contact = this.internalEditingContacts[oldContactIndex];
    // remove value* from editing
    this.internalEditingContacts.splice(oldContactIndex, 1);
    this.editingSet.delete(id);

    // add value* to available
    if (this.availableSet.has(id)) {
      return;
    }
    this.internalAvailableContacts.push(contact);
    this.availableSet.add(id);
  }

  async cancelEditing() {
    this.editingSet = undefined;
    this.availableSet = undefined;

    this.internalEditingContacts = undefined;
    this.internalAvailableContacts = undefined;
    this.editMode = false;
  }

  async saveContacts() {
    const translations = await firstValueFrom(this.translateService.get([
      'buttons.okay',
      'errors.header.components.property-contacts-card.save-contacts-error-header',
      'components.property-contacts-card.save-contacts-header',
      'components.property-contacts-card.save-contacts-message'
    ]));
    const ids = [...this.editingSet.values()];
    try {
      await this.propertyService.updatePropertyContactsById(this.propertyId, ids);
      await this.clearCacheAndWait();
      this.internalAssignedContacts = [...this.internalEditingContacts];
      await this.cancelEditing();
      await (await this.toastController.create({
        header: translations['components.property-contacts-card.save-contacts-header'],
        message: translations['components.property-contacts-card.save-contacts-message'],
        buttons: [translations['buttons.okay']],
        color: 'success',
        duration: 3000,
        position: 'top',
      })).present();
    } catch (e) {
      logger.error(e);
      await (await this.toastController.create({
        header: translations['errors.header.components.property-contacts-card.save-contacts-error-header'],
        message: this.translateService.instant('errors.message.error-message', {error: e}),
        buttons: [translations['buttons.okay']],
        color: 'danger',
        duration: 5000,
        position: 'top',
      })).present();
    }
  }

  onCacheCleared(lastRefresh: Date): Promise<void> | void {
    return this.loadContacts();
  }

  private resetEditingState() {
    this.editingSet = new Set<number>();
    this.availableSet = new Set<number>();

    this.internalEditingContacts = [...this.internalAssignedContacts];
    this.internalEditingContacts.forEach((c) => this.editingSet.add(c.id));
    this.internalAvailableContacts = this.allContacts.filter((c) => !this.assignedSet.has(c.id));
    this.internalAvailableContacts.forEach((c) => this.availableSet.add(c.id));
  }
}
