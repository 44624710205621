import { ApiService } from '@core/api/api.service';
import { Injectable } from '@angular/core';
import { Pagination } from '@core/models/datatypes/pagination.interface';
import { MeterReadingMapper } from '@app/@core/models/map-struct/meter-reading.mapper';
import { MeterReading } from '@app/@core/models/interfaces/meter-reading.interface';
import { MediaService } from '@core/api/media/media.service';

@Injectable({
  providedIn: 'root',
})
export class MeterReadingService {
  private readonly entityName = 'api/meter-readings';
  private readonly entityRef = 'api::meter-reading.meter-reading';

  constructor(
    private readonly apiService: ApiService,
    private readonly mediaService: MediaService,
    private readonly meterReadingMapper: MeterReadingMapper,
  ) {}

  public async getAllMeterReadings(page = 1, pageSize = 25): Promise<{ data: MeterReading[]; meta: Pagination }> {
    return this.apiService.getWithCaching(this.entityName, {
      populate: [
        'photo',
        'addedBy',
        'addedBy.profilePicture',
        'meter',
        'meter.unit',
        'meter.unit.property',
        'meter.unit.property.address',
      ],
      pagination: {
        page,
        pageSize,
      },
      sort: ['date:desc'],
    }).then((res) => ({
      data: res.data.map((reading) => this.meterReadingMapper.toEntity(reading)),
      meta: ('pagination' in res.meta ? res.meta.pagination : res.meta) as Pagination,
    }));
  }

  public async getLastReadingByMeterId(meterId: number): Promise<MeterReading | undefined> {
    return this.getReadingsByMeterId(meterId)
      .then(res => res.length > 0 ? res[0] : undefined);
  }

  public async getPreviousReadingByMeterId(meterId: number): Promise<MeterReading | undefined> {
    return this.getReadingsByMeterId(meterId)
      .then(res => res.length > 1 ? res[1] : undefined);
  }

  public async getReadingsByMeterId(meterId: number): Promise<MeterReading[]> {
    return this.apiService.getWithCaching(this.entityName, {
      filters: {
        meter: {
          id: meterId
        }
      },
      populate: [
        'meter',
        'photo'
      ],
      sort: ['date:desc'],
    }).then(res => res.data.map((meter) => this.meterReadingMapper.toEntity(meter)));
  }

  async addMeterReading(id: number, data: {reading: number; addedBy: number}) {
    return this.apiService.post(this.entityName, {
      data: {
        ...data,
        date: new Date().toISOString(),
        meter: id,
      }
    });
  }

  public async deleteById(id: number) {
    return this.apiService.delete(`${this.entityName}/${id}`);
  }

  async uploadPhoto(newId: any, photoFile: string | File | Blob, filename: string) {
    return this.mediaService.uploadFiles(newId, this.entityRef, 'photo', photoFile, undefined, filename);
  }
}
