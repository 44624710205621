import { Mapper } from '@core/models/map-struct/mapper.interface';
import { Injectable, Injector } from '@angular/core';
import { UserMapper } from './user.mapper';
import { MediaMapper } from './media.mapper';
import { Ticket } from '../interfaces/ticket.interface';
import { ProblemMapper } from './problem.mapper';
import { ContractorMapper } from '@core/models/map-struct/contractor.mapper';

@Injectable({
  providedIn: 'root',
})
export class TicketMapper implements Mapper<Ticket> {

  private userMapper: UserMapper;
  private mediaMapper: MediaMapper;
  private problemMapper: ProblemMapper;
  private contractorMapper: ContractorMapper;

  constructor(private injector: Injector) {

  }

  toEntity(apiData: any): Ticket | undefined {
    if (!('attributes' in apiData)) {
      return undefined;
    }
    const mappedObject = {
      id: apiData.id,
      title: apiData.attributes.title,
      body: apiData.attributes.body,
      status: apiData.attributes.status,
      createdAt: apiData.attributes.createdAt,
      updatedAt: apiData.attributes.updatedAt,
    } as Ticket;

    if ('problem' in apiData.attributes && apiData.attributes.problem != null) {
      if (apiData.attributes.problem?.data != null) {
        if (!this.problemMapper) {
          this.problemMapper = this.injector.get(ProblemMapper);
        }
        mappedObject.problem = this.problemMapper.toEntity(apiData.attributes.problem.data);
      } else if (!('data' in apiData.attributes.problem)) {
        mappedObject.problem = apiData.attributes.problem;
      }
    }

    if ('creator' in apiData.attributes && apiData.attributes.creator != null) {
      if (apiData.attributes.creator?.data != null) {
        if (!this.userMapper) {
          this.userMapper = this.injector.get(UserMapper);
        }
        mappedObject.creator = this.userMapper.toEntity(apiData.attributes.creator.data);
      } else if (!('data' in apiData.attributes.creator)) {
        mappedObject.creator = apiData.attributes.creator;
      }
    }

    if ('participants' in apiData.attributes && apiData.attributes.participants != null) {
      if (apiData.attributes.participants?.data != null) {
        if (!this.userMapper) {
          this.userMapper = this.injector.get(UserMapper);
        }
        mappedObject.participants = apiData.attributes.participants.data.map((user) => this.userMapper.toEntity(user));
      } else if (!('data' in apiData.attributes.participants)) {
        mappedObject.participants = apiData.attributes.participants;
      }
    }

    if ('assignedContractor' in apiData.attributes && apiData.attributes.assignedContractor != null) {
      if (apiData.attributes.assignedContractor?.data != null) {
        if (!this.contractorMapper) {
          this.contractorMapper = this.injector.get(ContractorMapper);
        }
        mappedObject.assignedContractor = this.contractorMapper.toEntity(apiData.attributes.assignedContractor.data);
      } else if (!('data' in apiData.attributes.assignedContractor)) {
        mappedObject.assignedContractor = apiData.attributes.assignedContractor;
      }
    }

    if ('attachments' in apiData.attributes && apiData.attributes.attachments != null) {
      if (apiData.attributes.attachment?.data != null) {
        if (!this.mediaMapper) {
          this.mediaMapper = this.injector.get(MediaMapper);
        }
        mappedObject.attachments = apiData.attributes.attachments.data.map(media => this.mediaMapper.toEntity(media));
      } else if (!('data' in apiData.attributes.attachments)) {
        mappedObject.attachments = apiData.attributes.attachments;
      }
    }

    // TODO checklist
    // TODO comments

    return mappedObject;
  }
}
