// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overflow {
  overflow-y: auto;
}

.content {
  height: 100%;
  width: 100%;
}

table tr:nth-child(even):not(.header-row) {
  background-color: rgba(var(--ion-color-primary-rgb), 0.1);
}
table tr.header-row {
  border-bottom: 1px solid black;
}
table tr td {
  padding: 0.5em;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/cost-type-field/add-cost-type-modal/add-cost-type-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AACA;EACE,YAAA;EACA,WAAA;AAEF;;AAGI;EACE,yDAAA;AAAN;AAGI;EACE,8BAAA;AADN;AAII;EACE,cAAA;AAFN","sourcesContent":[".overflow {\n  overflow-y: auto;\n}\n.content {\n  height: 100%;\n  width: 100%;\n}\n\ntable  {\n  tr {\n    &:nth-child(even):not(.header-row) {\n      background-color: rgba(var(--ion-color-primary-rgb), 0.1);\n    }\n\n    &.header-row {\n      border-bottom: 1px solid black;\n    }\n\n    td {\n      padding: 0.5em;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
