import { Injectable, Injector } from '@angular/core';
import { Mapper } from '@core/models/map-struct/mapper.interface';
import { CostType } from '@core/models/interfaces/cost-type.interface';
import { CompanyMapper } from '@core/models/map-struct/company.mapper';
import { PropertyMapper } from '@core/models/map-struct/property.mapper';

@Injectable({
  providedIn: 'root',
})
export class CostTypeMapper implements Mapper<CostType> {
  private companyMapper: CompanyMapper;
  private propertyMapper: PropertyMapper;

  constructor(private injector: Injector) {}
  toEntity(apiData: any): CostType | undefined {
    if (!('id' in apiData)) {
      return undefined;
    }

    const mappedObject = {
      id: apiData.id,
      name: apiData.attributes.name,
      apportionmentFormula: apiData.attributes.apportionmentFormula,
      nonApportionable: apiData.attributes.nonApportionable,
    } as CostType;

    if ('company' in apiData.attributes && apiData.attributes.company != null) {
      if (apiData.attributes.company?.data != null) {
        if (!this.companyMapper) {
          this.companyMapper = this.injector.get(CompanyMapper);
          this.companyMapper.setCostTypeMapper(this);
        }
        mappedObject.company = this.companyMapper.toEntity(apiData.attributes.company.data)
      } else if (!('data' in apiData.attributes.company)) {
        mappedObject.company = apiData.attributes.company;
      }
    }

    if ('property' in apiData.attributes && apiData.attributes.property != null) {
      if (apiData.attributes.property?.data != null) {
        if (!this.propertyMapper) {
          this.propertyMapper = this.injector.get(PropertyMapper);
        }
        mappedObject.property = this.propertyMapper.toEntity(apiData.attributes.property.data);
      } else if (!('data' in apiData.attributes.property)) {
        mappedObject.property = apiData.attributes.property;
      }
    }

    return mappedObject;
  }

  setCompanyMapper(mapper: CompanyMapper) {
    this.companyMapper = mapper;
  }
}
