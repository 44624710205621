// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-array-container {
  margin: 1.5rem 0.5rem;
  border-bottom: 2px solid #000;
  padding: 1rem;
  webkit-box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.75);
}

.form-array-input-container {
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
  column-gap: 0.5rem;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1.5rem 0.5rem;
}
.buttons-container ion-button {
  min-width: 8rem;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/relationship-rent-mask/relationship-rent-mask.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,6BAAA;EACA,aAAA;EACA,uDAAA;EAEA,gDAAA;AACF;;AAEA;EACE,aAAA;EACA,qBAAA;EACA,iBAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,qBAAA;AAAF;AAEE;EACE,eAAA;AAAJ","sourcesContent":[".form-array-container{\n  margin: 1.5rem 0.5rem;\n  border-bottom: 2px solid #000;\n  padding: 1rem;\n  webkit-box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.75);\n  -moz-box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.75);\n  box-shadow: 0px 2px 5px -1px rgba(0,0,0,0.75);\n}\n\n.form-array-input-container{\n  display: flex;\n  align-items: flex-end;\n  flex-wrap: nowrap;\n  justify-content: space-between;\n  flex-direction: row;\n  column-gap: 0.5rem;\n}\n\n\n.buttons-container{\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin: 1.5rem 0.5rem;\n\n  ion-button{\n    min-width: 8rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
