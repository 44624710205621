import { Injectable } from '@angular/core';
import { Mapper } from '@core/models/map-struct/mapper.interface';
import { Image } from '@core/models/datatypes/image.interface';
import { MediaMapper } from '@core/models/map-struct/media.mapper';

@Injectable({
  providedIn: 'root',
})
export class ImageMapper extends MediaMapper implements Mapper<Image> {
  toEntity(apiData: any): Image | undefined {
    if (!('attributes' in apiData)) {
      return undefined;
    }

    const entity = super.toEntity(apiData);

    return {
      ...entity,
      formats: apiData.attributes.formats,
    } as Image;
  }
}
