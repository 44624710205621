// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w-100 {
  width: 100%;
}

ion-grid, .ion-grid,
ion-row, .ion-row,
.ion-col, ion-col {
  padding: 0;
  margin: 0;
}

.stretch {
  height: calc(100% - 20px);
}

.header-and-icon {
  display: flex;
  align-items: center;
}
.header-and-icon ion-icon, .header-and-icon ion-button, .header-and-icon ion-img::part(image) {
  margin-left: auto;
}

.change {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.thumbnail-image::part(image) {
  width: 32px;
  height: auto;
  max-height: 32px;
}

.bold {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/meter-readout/meter-readout.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;;;EAGE,UAAA;EACA,SAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,iBAAA;AACJ;;AAGA;EACE,aAAA;EACA,8BAAA;EACA,WAAA;AAAF;;AAIE;EACE,WAAA;EACA,YAAA;EACA,gBAAA;AADJ;;AAKA;EACE,iBAAA;AAFF","sourcesContent":[".w-100 {\n  width: 100%;\n}\n\nion-grid, .ion-grid,\nion-row, .ion-row,\n.ion-col, ion-col {\n  padding: 0;\n  margin: 0;\n}\n\n.stretch {\n  height: calc(100% - 20px);\n}\n\n.header-and-icon {\n  display: flex;\n  align-items: center;\n\n  ion-icon, ion-button, ion-img::part(image) {\n    margin-left: auto;\n  }\n}\n\n.change {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.thumbnail-image {\n  &::part(image) {\n    width: 32px;\n    height: auto;\n    max-height: 32px;\n  }\n}\n\n.bold {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
