import { Component, effect, ElementRef, Input, OnInit, signal, ViewChild } from '@angular/core';
import { UserService } from '@app/@core/api/user/user.service';
import { User } from '@core/models/interfaces/user.interface';
import { AnimationController, ModalController, ToastController } from '@ionic/angular';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { UsesCacheComponent } from '@core/api/cache/uses-cache';
import { CacheService } from '@app/@core/api/cache/cache.service';
import { TranslateService } from '@ngx-translate/core';
import { getThumbnailUrlOrPlaceholder } from '@core/models/utils/media.utils';

@Component({
  selector: 'app-bulk-user-assign-mask',
  templateUrl: './bulk-user-assign-mask.component.html',
  styleUrls: ['./bulk-user-assign-mask.component.scss'],
})
export class BulkUserAssignMaskComponent extends UsesCacheComponent implements OnInit {
  public static readonly SUCCESS_ROLE = 'assign';
  public static readonly MODAL_ID = 'user-assign-mask-component';

  @Input() showOnlyTenants = false;

  public assignedUsers = new BehaviorSubject<User[]>([]);
  public unassignedUsers = new BehaviorSubject<User[]>([]);
  public removedUsers = new BehaviorSubject<User[]>([]);
  protected readonly getThumbnailUrlOrPlaceholder = getThumbnailUrlOrPlaceholder;
  private originalAssignedUsers?: number[];
  private allUsers?: User[];
  private availableSet: Set<number> = new Set<number>();
  private assignedSet: Set<number> = new Set<number>();
  private removedSet: Set<number> = new Set<number>();
  private isSearchActive = false;
  private searchTerm = undefined;

  constructor(
    private readonly modalController: ModalController,
    private readonly userService: UserService,
    private readonly toastController: ToastController,
    protected readonly cacheService: CacheService,
    private translateService: TranslateService,
    private animationController: AnimationController,
  ) {
    super(cacheService);
  }

  @Input() set assigned(users: number[]) {
    this.originalAssignedUsers = users;
    this.updateSets().then();
  }

  private static fuzzy(value, term, ratio) {
    const loweredValue = value.toLowerCase();
    const compare = term.toLowerCase();
    let matches = 0;
    if (loweredValue.indexOf(compare) > -1) {return true;} // covers basic partial matches
    for (const compared of compare) {
      if (loweredValue.indexOf(compared) > -1) {
        matches += 1;
      } else {
        matches -= 1;
      }
    }
    return (matches / value.length >= ratio || term === '');
  }

  public trackById = (i, entity) => entity.id;

  async ngOnInit() {
    await this.initializeData();
    this.isInitialized = true;
  }

  public async dismiss(): Promise<void> {
    await this.modalController.dismiss({
      dismissed: true,
    }, undefined, BulkUserAssignMaskComponent.MODAL_ID);
  }

  public async addToAssigned(id: number) {
    this.availableSet.delete(id);

    this.assignedSet.add(id);
    const sorted = Array.from(this.assignedSet).sort((a, b) => a - b);
    this.assignedSet.clear();
    sorted.forEach(n => this.assignedSet.add(n));

    if (this.isSearchActive) {
      this.triggerSearch();
    } else {
      this.updateStreams();
    }
  }

  public async removeFromAssigned(id: number) {
    this.assignedSet.delete(id);
    this.availableSet.add(id);

    const sorted = Array.from(this.availableSet).sort((a, b) => a - b);
    this.availableSet.clear();
    sorted.forEach(n => this.availableSet.add(n));

    if (this.isSearchActive) {
      this.triggerSearch();
    } else {
      this.updateStreams();
    }
  }

  public async addToRemoved(id: number) {
    this.availableSet.delete(id);

    this.removedSet.add(id);
    const sorted = Array.from(this.removedSet).sort((a, b) => a - b);
    this.removedSet.clear();
    sorted.forEach(n => this.removedSet.add(n));

    if (this.isSearchActive) {
      this.triggerSearch();
    } else {
      this.updateStreams();
    }
  }

  public async removeFromRemoved(id: number) {
    this.removedSet.delete(id);
    this.availableSet.add(id);

    const sorted = Array.from(this.removedSet).sort((a, b) => a - b);
    this.removedSet.clear();
    sorted.forEach(n => this.removedSet.add(n));

    if (this.isSearchActive) {
      this.triggerSearch();
    } else {
      this.updateStreams();
    }
  }

  search($event: any) {
    this.isSearchActive = true;
    this.searchTerm = $event.detail.value.trim();
    const assigned = [...this.assignedSet].map((id) => this.allUsers?.find(
      (u) => u.id === id && (
        u.id.toString() === this.searchTerm
        || BulkUserAssignMaskComponent.fuzzy(
          `${u.companyName ? !!u.companyName + ' ' : ''}${u.firstname} ${u.lastname}`,
          this.searchTerm,
          0.32,
        )
      )),
    ).filter(Boolean);
    const available = [...this.availableSet].map((id) => this.allUsers?.find(
      (u) => u.id === id && (
        u.id.toString() === $event.detail.value.trim()
        || BulkUserAssignMaskComponent.fuzzy(
          `${u.companyName ? !!u.companyName + ' ' : ''}${u.firstname} ${u.lastname}`,
          this.searchTerm,
          0.32,
        )
      )),
    ).filter(Boolean);
    const removed = [...this.removedSet].map((id) => this.allUsers?.find(
      (u) => u.id === id && (
        u.id.toString() === $event.detail.value.trim()
        || BulkUserAssignMaskComponent.fuzzy(
          `${u.companyName ? !!u.companyName + ' ' : ''}${u.firstname} ${u.lastname}`,
          this.searchTerm,
          0.32,
        )
      )),
    ).filter(Boolean);
    this.assignedUsers.next(assigned);
    this.unassignedUsers.next(available);
    this.removedUsers.next(removed);
  }

  resetSearch() {
    this.isSearchActive = false;
    this.searchTerm = undefined;
    this.updateStreams();
  }

  public async save() {
    await this.modalController.dismiss({
      assigned: [...this.assignedSet],
      removed: [...this.removedSet],
    }, BulkUserAssignMaskComponent.SUCCESS_ROLE, BulkUserAssignMaskComponent.MODAL_ID);
  }

  onCacheCleared(lastRefresh: Date): Promise<void> | void {
    return this.initializeData();
  }

  private async initializeData() {
    let hasMore = false;
    this.allUsers = [];
    let pageIndex = 1;
    do {
      const allUsers = this.showOnlyTenants ?
        await this.userService.getTenants(pageIndex, 100)
        : await this.userService.getUsers(undefined, 'any', false, undefined, pageIndex, 100);
      this.allUsers.push(...allUsers.data);
      hasMore = allUsers.meta.page < allUsers.meta.pageCount;
      pageIndex++;
    } while (hasMore);
    await this.updateSets();
  }

  private triggerSearch() {
    return this.search({
      detail: {
        value: this.searchTerm,
      },
    });
  }

  private async updateSets() {
    const translations = await firstValueFrom(this.translateService.get([
      'buttons.okay',
      'components.user-assign-mask.update-sets-warning-header',
    ]));
    if (!this.availableSet) {
      this.availableSet = new Set<number>();
    } else {
      this.availableSet.clear();
    }
    if (this.allUsers) {
      this.allUsers.forEach((user) => this.availableSet.add(user.id));
    }

    if (!this.assignedSet && this.originalAssignedUsers) {
      this.assignedSet = new Set<number>();
      this.originalAssignedUsers.forEach((id) => {
        this.assignedSet.add(id);
      });
    }

    if (this.assignedSet && this.availableSet.size > 0) {
      let unavailable = 0;
      this.assignedSet.forEach((id) => {
        if (this.availableSet.has(id)) {
          this.availableSet.delete(id);
        } else {
          unavailable++;
          this.assignedSet.delete(id);
        }
      });
      if (unavailable) {
        await (await this.toastController.create({
          header: translations['components.user-assign-mask.update-sets-warning-header'],
          message: this.translateService.instant('components.user-assign-mask.update-sets-warning-message', { number: unavailable }),
          icon: 'alert-circle-outline',
          color: 'warning',
          duration: 5000,
          buttons: [translations['buttons.okay']],
        })).present();
      }
    }

    this.updateStreams();
  }

  private updateStreams() {
    const available = [...this.availableSet].map((id) => this.allUsers?.find((u) => u.id === id)).filter(Boolean);
    const assigned = [...this.assignedSet].map((id) => this.allUsers?.find((u) => u.id === id)).filter(Boolean);
    const removed = [...this.removedSet].map((id) => this.allUsers?.find((u) => u.id === id)).filter(Boolean);
    this.assignedUsers.next(assigned);
    this.unassignedUsers.next(available);
    this.removedUsers.next(removed);
  }
}
