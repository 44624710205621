import { TranslateService } from '@ngx-translate/core';

const propertyTypes = {
  residence: 'core.propertyTypes.residence',
  commercial: 'core.propertyTypes.commercial',
  mixed: 'core.propertyTypes.mixed',
  weg: 'core.propertyTypes.weg',
};

const meterTypes = {
  electricity: 'core.meterTypes.electricity',
  water: 'core.meterTypes.water',
  gas: 'core.meterTypes.gas',
};

export const getTranslationForPropertyType = (type: string, translateService: TranslateService) => {
  if (type in propertyTypes) {
    return translateService.instant(propertyTypes[type]);
  }
  return type;

};

export const getTranslationForMeterType = (type: string, translateService: TranslateService) => {
  if (type in meterTypes) {
    return translateService.instant(meterTypes[type]);
  }
  return type;
};
