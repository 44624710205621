import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Comment } from '@core/models/datatypes/comment.interface';
import { getThumbnailUrl } from '@core/models/utils/media.utils';
import { User } from '@core/models/interfaces/user.interface';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentComponent {

  @Input() comment: Comment;
  @Input() internalUser: User;

  getAvatar() {
    return getThumbnailUrl(this.comment?.user?.profilePicture) ?? 'assets/images/avatar.svg';
  }

  hasPermissions() {
    if (['admin', 'manager'].includes(this.internalUser?.role?.type ?? 'public')) {
      return this.comment?.user?.role?.type !== 'contractor';
    }
    return false;
  }
}
