import { Image, ImageResolution, imageResolutions } from '@core/models/datatypes/image.interface';
import { MediaFormat } from '@core/models/datatypes/media-format.interface';
import { apiSettings } from '@env/environment';

/**
 * Returns the image format with the highest resolution
 */
export const getBestImage = (image: Image, maxRes?: ImageResolution): MediaFormat => {
  if(!image || !image.formats) {
    return undefined;
  }
  const fromIndex: number = maxRes ? imageResolutions.indexOf(maxRes) : 0;

  for (let i = fromIndex; i < imageResolutions.length; i++) {
    if (image.formats[imageResolutions[i]]) {
      return image.formats[imageResolutions[i]];
    }
  }
};

export const getMediaFormatUrl = (mediaFormat: MediaFormat): string => {
  if (typeof mediaFormat === 'undefined') {
    return undefined;
  }

  return `${apiSettings.baseUrl}${mediaFormat.url}`;
};

export const getThumbnailUrl = (image: Image): string | undefined => {
  if (!image?.formats?.thumbnail?.url) {
    if (image?.url) {
      return apiSettings.baseUrl + image.url;
    }
    return undefined;
  }

  return getMediaFormatUrl(image.formats.thumbnail);
};

export const getThumbnailUrlOrPlaceholder = (image: Image): string => getThumbnailUrl(image) ?? 'assets/images/media-not-found.png';
export const getImageUrl = (image: Image): string | undefined => {
  const bestImage = getBestImage(image, 'large');
  if (!bestImage?.url) {
    if (image?.url) {
      return apiSettings.baseUrl + image.url;
    }
    return undefined;
  }

  return getMediaFormatUrl(bestImage);
};
