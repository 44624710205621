// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar ion-img::part(image) {
  height: auto;
  max-height: 128px;
  max-width: 128px;
  background: linear-gradient(var(--ion-color-primary), var(--ion-color-secondary), var(--ion-color-dark));
}

.avatar-wrapper {
  display: flex;
  justify-content: center;
  margin: 0.5em;
}

.clickable {
  cursor: pointer;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/manager-mask/manager-mask.component.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,wGAAA;AAAJ;;AAIA;EACE,aAAA;EACA,uBAAA;EACA,aAAA;AADF;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,eAAA;EACA,0BAAA;AADF","sourcesContent":[".avatar {\n  ion-img::part(image) {\n    height: auto;\n    max-height: 128px;\n    max-width: 128px;\n    background: linear-gradient(var(--ion-color-primary), var(--ion-color-secondary), var(--ion-color-dark));\n  }\n}\n\n.avatar-wrapper {\n  display: flex;\n  justify-content: center;\n  margin: 0.5em;\n}\n\n.clickable {\n  cursor: pointer;\n}\n\n.link {\n  cursor: pointer;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
