import { ApiService } from '@core/api/api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MediaService {

  constructor(private readonly apiService: ApiService,
  ) {
  }

  public async uploadFiles(
    id: number | string,
    ref: string,
    field: string,
    file: string | string[] | File[] | File | Blob | FileList,
    existingFiles?: number[],
    filename?: string,
    signal?: AbortSignal
  ) {
    const formData = new FormData();
    if (file instanceof FileList) {
      for (let i = 0; i < file.length; i++) {
        const uploadFile = file.item(i);
        formData.append(`files`, uploadFile, uploadFile.name);
      }
    } else if (Array.isArray(file)) {
      for (const uploadFileIndex in file) {
        if (!file[uploadFileIndex]) {continue;}
        const uploadFile = file[uploadFileIndex] as any;
        if ('name' in uploadFile) {
          formData.append(`files`, uploadFile, uploadFile.name);
        } else {
          formData.append(`files`, uploadFile);
        }
      }
    } else {
      if (filename) {
        // @ts-ignore
        formData.append('files', file, filename);
      } else {
        // @ts-ignore
        formData.append('files', file);
      }
    }

    if (existingFiles && existingFiles.length) {
      for (const fileId of existingFiles) {
        formData.append('files', fileId.toString());
      }
    }

    formData.append('refId', id.toString());
    formData.append('ref', ref);
    formData.append('field', field);

    return this.apiService.upload('api/upload', formData, 'POST', signal);
  }

  deleteFile(id: number) {
    return this.apiService.delete('api/upload/files/' + id);
  }
}
