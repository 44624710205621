import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentReference,
  Query,
  QueryDocumentSnapshot,
} from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { serverTimestamp } from '@angular/fire/firestore';

import { UserService } from '@core/api/user/user.service';

import { Chat } from '@core/models/firebase/interfaces/chat.interfaces';
import { Message } from '@core/models/firebase/interfaces/message.interfaces';
import { UserID } from '@core/models/interfaces/user.interface';
import { ChatUid } from '@core/models/firebase/types/chat.types';

@Injectable({
  providedIn: 'root',
})
export class ChatService {

  constructor(
    private readonly userService: UserService,
    private readonly firestore: AngularFirestore,
  ) {}

  /**
   * Retrieve the collection of messages of the specified chat room
   */
  public getChatMessageCollection(chatUid: ChatUid): AngularFirestoreCollection<Message> {
    return this.firestore
      .collection<Chat>('chats')
      .doc(chatUid)
      .collection<Message>('messages');
  }

  /**
   * Get an Observable for the chats document
   */
  public getChatInfo(chatUid: ChatUid): Observable<Chat | undefined> {
    return this.firestore
      .collection<Chat>('chats')
      .doc(chatUid)
      .valueChanges({ idField: 'id' });
  }

  /**
   * Retrieve the collection of messages of the specified chat room, ordered by date
   *
   * Supports pagination via a limit and a cursor.
   */
  public getMessagesFromChatRoomInOrder(
    chatUid: string,
    limit = 5,
    start?: QueryDocumentSnapshot<Message>,
  ): AngularFirestoreCollection<Message> {
    return this.firestore
      .collection<Message>(`chats/${chatUid}/messages`, ref => {
        let refD = ref.orderBy('timestamp', 'desc');

        if (start) {
          refD = refD.startAfter(start);
        }

        return refD.limit(limit);
      });
  }

  /**
   * Get count of messages in the specified chat room
   */
  public async getAmountOfMessagesFromChatRoom(chatUid: ChatUid): Promise<number> {
    const dataRef = await this.firestore
      .collection<Chat>('chats')
      .doc(chatUid)
      .collection<Message>('messages')
      .ref
      .get();

    return dataRef.size;
  }

  /**
   * Get a query for the last message in the specified chat room
   *
   * Can be used to add a snapshot listener to the newest message.
   */
  public onNewMessage(chatUid: string): Query<Message> {
    return this.getChatMessageCollection(chatUid)
      .ref
      .orderBy('timestamp', 'desc')
      .limit(1);
  }

  /**
   * Sends a message into the specified chat room
   */
  public async sendMessageFromString(chatUid: ChatUid, message: string): Promise<DocumentReference<Message>> {
    const userId = await this.getUserId();

    const messageObject = {
      senderId: userId,
      type: 'text',
      contentText: message,
      timestamp: serverTimestamp(),
    } as Message;

    return this.sendMessage(chatUid, messageObject);
  }

  /**
   * Send a message into the specified chat room
   */
  public sendMessage(chatUid: string, message: Message): Promise<DocumentReference<Message>> {
    const messagesCollection = this.getChatMessageCollection(chatUid);
    return messagesCollection.add(message);
  }

  /**
   * Set rooms unreadMessage count for user to zero
   */
  public async setChatRoomAsRead(chatUid: ChatUid): Promise<void> {
    const userId = await this.getUserId();

    await this.firestore
      .collection<Chat>('chats')
      .doc(chatUid)
      .update({
        [`unreadMessages.${userId}`]: 0,
      });
  }

  private async getUserId(): Promise<UserID> {
    const user = await this.userService.getMe();
    return user.id;
  }

}
