import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CacheService } from '@app/@core/api/cache/cache.service';
import { PostService } from '@app/@core/api/post/post.service';
import { UserService } from '@app/@core/api/user/user.service';
import { Post } from '@core/models/interfaces/post.interface';
import { Logger } from '@core/logger/logger.service';

const logger = new Logger('a.@s.c.b.BlackboardMaskComponent');

@Component({
  selector: 'app-blackboard-mask',
  templateUrl: './blackboard-mask.component.html',
  styleUrls: ['./blackboard-mask.component.scss'],
})
export class BlackboardMaskComponent implements OnInit {

  @Input() propertyId: number;

  public readonly form: UntypedFormGroup;
  private role = 'public';

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly postService: PostService,
    private readonly userService: UserService,
    private readonly cacheService: CacheService,
  ) {
    this.form = this.createForm();
  }

  get showMask() {
    return ['admin', 'manager'].includes(this.role);
  }

  async ngOnInit() {
    const user = await this.userService.getMe();
    this.form.patchValue({ userId: user?.id ?? null });
    this.role = user?.role?.type ?? 'public';
  }

  async createPost(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (this.form.invalid || this.form.disabled) {
      return;
    }
    this.form.disable();

    const { topic, message, userId } = this.form.value;

    const date = new Date().toISOString();

    const post = {
      title: topic,
      message,
      date,
      createdAt: date,
      updatedAt: date,
    } as Post;

    try {
      await this.postService.createPostForProperty(this.propertyId, userId, post);
      this.form.reset();
      await this.cacheService.clearCache();
    } catch (e) {
      logger.error(e);
      // TODO err
    } finally {
      this.form?.enable();
    }

  }

  private createForm(): UntypedFormGroup {
    return this.formBuilder.group({
      topic: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      message: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      userId: [null, Validators.required],
    });
  }
}
