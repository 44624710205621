import { Mapper } from '@core/models/map-struct/mapper.interface';
import { Injectable, Injector } from '@angular/core';
import { Property } from '@core/models/interfaces/property.interface';
import { PostMapper } from '@core/models/map-struct/post.mapper';
import { ImageMapper } from '@core/models/map-struct/image.mapper';
import { UnitMapper } from '@core/models/map-struct/unit.mapper';
import { UserMapper } from '@core/models/map-struct/user.mapper';
import { ContactMapper } from '@core/models/map-struct/contact.mapper';

@Injectable({
  providedIn: 'root',
})
export class PropertyMapper implements Mapper<Property> {
  private postMapper: PostMapper;
  private imageMapper: ImageMapper;
  private unitMapper: UnitMapper;
  private userMapper: UserMapper;
  private contactMapper: ContactMapper;

  constructor(private readonly injector: Injector) {}

  toEntity(apiData: any): Property | undefined {
    if (!('attributes' in apiData)) {
      return undefined;
    }

    const mappedObject = {
      id: apiData.id,
      name: apiData.attributes.name,
      description: apiData.attributes.description,
      type: apiData.attributes.type ?? undefined,
      photo: apiData.attributes.photo ?? undefined,
    } as Property;

    if ('address' in apiData.attributes && apiData.attributes.address != null) {
      mappedObject.address = {
        street: apiData.attributes.address.street,
          streetNumber: apiData.attributes.address.streetNumber,
          zip: apiData.attributes.address.zip,
          city: apiData.attributes.address.city,
          country: apiData.attributes.address.country,
      };
    }

    if ('posts' in apiData.attributes && apiData.attributes.posts?.data != null) {
      if (!this.postMapper) {
        this.postMapper = this.injector.get(PostMapper);
        this.postMapper.setPropertyMapper(this);
      }
      mappedObject.posts = apiData.attributes.posts.data.map((post) => this.postMapper.toEntity(post));
    }

    if ('photo' in apiData.attributes && apiData.attributes.photo?.data != null) {
      if (!this.imageMapper) {
        this.imageMapper = this.injector.get(ImageMapper);
      }
      mappedObject.photo = this.imageMapper.toEntity(apiData.attributes.photo.data);
    }

    if ('units' in apiData.attributes && apiData.attributes.units?.data != null) {
      if (!this.unitMapper) {
        this.unitMapper = this.injector.get(UnitMapper);
        this.unitMapper.setPropertyMapper(this);
      }

      mappedObject.units = apiData.attributes.units.data.map((unit) => this.unitMapper.toEntity(unit));
    }

    if ('mainManager' in apiData.attributes && apiData.attributes.mainManager?.data != null) {
      if (!this.userMapper) {
        this.userMapper = this.injector.get(UserMapper);
        // this.userMapper.setPropertyMapper(this);
      }
      mappedObject.mainManager = this.userMapper.toEntity(apiData.attributes.mainManager.data);
    }

    if ('managers' in apiData.attributes && apiData.attributes.managers?.data != null) {
      if (!this.userMapper) {
        this.userMapper = this.injector.get(UserMapper);
        // this.userMapper.setPropertyMapper(this);
      }
      mappedObject.managers = apiData.attributes.managers.data.map(manager => this.userMapper.toEntity(manager));
    }

    if ('contacts' in apiData.attributes && apiData.attributes.contacts?.data != null) {
      if (!this.contactMapper) {
        this.contactMapper = this.injector.get(ContactMapper);
        this.contactMapper.setPropertyMapper(this);
      }
      mappedObject.contacts = apiData.attributes.contacts.data.map(contact => this.contactMapper.toEntity(contact));
    }

    return mappedObject;
  }

  setPostMapper(mapper: PostMapper) {
    this.postMapper = mapper;
  }

  setUnitMapper(mapper: UnitMapper) {
    this.unitMapper = mapper;
  }

  setImageMapper(mapper: ImageMapper) {
    this.imageMapper = mapper;
  }

  setUserMapper(mapper: UserMapper) {
    this.userMapper = mapper;
  }

  setContactMapper(mapper: ContactMapper) {
    this.contactMapper = mapper;
  }
}
