// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-bottom {
  border-bottom: 1px solid rgba(var(--ion-color-medium-rgb, rgb(0, 0, 0)), 40%);
}

.bold {
  font-weight: 700;
}

.w-100 {
  width: 100%;
}

.ion-card, ion-card {
  flex-grow: 1;
}

:host {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  height: -moz-fit-content;
  height: fit-content;
}

td {
  width: 75%;
}
td.app-label {
  width: 25%;
}

.clickable {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/contact-card/contact-card.component.scss"],"names":[],"mappings":"AAAA;EACE,6EAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,wBAAA;EACA,oBAAA;EACA,wBAAA;EAAA,mBAAA;AACF;;AAEA;EACE,UAAA;AACF;AACE;EACE,UAAA;AACJ;;AAGA;EACE,eAAA;AAAF","sourcesContent":[".border-bottom {\n  border-bottom: 1px solid rgba(var(--ion-color-medium-rgb, rgb(0, 0, 0)), 40%);\n}\n\n.bold {\n  font-weight: 700;\n}\n\n.w-100 {\n  width: 100%;\n}\n\n.ion-card, ion-card {\n  flex-grow: 1;\n}\n\n:host {\n  display: flex;\n  justify-content: stretch;\n  align-items: stretch;\n  height: fit-content;\n}\n\ntd {\n  width: 75%;\n\n  &.app-label {\n    width: 25%;\n  }\n}\n\n.clickable {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
