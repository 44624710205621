import { Injectable } from '@angular/core';
import * as qs from 'qs';

import { AuthenticationService } from '@app/auth/authentication.service';
import { StorageService } from '@core/storage/storage.service';
import { apiSettings, environment } from '@env/environment';
import { CapacitorHttp, HttpOptions } from '@capacitor/core';

type ApiMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  private readonly componentNameCache = 'ApiCache';

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly storageService: StorageService,
  ) {}

  private static getQueryString(parameters: object = {}): string | undefined {
    return Object.keys(parameters).length > 0 ? qs.stringify(parameters) : undefined;
  }

  /**
   * Get all items of a specific collection entity
   */
  public getItems(
    entity: string,
    parameters: object = {},
    cache = true,
  ): Promise<unknown[]> {
    // Turn off caching with `false`, flush cache with `null`
    if (cache || cache === null) {
      return this.getWithCaching(entity, parameters, cache === null);
    }

    return this.get(entity, parameters);
  }

  /**
   * Get item by ID of a specific collection entity
   */
  public getItem(
    entity: string,
    id: number,
    parameters: object = {},
    cache = true,
  ): Promise<unknown> {
    // Turn off caching with `false`, flush cache with `null`
    if (cache || cache === null) {
      return this.getWithCaching(`${entity}/${id}`, parameters, cache === null);
    }

    return this.get(`${entity}/${id}`, parameters);
  }

  /**
   * Get the item of a specific singleton entity
   */
  public getSingletonItem(
    entity: string,
    parameters: object = {},
    cache = true,
  ): Promise<unknown> {
    return this.getItems(entity, parameters, cache);
  }

  /**
   * Get count of all items of a specific collection entity
   */
  public getItemCount(
    entity: string,
    parameters: object = {},
    cache = true,
  ): Promise<number> {
    // Turn off caching with `false`, flush cache with `null`
    if (cache || cache === null) {
      return this.getWithCaching(`${entity}/count`, parameters, cache === null);
    }

    return this.get(`${entity}/count`, parameters);
  }

  /**
   * Get item by ID of a specific collection entity
   */
  public putItem(
    entity: string,
    id: number,
    body: any,
    parameters: object = {},
  ): Promise<unknown> {

    return this.put(`${entity}/${id}`, body, parameters);
  }

  /**
   * Fetch from the API with caching
   *
   * @Cached
   */
  public async getWithCaching(path: string, parameters: object = {}, flushCache = false): Promise<any> {
    const query: string = qs.stringify(parameters);

    const cache: unknown[] = await this.storageService.get(this.componentNameCache, [path, query]);
    const cachedAt: number = await this.storageService.get(this.componentNameCache, [path, query, 'cached_at'], 0);

    const currentTime: number = new Date().getTime();
    const timeDiff: number = currentTime - cachedAt;

    // Check if data is cached and not expired
    if (cache && environment.production && timeDiff <= apiSettings.cacheExpirationTime && !flushCache) {
      return cache;
    } else {
      // Fetch new data
      const items = await this.handleRequest(path, query);

      // Set new cache time
      this.storageService.set(this.componentNameCache, [path, query, 'cached_at'], currentTime)
        .catch((e: any) => console.error(e));

      // Return and cache fetched data
      return this.storageService.set(this.componentNameCache, [path, query], items)
        .catch((e: any) => console.error(e));
    }
  }

  /**
   * Fetch from the API without caching
   */
  public get(path: string, parameters: object = {}): Promise<any> {
    const query = ApiService.getQueryString(parameters);

    return this.handleRequest(path, query);
  }

  /**
   * Post an item to the API
   */
  public post(path: string, body: any, parameters: object = {}): Promise<any> {
    const query = ApiService.getQueryString(parameters);

    return this.handleRequest(path, query, 'POST', body);
  }

  /**
   * Put an item into the API
   */
  public put(path: string, body: any, parameters: object = {}): Promise<any> {
    const query = ApiService.getQueryString(parameters);

    return this.handleRequest(path, query, 'PUT', body);
  }

  /**
   * Delete an item from the API
   */
  public delete(path: string, parameters: object = {}): Promise<any> {
    const query = ApiService.getQueryString(parameters);

    return this.handleRequest(path, query, 'DELETE');
  }

  /**
   * Fetch from the API without caching
   */
  public async handleRequest(path: string, query = '', method: ApiMethod = 'GET', data?: any): Promise<any> {
    const url =
      `${apiSettings.baseUrl}/${path}` + (query !== '' ? `?${query}` : '');

    const token = await this.authenticationService.getToken();
    await this.authenticationService.validateLogin();

    const options: HttpOptions = {
      method,
      url,
      headers: {
        ...(token !== null && { Authorization: `Bearer ${token}` }),
        ...(data && data instanceof FormData ? { 'Content-Type': 'multipart/form-data' } : { 'Content-Type': 'application/json' }),
      },
      data,
    };

    const request = await CapacitorHttp.request(options);

    // Throw error on non-successful status
    if (request.status < 200 || request.status >= 300) {
      if (request.status === 401) {
        // internally try to reauthenticate

        const authRequest = await CapacitorHttp.request({
          headers: { Authorization: `Bearer ${token}` },
          url: `${apiSettings.baseUrl}/api/users/me`,
        });
        if (authRequest.status !== 200) {
          await this.authenticationService.logout();
          return;
        }
      }
      throw new Error(request.data.message ?? request.data.error?.message ?? request.data);
    }

    return request.data;
  }

  public async upload(path: string, data: FormData, method = 'POST', signal?: AbortSignal) {
    const url =
      `${apiSettings.baseUrl}/${path}`;

    const token = await this.authenticationService.getToken();
    await this.authenticationService.validateLogin();
    return fetch(url, {
      headers: {
        ...(token !== null && { Authorization: `Bearer ${token}` }),
      },
      method,
      body: data,
      signal
    });
  }

  /**
   * Clear all items from the cache
   */
  public clearItemCache(entity?: string): Promise<void> {
    return this.storageService.clear(this.componentNameCache, entity);
  }

}
