import { Component, Input, OnInit } from '@angular/core';
import { Contact } from '@app/@core/models/interfaces/contact.interface';
import { ModalController } from '@ionic/angular';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Logger } from '@core/logger/logger.service';

const logger = new Logger('a.@s.c.c.CompanyContactMaskComponent');

@Component({
  selector: 'app-company-contact-mask',
  templateUrl: './company-contact-mask.component.html',
  styleUrls: ['./company-contact-mask.component.scss'],
})
export class CompanyContactMaskComponent implements OnInit {

  public static readonly MODAL_ID = 'company-contact-mask-modal';

  @Input() contact?: Contact;

  form: UntypedFormGroup;

  constructor(
    private readonly modalController: ModalController,
    private readonly formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      position: [this.contact?.position ?? '', Validators.compose([Validators.required])],
      companyName: [this.contact?.companyName ?? '', Validators.compose([Validators.required])],
      contactName: [this.contact?.contactName ?? '', Validators.compose([Validators.required])],
      phoneNumber: [this.contact?.phoneNumber ?? '', Validators.compose([Validators.required])],
      email: [this.contact?.email ?? '', Validators.compose([Validators.email])],
      websiteUrl: [this.contact?.websiteUrl ?? ''],
      address: this.formBuilder.group({
        street: [this.contact?.address?.street ?? '', [Validators.required]],
        streetNumber: [this.contact?.address?.streetNumber ?? '', [Validators.required]],
        city: [this.contact?.address?.city ?? '', [Validators.required]],
        zip: [this.contact?.address?.zip ?? '', [Validators.required]],
        country: [{ value: 'Germany', disabled: true }],
      })
    });
  }

  async dismiss() {
    await this.modalController.dismiss(undefined, undefined, CompanyContactMaskComponent.MODAL_ID);
  }

  async submit() {
    if (this.form.disabled || this.form.invalid) {
      return;
    }
    this.form.disable();

    try {
      await this.modalController.dismiss(this.form.value, this.contact ? 'edit' : 'add', CompanyContactMaskComponent.MODAL_ID);
      this.form.reset();
    } catch (e) {
      logger.error(e);
    } finally {
      this.form?.enable();
    }

  }
}
