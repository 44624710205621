// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center-info-component {
  position: relative;
  width: 100%;
  height: calc(100vh - 192px);
}
.center-info-component aside {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  opacity: 0.5;
  text-align: center;
}
.center-info-component ion-icon {
  display: block;
  font-size: 6rem;
  margin-bottom: 0.5rem;
}
.center-info-component ion-text {
  font-size: 1.75rem;
}
.center-info-component p {
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/center-info/center-info.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,2BAAA;AACF;AACE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EAEA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;EAEA,YAAA;EACA,kBAAA;AADJ;AAIE;EACE,cAAA;EACA,eAAA;EACA,qBAAA;AAFJ;AAKE;EACE,kBAAA;AAHJ;AAME;EACE,eAAA;AAJJ","sourcesContent":[".center-info-component {\n  position: relative;\n  width: 100%;\n  height: calc(100vh - 192px);\n\n  aside {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n    padding: 1rem;\n\n    opacity: 0.5;\n    text-align: center;\n  }\n\n  ion-icon {\n    display: block;\n    font-size: 6rem;\n    margin-bottom: .5rem;\n  }\n\n  ion-text {\n    font-size: 1.75rem;\n  }\n\n  p {\n    font-size: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
