import { Injectable, Injector } from '@angular/core';
import { UserMapper } from '@core/models/map-struct/user.mapper';
import { ImageMapper } from '@core/models/map-struct/image.mapper';
import { Mapper } from '@core/models/map-struct/mapper.interface';
import { ChatRoom } from '@core/models/interfaces/chat-room.interface';

@Injectable({
  providedIn: 'root',
})
export class ChatRoomMapper implements Mapper<ChatRoom> {
  private userMapper: UserMapper;
  private imageMapper: ImageMapper;

  constructor(private readonly injector: Injector) {}

  toEntity(apiData: any): ChatRoom | undefined {
    if (!('attributes' in apiData)) {
      return undefined;
    }

    const mappedObject = {
      id: apiData.id,
      chatUid: apiData.attributes.chatUid,
      title: apiData.attributes.title,
      type: apiData.attributes.type,
      open: apiData.attributes.open,
    } as ChatRoom;

    if ('picture' in apiData.attributes && apiData.attributes.picture?.data != null) {
      if (!this.imageMapper) {
        this.imageMapper = this.injector.get(ImageMapper);
      }
      mappedObject.picture = this.imageMapper.toEntity(apiData.attributes.picture.data);
    }

    if ('owner' in apiData.attributes && apiData.attributes.owner?.data != null) {
      if (!this.userMapper) {
        this.userMapper = this.injector.get(UserMapper);
      }
      mappedObject.owner = this.userMapper.toEntity(apiData.attributes.owner.data);
    }

    if ('participants' in apiData.attributes && apiData.attributes.participants?.data != null) {
      if (!this.userMapper) {
        this.userMapper = this.injector.get(UserMapper);
      }
      mappedObject.participants = apiData.attributes.participants.data.map(manager => this.userMapper.toEntity(manager));
    }

    return mappedObject;
  }
}
