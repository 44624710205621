import { Mapper } from '@core/models/map-struct/mapper.interface';
import { Invitation } from '@core/models/interfaces/invitation.interface';
import { Injectable, Injector } from '@angular/core';
import { UserMapper } from '@core/models/map-struct/user.mapper';
import { UnitRelationshipMapper } from '@core/models/map-struct/unit-relationship.mapper';

@Injectable({
  providedIn: 'root'
})
export class InvitationMapper implements Mapper<Invitation> {

  private userMapper: UserMapper;
  private unitRelationshipMapper: UnitRelationshipMapper;

  constructor(private injector: Injector) {

  }

  toEntity(apiData: any): Invitation | undefined {
    if (!('attributes' in apiData)) {
      return undefined;
    }

    const mappedObject = {
      id: apiData.id ?? undefined,
      needsAction: apiData.needsAction ?? null,
      accepted: apiData.accepted ?? null,
      inviteToken: apiData.attributes.inviteToken,
      invitedAs: apiData.attributes.invitedAs,
    } as Invitation;

    if (!this.userMapper) {
      this.userMapper = this.injector.get(UserMapper);
    }

    if (!this.unitRelationshipMapper) {
      this.unitRelationshipMapper = this.injector.get(UnitRelationshipMapper);
    }

    if ('invitingManager' in apiData.attributes && apiData.attributes.invitingManager?.data != null) {
      mappedObject.invitingManager = this.userMapper.toEntity(apiData.attributes.invitingManager.data);
    }

    if ('invitedUser' in apiData.attributes && apiData.attributes.invitedUser?.data != null) {
      mappedObject.invitedUser = this.userMapper.toEntity(apiData.attributes.invitedUser.data);
    }

    if ('unitRelationship' in apiData.attributes && apiData.attributes.unitRelationship?.data != null) {
      mappedObject.unitRelationship = this.unitRelationshipMapper.toEntity(apiData.attributes.unitRelationship.data);
    }

    return mappedObject;
  }
}
