// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-padding {
  --inner-padding-end: 0;
  --inner-padding-start: 0;
  --padding-start: 0;
  --padding-end: 0;
}

ion-searchbar {
  padding-inline-start: 0;
  padding-inline-end: 0;
  margin-left: 0;
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/choose-contact-mask/choose-contact-mask.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,wBAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAGA;EACE,uBAAA;EACA,qBAAA;EACA,cAAA;EACA,eAAA;AAAF","sourcesContent":[".no-padding {\n  --inner-padding-end: 0;\n  --inner-padding-start: 0;\n  --padding-start: 0;\n  --padding-end: 0;\n\n}\n\nion-searchbar {\n  padding-inline-start: 0;\n  padding-inline-end: 0;\n  margin-left: 0;\n  margin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
