import { HasCache } from '@core/api/cache/has-cache.interface';
import { CacheService } from '@core/api/cache/cache.service';
import { Subscription } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { take } from 'rxjs/operators';

@Component({
  template: ''
})
export abstract class UsesCacheComponent implements HasCache, OnDestroy {

  cacheClearedSubscription: Subscription;
  isInitialized = false;

  protected constructor(protected cacheService: CacheService) {
    this.cacheClearedSubscription = this.createCacheSubscription();
  }

  public ngOnDestroy() {
    this.cacheClearedSubscription.unsubscribe();
    this.isInitialized = false;
  }

  public createCacheSubscription(): Subscription {
    if (this.cacheClearedSubscription) {
      this.cacheClearedSubscription.unsubscribe();
    }
    return this.cacheService.clearCacheStream.subscribe(async (value) => {
      if (!this.isInitialized) {
        return;
      }
      await this.onCacheCleared(value);
    });
  }

  public async clearCacheAndWait(): Promise<void> {
    this.cacheClearedSubscription.unsubscribe();
    await this.cacheService.clearCache();
    await this.cacheService.clearCacheStream.pipe(take(1)).toPromise();
    this.cacheClearedSubscription = this.createCacheSubscription();
  }

  abstract onCacheCleared(lastRefresh: Date): Promise<void> | void;
}
