import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '@app/auth/authentication.service';
import { Logger } from '@core/logger/logger.service';
import { NavController } from '@ionic/angular';

const log = new Logger('a.a.AuthenticationGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard  {

  constructor(
    private readonly router: Router,
    private readonly navController: NavController,
    private readonly authenticationService: AuthenticationService,
  ) {}

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (await this.authenticationService.isAuthenticated()) {
      return true;
    }

    log.debug('Not authenticated, redirecting and adding redirect URL...');
    await this.navController.navigateRoot(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
    return false;
  }

}
