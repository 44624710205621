import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from '@app/@core/api/api.service';
import { MediaService } from '@core/api/media/media.service';
import { nanoid } from 'nanoid';
import { ReplaySubject } from 'rxjs';
import { Logger } from '@core/logger/logger.service';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';


const logger = new Logger('a.@s.c.d.DropzoneComponent');

@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss'],
})
export class DropzoneComponent implements OnInit {
  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  @Input() reference: string;
  @Input() entity: string;
  @Input() id: string | number;
  @Input() field: string;
  @Input() single = false;
  @Output() filesUploaded = new EventEmitter<void>();
  @Output() didAbort = new EventEmitter<void>();

  files: any[] = [];
  private newFiles: ReplaySubject<any[]>;
  private pending = new Set<any>();
  private uploading = new Set<any>();
  private done = new Set<any>();
  private error = new Set<any>();
  private currentUploading: any;
  private abortController: AbortController;

  constructor(
    private mediaService: MediaService,
    private apiService: ApiService,
    private toastController: ToastController,
    private translateService :TranslateService,
  ) {
    this.abortController = new AbortController();
    this.newFiles = new ReplaySubject<any[]>();
    this.newFiles.subscribe((files) => {
      const toBePending = [];
      for (const file of files) {
        if (this.done.has(file.uniqueId)
          || this.error.has(file.uniqueId)
          || this.uploading.has(file.uniqueId)
          || this.pending.has(file.uniqueId)
        ) {
          return;
        }
        toBePending.push(file);
      }

      toBePending.forEach(f => this.pending.add(f.uniqueId));
      if (!this.currentUploading) {
        this.startUpload(toBePending).then();
      }
    });
  }

  get canUpload() {
    if (!this.single) {
      return true;
    }
    return !(this.pending.size > 0 || this.uploading.size > 0 || this.done.size > 0);
  }

  get isUploadingFile() {
    return this.pending.size > 0 || this.uploading.size > 0;
  }

  trackByFileId(index: number, element) {
    return element?.uniqueId ?? 'idx' + index;
  }

  ngOnInit() {}

  fileBrowseHandler($event: any) {
    this.prepareFilesList($event.target.files);
  }

  onFileDropped(files: any) {
    this.prepareFilesList(files);
  }

  /**
   * Convert Files list to normal array list
   *
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    if (!this.canUpload) {
      return;
    }
    const items = [];
    for (const item of files) {
      item.uniqueId = nanoid();
      items.push(item);
    }
    items.forEach(item => this.files.push(item));
    this.newFiles.next(items);

    this.fileDropEl.nativeElement.value = '';
  }

  isUploading(file: any) {
    return this.uploading.has(file.uniqueId);
  }

  isPending(file: any) {
    return this.pending.has(file.uniqueId);
  }

  isDone(file: any) {
    return this.done.has(file.uniqueId);
  }

  isError(file: any) {
    return this.error.has(file.uniqueId);
  }

  public abort() {
    const pending = [...this.pending];
    this.pending.clear();
    const uploading = [...this.uploading];
    this.uploading.clear();
    this.abortController.abort();
    [...pending, ...uploading].forEach((file) => this.error.add(file));
  }

  private async startUpload(files: any) {
    if (this.currentUploading) {
      return;
    }

    const translations = await firstValueFrom(this.translateService.get(['buttons.ok', 'errors.header.error-header']));

    try {
      this.currentUploading = this.apiService.get(`${this.entity}/${this.id}`, {
        populate: [
          this.field,
        ],
      });
      const response = await this.currentUploading;
      const fileIds = response.data?.attributes[this.field].data?.map((file) => file?.id).filter(Boolean);
      const existingFiles = fileIds && fileIds.length > 0 ? fileIds : undefined;

      for (const file of files) {
        this.pending.delete(file.uniqueId);
        this.uploading.add(file.uniqueId);
      }
      this.currentUploading = this.mediaService.uploadFiles(
        this.id,
        this.reference,
        this.field,
        files,
        existingFiles,
        undefined,
        this.abortController.signal,
      );
      this.currentUploading.then(() => {
        for (const file of files) {
          this.uploading.delete(file.uniqueId);
          this.done.add(file.uniqueId);
        }
      }).catch((e) => {

        logger.error(e);
        for (const file of files) {
          this.uploading.delete(file.uniqueId);
          this.error.add(file.uniqueId);
        }
        if (e.name === 'AbortError') {
          this.abortController = new AbortController();
          this.didAbort.emit();
        } else {
          this.toastController.create({
            header: translations['errors.header.error-header'],
            message: this.translateService.instant('errors.message.there-is-an-error-message', {error: e?.message ?? e}),
            duration: 5000,
            buttons: [translations['buttons.ok']],
            color: 'danger',
            position: 'top',
          }).then(t => t.present())
            .then();
        }
      }).finally(() => {
        this.currentUploading = undefined;
        if (this.pending.size > 0) {
          const pendingIds = [...this.pending];
          const pendingFiles = this.files.filter((file) => pendingIds.includes(file.uniqueId));
          this.startUpload(pendingFiles).then();
        }
        this.filesUploaded.emit();

        setTimeout(() => {
          for (const file of files) {
            if (this.done.has(file.uniqueId)) {
              this.done.delete(file.uniqueId);
              const index = this.files.findIndex((inFilesFile) => file.uniqueId === inFilesFile.uniqueId);
              if (index !== -1) {
                this.files.splice(index, 1);
              }
            }
          }
        }, 5000);
      });
    } catch (e: any) {
      logger.error(e);
    }
  }

  getTranslated(key: string){
    return this.translateService.get(key);
  }
}
