import { Injectable } from '@angular/core';
import { Mapper } from '@core/models/map-struct/mapper.interface';
import { MediaFormat } from '@core/models/datatypes/media-format.interface';

@Injectable({
  providedIn: 'root',
})
export class MediaMapper implements Mapper<MediaFormat> {
  toEntity(apiData: any): MediaFormat | undefined {
    if (!('attributes' in apiData)) {
      return undefined;
    }

    return {
      id: apiData.id,
      name: apiData.attributes.name,
      alternativeText: apiData.attributes.alternativeText,
      caption: apiData.attributes.caption,
      width: apiData.attributes.width,
      height: apiData.attributes.height,
      hash: apiData.attributes.hash,
      ext: apiData.attributes.ext,
      mime: apiData.attributes.mime,
      size: apiData.attributes.size,
      url: apiData.attributes.url,
      previewUrl: apiData.attributes.previewUrl,
      provider: apiData.attributes.provider,
      provider_metadata: apiData.attributes.provider_metadata,
      createdAt: 'created_at' in apiData.attributes ? apiData.attributes.created_at : apiData.attributes.createdAt,
      updatedAt: 'updated_at' in apiData.attributes ? apiData.attributes.updated_at : apiData.attributes.updatedAt,
    } as MediaFormat;
  }
}
