import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Post } from '@core/models/interfaces/post.interface';
import { getThumbnailUrl } from '@core/models/utils/media.utils';

@Component({
  selector: 'app-blackboard-post',
  templateUrl: './blackboard-post.component.html',
  styleUrls: ['./blackboard-post.component.scss'],
})
export class BlackboardPostComponent {

  @Input() post: Post;
  @Input() showProperty = false;
  @Input() privileged = false;

  @Output() deleteClicked = new EventEmitter<Post>();

  get creator() {
    return this.post.creator;
  }

  get avatar() {
    return this.post?.creator?.profilePicture ? getThumbnailUrl(this.post.creator.profilePicture) : '/assets/images/avatar.svg';
  }

  delete() {
    this.deleteClicked.emit(this.post);
  }

}
