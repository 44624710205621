import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { Document, DocumentCreateDto } from '@core/models/interfaces/document.interface';
import { format } from 'date-fns';
import { DocumentService } from '@core/api/document/document.service';
import { MediaService } from '@core/api/media/media.service';
import { UserService } from '@app/@core/api/user/user.service';
import { UserAssignMaskComponent } from '@shared/components/user-assign-mask/user-assign-mask.component';
import { CacheService } from '@core/api/cache/cache.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-document-mask',
  templateUrl: './document-mask.component.html',
  styleUrls: ['./document-mask.component.scss'],
})
export class DocumentMaskComponent implements OnInit {

  public static readonly MODAL_ID = 'document-mask-component';

  @Input() document?: Document;
  @Input() template = false;

  private documentForm: UntypedFormGroup;

  constructor(
    private readonly modalController: ModalController,
    private readonly toastController: ToastController,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly documentService: DocumentService,
    private readonly imageService: MediaService,
    private readonly userService: UserService,
    private readonly loadingController: LoadingController,
    private readonly cacheService: CacheService,
    private translateService :TranslateService,
  ) {
  }

  public get form(): UntypedFormGroup {
    return this.documentForm;
  }

  public async ngOnInit() {
    this.documentForm = this.createForm();
  }

  public async dismiss(): Promise<void> {
    await this.modalController.dismiss(
      {
        dismissed: true,
      },
      undefined,
      DocumentMaskComponent.MODAL_ID,
    );
  }

  public async submit(event: any): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    if (this.form.invalid || this.form.disabled) {
      return;
    }
    const loading = await this.loadingController.create({});
    await loading.present();
    this.form.disable();

    const {
      title,
      description,
      participants,
      file, fileUploaded,
    } = this.form.value;
    const date = format(new Date(), 'yyyy-MM-dd');

    const document = {
      ...this.document,
      title,
      description,
      participants,
      date,
    } as Document;
    delete document.file;

    try {
      let result;
      if (!this.document?.id) {
        result = await this.documentService.createDocument({
          title: document.title,
          description: document.description,
          participants: document.participants?.map(p => p.id) ?? [],
          date: document.date,
        }, this.template);
        await this.documentService.updateFile(result.id, fileUploaded, this.template);
      } else {
        result = await this.documentService.updateDocument(document, this.template);
        if (file !== this.document.file?.name) {
          if (this.document.file) {
            await this.imageService.deleteFile(this.document.file.id);
          }
          await this.documentService.updateFile(result.id, fileUploaded, this.template);
        }
      }

      await this.cacheService.clearCache();

      const name = (result?.data?.name
          ?? result?.data?.title
          ?? result?.data?.attributes?.name
          ?? result?.data?.attributes?.title)
        ?? 'Datei';

      await this.modalController.dismiss(name, 'success', DocumentMaskComponent.MODAL_ID);
      this.form.reset();
    } catch (e) {
      await this.modalController.dismiss(e, 'error', DocumentMaskComponent.MODAL_ID);
    } finally {
      this.form?.enable();
      await loading.dismiss();
    }
  }

  changeFile($event: any) {
    this.form.patchValue({ fileUploaded: $event.target.files[0] });
  }

  async assignUsers() {
    const userAssign = await this.modalController.create({
      component: UserAssignMaskComponent,
      id: UserAssignMaskComponent.MODAL_ID,
      componentProps: {
        assigned: this.form.get('participants')?.value ?? [],
      },
    });
    await userAssign.present();

    const { data, role } = await userAssign.onWillDismiss();
    if (role !== UserAssignMaskComponent.SUCCESS_ROLE) {
      return;
    }

    this.form.patchValue({ participants: data.assigned });
  }

  private createForm(): UntypedFormGroup {
    return this.formBuilder.group({
      title: [this.document?.title ?? '', Validators.compose([Validators.required, Validators.minLength(3)])],
      description: [this.document?.description ?? '', Validators.compose([Validators.minLength(3)])],
      date: [{ value: this.document?.date ?? new Date().toLocaleDateString(), disabled: true }, Validators.compose([Validators.required])],
      participants: [this.document?.participants?.map(p => p.id)],
      file: [this.document?.file?.name ?? null, this.document?.file?.name ? [] : [Validators.required]],
      fileUploaded: [null, this.document?.file?.name ? [] : [Validators.required]],
    });
  }

  getTranslated(key: string){
    return this.translateService.get(key);
  }
}
