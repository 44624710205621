// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card-title h1 {
  border-bottom: 2px solid var(--ion-color-medium);
  display: inline-block;
  padding-left: 1em;
  margin-left: -1em;
  padding-right: 1em;
}

.input-item {
  border: 1px solid var(--ion-color-light);
  --padding-start: 1em !important;
  --padding-end: 1em !important;
  --padding-top: 1em !important;
  --padding-bottom: 1em !important;
}
.input-item.ion-touched.ion-invalid {
  border: 1px solid var(--ion-color-danger);
}

.iconed-button {
  --border-radius: 0;
  --padding-start: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}
.iconed-button .ion-background-primary {
  background: var(--ion-color-medium);
  color: var(--ion-color-medium-contrast);
}
.iconed-button div[slot=start] {
  margin-right: 1.1em;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/blackboard-mask/blackboard-mask.component.scss"],"names":[],"mappings":"AACE;EACE,gDAAA;EACA,qBAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;AAAJ;;AAIA;EACE,wCAAA;EACA,+BAAA;EACA,6BAAA;EACA,6BAAA;EACA,gCAAA;AADF;AAGE;EACE,yCAAA;AADJ;;AAKA;EACE,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAFF;AAIE;EACE,mCAAA;EACA,uCAAA;AAFJ;AAKE;EACE,mBAAA;AAHJ","sourcesContent":["ion-card-title {\n  h1 {\n    border-bottom: 2px solid var(--ion-color-medium);\n    display: inline-block;\n    padding-left: 1em;\n    margin-left: -1em;\n    padding-right: 1em;\n  }\n}\n\n.input-item {\n  border: 1px solid var(--ion-color-light);\n  --padding-start: 1em !important;\n  --padding-end: 1em !important;\n  --padding-top: 1em !important;\n  --padding-bottom: 1em !important;\n\n  &.ion-touched.ion-invalid {\n    border: 1px solid var(--ion-color-danger);\n  }\n}\n\n.iconed-button {\n  --border-radius: 0;\n  --padding-start: 0;\n  --padding-top: 0;\n  --padding-bottom: 0;\n\n  .ion-background-primary {\n    background: var(--ion-color-medium);\n    color: var(--ion-color-medium-contrast);\n  }\n\n  div[slot=\"start\"] {\n    margin-right: 1.1em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
