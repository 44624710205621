import { ApiService } from '@core/api/api.service';
import { Injectable } from '@angular/core';
import { MeterMapper } from '@core/models/map-struct/meter.mapper';
import { Pagination } from '@core/models/datatypes/pagination.interface';
import { Meter } from '@core/models/interfaces/meter.interface';

@Injectable({
  providedIn: 'root',
})
export class MeterService {
  private readonly entityName = 'api/meters';
  private readonly entityRef = 'api::meter.meter';

  constructor(
    private readonly apiService: ApiService,
    private readonly meterMapper: MeterMapper,
  ) {}

  public async getMeters(page = 1, pageSize = 25): Promise<{ data: Meter[]; meta: Pagination }> {
    return this.apiService.getWithCaching(this.entityName, {
      populate: [
        'meterReadings',
        'meterReadings.addedBy',
      ],
      pagination: {
        page,
        pageSize,
      },
      // sort: ['meterReading.date:desc'],
    }).then((res) => ({
      data: res.data.map((meter) => this.meterMapper.toEntity(meter)),
      meta: 'pagination' in res.meta ? res.meta.pagination : res.meta as Pagination,
    }));
  }

  public async getMeterByUnitId(unitId: number): Promise<Meter[] | undefined> {
    return this.apiService.getWithCaching(this.entityName, {
      filters: {
        unit: {
          id: {
            $eq: unitId
          }
        }
      },
      populate: [
        'unit'
      ],
    }).then(res => res.data.map((meter) => this.meterMapper.toEntity(meter)));
  }

  async createMeterForUnit(unitId: number, identifier: string, type: string) {
    return this.apiService.post(this.entityName, {
      data: {
        unit: unitId,
        identifier,
        type
      }
    });
  }

  async updateMeterById(id: number, data: Partial<Meter>) {
    return this.apiService.put(`${this.entityName}/${id}`, {
      data
    });
  }

  async deleteMeterById(id: number) {
    return this.apiService.delete(`${this.entityName}/${id}`);
  }

  public async getContentType() {
    return this.apiService.get(`api/content-type-builder/content-types/${this.entityRef}`);
  }

  public async getMeterTypes(): Promise<string[]> {
    const contentType = await this.getContentType();
    return contentType.data.schema.attributes.type.enum;
  }
}
