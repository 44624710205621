import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { parseISO } from 'date-fns';

export const isDateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  try {
    parseISO(control.value);
    return null;
  } catch (e) {
    return {parse: 'Cannot parse ISO'};
  }
};
