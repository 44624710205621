import { Mapper } from '@core/models/map-struct/mapper.interface';
import { Post } from '@core/models/interfaces/post.interface';
import { Injectable, Injector } from '@angular/core';
import { PropertyMapper } from '@core/models/map-struct/property.mapper';
import { UserMapper } from '@core/models/map-struct/user.mapper';

@Injectable({
  providedIn: 'root',
})
export class PostMapper implements Mapper<Post> {

  private propertyMapper: PropertyMapper;
  private userMapper: UserMapper;

  constructor(private injector: Injector) {}

  toEntity(apiData: any): Post | undefined {
    if (!('attributes' in apiData)) {
      return undefined;
    }

    const mappedObject = {
      id: apiData.id,
      title: apiData.attributes.title,
      message: apiData.attributes.message,
      createdAt: apiData.attributes.createdAt,
      updatedAt: apiData.attributes.updatedAt,
      date: apiData.attributes.date,
    } as Post;

    if ('property' in apiData.attributes && apiData.attributes.property?.data != null) {
      if (!this.propertyMapper) {
        this.propertyMapper = this.injector.get(PropertyMapper);
        this.propertyMapper.setPostMapper(this);
      }

      mappedObject.property = this.propertyMapper.toEntity(apiData.attributes.property.data);
    }

    if ('creator' in apiData.attributes && apiData.attributes.creator?.data != null) {
      if (!this.userMapper) {
        this.userMapper = this.injector.get(UserMapper);
        this.userMapper.setPostMapper(this);
      }

      mappedObject.creator = this.userMapper.toEntity(apiData.attributes.creator.data);
    }

    return mappedObject;
  }

  setPropertyMapper(mapper: PropertyMapper): void {
    this.propertyMapper = mapper;
  }

  setUserMapper(mapper: UserMapper): void {
    this.userMapper = mapper;
  }
}
