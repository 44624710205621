import { Injectable } from '@angular/core';
import { Unit } from '@core/models/interfaces/unit.interface';
import { ApiService } from '@core/api/api.service';
import { ProblemMapper } from '@core/models/map-struct/problem.mapper';
import { Property } from '@core/models/interfaces/property.interface';
import { Pagination } from '@core/models/datatypes/pagination.interface';
import { Problem, ProblemCounts } from '@core/models/interfaces/problem.interface';
import { UserService } from '@core/api/user/user.service';
import { User } from '@core/models/interfaces/user.interface';
import { MediaService } from '@core/api/media/media.service';

@Injectable({
  providedIn: 'root',
})
export class ProblemService {
  private readonly entityName = 'api/problems';
  private readonly entityRef = 'api::problem.problem';

  constructor(
    private readonly apiService: ApiService,
    private readonly problemMapper: ProblemMapper,
    private readonly mediaService: MediaService,
    private readonly userService: UserService,
  ) {}

  public async getProblemsByUnitId(unitId: number, page = 1, pageSize = 10): Promise<{
    data: Problem[];
    meta: Pagination;
  }> {
    return this.apiService.getWithCaching(this.entityName, {
      populate: [
        'reporter',
        'unit',
        'property',
      ],
      filters: {
        $or: [
          { unit: unitId },
          { unit: { id: { $null: true } }, property: { units: unitId } },
        ],
      },
      pagination: {
        page,
        pageSize,
      },
      sort: ['createdAt:desc'],
    })
      .then((res) => ({
        data: res.data.map((problem) => this.problemMapper.toEntity(problem)),
        meta: 'pagination' in res.meta ? res.meta.pagination : res.meta as Pagination,
      }));
  }

  public async getProblemsByPropertyId(propertyId: number, page = 1, pageSize = 10): Promise<{
    data: Problem[];
    meta: Pagination;
  }> {
    return this.apiService.getWithCaching(this.entityName, {
      populate: [
        'reporter',
        'property',
        'unit'
      ],
      filters: {
        property: propertyId,
        unit: {id: {$null: true}},
      },
      pagination: {
        page,
        pageSize,
      },
      sort: ['createdAt:desc'],
    })
      .then((res) => ({
        data: res.data.map((problem) => this.problemMapper.toEntity(problem)),
        meta: 'pagination' in res.meta ? res.meta.pagination : res.meta as Pagination,
      }));
  }

  public async getProblemsWithStatusByUnitId(unitId: number, page = 1, pageSize = 10): Promise<{
    data: Problem[];
    meta: Pagination;
  }> {
    return this.getProblemsByUnitId(unitId, page, pageSize).then(async ({ data, meta }) => {

      const requests = data.map((problem) => this.getStatus(problem));

      const status = await Promise.all(requests);
      const newData = data.map((problem, index) => ({
        ...problem,
        status: status[index] ?? 'open',
      }));

      return { data: newData, meta };
    });
  }

  public async getProblemsWithStatusByPropertyId(propertyId: number, page = 1, pageSize = 10): Promise<{
    data: Problem[];
    meta: Pagination;
  }> {
    return this.getProblemsByPropertyId(propertyId, page, pageSize).then(async ({ data, meta }) => {

      const requests = data.map((problem) => this.getStatus(problem));

      const status = await Promise.all(requests);
      const newData = data.map((problem, index) => ({
        ...problem,
        status: status[index] ?? 'open',
      }));

      return { data: newData, meta };
    });
  }

  createProblemForUnit(unit: Unit, userId: number, description: string) {
    return this.apiService.post(this.entityName, {
      data: {
        reporter: userId,
        unit: unit.id,
        property: unit.property?.id,
        body: description,
      },
    });
  }

  public async uploadFiles(id: number, file: string | string[] | File | Blob | FileList, existingFiles?: number[]) {
    return this.mediaService.uploadFiles(id,
      this.entityRef,
      'attachments', file, existingFiles);
  }

  async createProblemForProperty(property: Property, userId: number, description: string) {
    return this.apiService.post(this.entityName, {
      data: {
        reporter: userId,
        property: property.id,
        body: description,
      },
    });
  }

  public async getStatus(problem: Problem) {
    try {
      const response = await this.apiService.getWithCaching(`${this.entityName}/${problem.id}/status`, undefined);
      return response?.status ?? 'open';
    } catch (e) {
      return 'open';
    }
  }

  async getProblems(
    isManager = false,
    field = 'createdAt',
    mode = 'desc',
    page = 1,
    pageSize = 25,
  ) {
    const user = await this.userService.getMe();
    const sort = [`${field}:${mode}`];
    if (field !== 'id') {
      sort.push('id:asc');
    }

    let filters;

    if (isManager) {
      filters = {
        $or: [
          {
            reporter: user.id,
          },
          {
            property: {
              $or: [
                {
                  mainManager: user.id,
                }, {
                  managers: user.id,
                },
              ],
            },
          },
          {
            unit: {
              property: {
                $or: [
                  {
                    mainManager: user.id,
                  }, {
                    managers: user.id,
                  },
                ],
              },
            },
          },
        ],
      };
    } else {
      filters = {
        $or: [
          {
            reporter: user.id,
          },
          {
            unit: {
              relationships: {
                $or: [
                  { proprietor: user.id },
                  { rentee: user.id },
                ],
              },
            },
          },
          {
            unit: {
              id: { $null: true },
            },
            property: {
              units: {
                relationships: {
                  $or: [
                    { proprietor: user.id },
                    { rentee: user.id },
                  ],
                },
              },
            },
          },
        ],
      };
    }

    return this.apiService.getWithCaching(this.entityName, {
      populate: [
        'reporter',
        'unit',
        'property',
        'property.address',
        'attachments',
      ],
      filters,

      pagination: {
        page,
        pageSize,
      },
      sort,
    })
      .then((res) => ({
        data: res.data.map((problem) => this.problemMapper.toEntity(problem)),
        meta: 'pagination' in res.meta ? res.meta.pagination : res.meta as Pagination,
      }));
  }

  public async getProblemsWithStatus(
    isManager = false,
    field = 'createdAt',
    mode = 'desc',
    page = 1,
    pageSize = 10,
  ): Promise<{
    data: Problem[];
    meta: Pagination;
  }> {
    return this.getProblems(isManager, field, mode, page, pageSize).then(async ({ data, meta }) => {

      const requests = data.map((problem) => this.getStatus(problem));

      const status = await Promise.all(requests);
      const newData = data.map((problem, index) => ({
        ...problem,
        status: status[index] ?? 'open',
      }));

      return { data: newData, meta };
    });
  }

  async updateProblem(id: number, description: string) {
    return this.apiService.put(`${this.entityName}/${id}`, {
      data: {
        body: description,
      },
    });
  }

  async deleteProblem(id: number) {
    return this.apiService.delete(`${this.entityName}/${id}`);
  }

  async getProblemById(id: string, user: User, isManager: boolean): Promise<Problem | undefined> {
    let managerFilter;
    if (isManager) {
      managerFilter = [
        {
          property: {
            $or: [
              {
                mainManager: user.id,
              }, {
                managers: user.id,
              },
            ],
          },
        },
        {
          unit: {
            property: {
              $or: [
                {
                  mainManager: user.id,
                }, {
                  managers: user.id,
                },
              ],
            },
          },
        },
      ];
    } else {
      managerFilter = [
        {
          unit: {
            relationships: {
              $or: [
                { proprietor: user.id },
                { rentee: user.id },
              ],
            },
          },
        },
        {
          unit: {
            id: { $null: true },
          },
          property: {
            units: {
              relationships: {
                $or: [
                  { proprietor: user.id },
                  { rentee: user.id },
                ],
              },
            },
          },
        },
      ];
    }
    const filters = {
      id,
      $or: [
        { reporter: user.id },
        ...managerFilter,
      ],
    };
    return this.apiService.getWithCaching(`${this.entityName}`, {
        filters,
        populate: [
          'reporter',
          'reporter.profilePicture',
          'unit',
          'unit.relationships',
          'unit.relationships.rentee',
          'unit.relationships.rentee.profilePicture',
          'property',
          'property.address',
          'property.photo',
          'attachments',
        ],
      },
    ).then((res) => (res.data.length ? this.problemMapper.toEntity(res.data[0]) : undefined));
  }

  async getProblemByIdWithStatus(id: string, user: User, isManager: boolean): Promise<Problem | undefined> {
    return this.getProblemById(id, user, isManager).then(async (data) => {
      if (data === undefined) {
        return undefined;
      }
      const status = await this.getStatus(data);

      return {
        ...data,
        status: status ?? 'open',
      } as Problem;
    });
  }

  async getProblemCountByPropertyId(id: number): Promise<ProblemCounts> {
    return this.apiService.get(`${this.entityName}/count-by-property/${id}`);
  }

}
