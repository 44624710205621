import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DndDirective } from '@shared/directives/dnd.directive';

const exports = [
  DndDirective,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: exports,
  exports,
})
export class DirectivesModule {}
