import { Injectable } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { PropertyMapper } from '@core/models/map-struct/property.mapper';
import { Property } from '@core/models/interfaces/property.interface';
import { Pagination } from '@app/@core/models/datatypes/pagination.interface';
import { UserService } from '../user/user.service';
import { MediaService } from '@core/api/media/media.service';

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  private readonly entityName = 'api/properties';
  private readonly entityRef = 'api::property.property';

  constructor(
    private readonly apiService: ApiService,
    private readonly mediaService: MediaService,
    private readonly userService: UserService,
    private readonly propertyMapper: PropertyMapper,
  ) {}

  public async getPropertyById(id: number, additionalPopulations: string[] = []) {
    return this.apiService.getWithCaching(`${this.entityName}/${id}`, {
      populate: [
        'address',
        'photo',
        'mainManager',
        'mainManager.employeeIn',
        'mainManager.address',
        'mainManager.profilePicture',
        'managers',
        'managers.profilePicture',
        'units',
        'units.relationships',
        'units.relationships.rentee',
        'units.relationships.rentee.profilePicture',
        'units.relationships.proprietor',
        'units.relationships.proprietor.profilePicture',
        ...additionalPopulations,
      ],
    }).then(res => this.propertyMapper.toEntity(res.data));
  }

  public async getAllProperties(): Promise<Property[]> {
    const properties = [];
    let hasMore = false;
    let page = 1;
    const pageSize = 100;
    do {
      const response = await this.apiService.getWithCaching(this.entityName, {
        pagination: {
          page,
          pageSize,
        },
      });
      page++;
      const data = response.data.map(post => this.propertyMapper.toEntity(post));
      properties.push(...data);
      const meta = ('pagination' in response.meta ? response.meta.pagination : response.meta) as Pagination;
      hasMore = meta.pageCount >= page;
    } while(hasMore);
    return properties;
  }

  public async getProperties(search: string = undefined, fuzzySearch = false, page = 1, pageSize = 25): Promise<{
    data: Property[];
    meta: Pagination;
  }> {
    let filter;

    if (search !== undefined) {
      const parts = search.trim().split(' ');
      const searches: any[] = [
        { name: { $containsi: search } },
        { description: { $containsi: search } },
        {
          address: {
            $or: [
              { street: { $containsi: search } },
              { streetNumber: { $containsi: search } },
              { zip: { $containsi: search } },
              { city: { $containsi: search } },
              { country: { $containsi: search } },
            ],
          },
        },
      ];
      if (fuzzySearch && parts.length > 1) {
        const more = parts.splice(5).join(' ');
        if (more) {
          parts.push(more);
        }
        parts.forEach((part) => {
          searches.push(
            ...[
              { name: { $containsi: part } },
              { description: { $containsi: part } },
              {
                address: {
                  $or: [
                    { street: { $containsi: part } },
                    { streetNumber: { $containsi: part } },
                    { zip: { $containsi: part } },
                    { city: { $containsi: part } },
                    { country: { $containsi: part } },
                  ],
                },
              },
            ],
          );
        });
      }

      filter = {
        $or: searches,
      };
    }

    return this.apiService.getWithCaching(
      this.entityName,
      {
        populate: [
          'address',
          'photo',
          'units',
          'units.relationships',
          'units.relationships.rentee',
          'units.relationships.rentee.profilePicture',
        ],
        filters: filter,
        pagination: {
          page,
          pageSize,
        },
      },
    ).then((res) => ({
      data: res.data.map(
        (property) => this.propertyMapper.toEntity(property),
      ),
      meta: ('pagination' in res.meta ? res.meta.pagination : res.meta) as Pagination,
    }));
  }

  public async updateProperty(property: Property) {
    let data: any = {
      ...property,
    };

    if (property.managers) {
      data = { ...data, managers: property.managers?.map(m => m.id) ?? [] };
    }

    if (property.mainManager) {
      data = { ...data, mainManager: property.mainManager.id };
    }

    delete data.units;
    delete data.posts;
    delete data.contacts;

    return this.apiService.put(`${this.entityName}/${property.id}`, {
      data,
    });
  }

  public async createProperty(property: Property) {
    const me = await this.userService.getMe();
    return this.apiService.post(`${this.entityName}`, {
      data: {
        ...property,
        mainManager: me.id,
        managers: [me.id],
      },
    });
  }

  public async deleteProperty(property: Property) {
    return this.apiService.delete(`${this.entityName}/${property.id}`);
  }

  public async updatePhoto(id: number, photo: string | File | Blob, filename: string) {
    return this.mediaService.uploadFiles(id, this.entityRef, 'photo', photo, undefined, filename);
  }

  public async getContentType() {
    return this.apiService.getWithCaching(`api/content-type-builder/content-types/${this.entityRef}`);
  }

  public async getPropertyTypes(): Promise<string[]> {
    const contentType = await this.getContentType();
    return contentType.data.schema.attributes.type.enum;
  }

  public async addManager(property: Property, userId: number) {
    return this.apiService.put(`${this.entityName}/${property.id}`, {
      data: {
        managers: [
          ...property.managers.map(m => m.id),
          userId,
        ],
      },
    });
  }

  async removeManager(property: Property, id: number) {
    return this.apiService.put(`${this.entityName}/${property.id}`, {
      data: {
        managers: property.managers.map(m => m.id).filter(mid => id !== mid),
      },
    });
  }

  async getPropertiesWhereUserIsManager(id: number, page = 1, pageSize = 25) {
    return this.apiService.getWithCaching(
      this.entityName,
      {
        populate: [
          'address',
          'photo',
          'units',
          'units.relationships',
          'units.relationships.rentee',
          'units.relationships.rentee.profilePicture',
        ],
        filters: {
          managers: id,
        },
        pagination: {
          page,
          pageSize,
        },
      },
    ).then((res) => ({
      data: res.data.map(
        (property) => this.propertyMapper.toEntity(property),
      ),
      meta: ('pagination' in res.meta ? res.meta.pagination : res.meta) as Pagination,
    }));
  }

  async findPropertiesFromUser(id: number, page = 1, pageSize = 25){
    return this.apiService.getWithCaching(this.entityName, {
      filters: {
        units: {
          relationships: {
            $or: [
              { rentee: id },
              { proprietor: id },
            ],
          },
        },
      },
      populate: [
        'address',
        'photo',
      ],
      pagination: {
        page,
        pageSize,
      },
    }).then((res => ({
        data: res.data.map(
          (unit) => this.propertyMapper.toEntity(unit),
        ),
        meta: ('pagination' in res.meta ? res.meta.pagination : res.meta) as Pagination,
      })
    ));
  }

  async updatePropertyContactsById(propertyId: number, ids: number[]) {
    return this.apiService.put(`${this.entityName}/${propertyId}`, {
      data: {
        contacts: ids,
      },
    });
  }
}
