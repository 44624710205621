import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Contractor } from '@core/models/interfaces/contractor.interface';
import { User } from '@core/models/interfaces/user.interface';
import { getImageUrl, getThumbnailUrlOrPlaceholder } from '@core/models/utils/media.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contractor-company-card',
  templateUrl: './contractor-company-card.component.html',
  styleUrls: ['./contractor-company-card.component.scss'],
})
export class ContractorCompanyCardComponent {

  @Input() contractor: Contractor;
  @Output() edit = new EventEmitter<Contractor>();
  @Output() delete = new EventEmitter<Contractor>();

  private translateService = inject(TranslateService);

  get acceptedInvite() {
    return this.contractor?.isUser?.confirmed ?? undefined;
  }

  getUserAvatar(user?: User) {
    return getImageUrl(user?.profilePicture) ?? getThumbnailUrlOrPlaceholder(user?.profilePicture);
  }

  editContractor() {
    this.edit.emit(this.contractor);
  }

  deleteContractor() {
    this.delete.emit(this.contractor);
  }

  getTranslated(key: string){
    return this.translateService.get(key);
  }
}
