import { Component, Input, OnInit } from '@angular/core';
import { Contact } from '@core/models/interfaces/contact.interface';
import { ContactService } from '@core/api/contact/contact.service';
import { ModalController } from '@ionic/angular';
import { Property } from '@core/models/interfaces/property.interface';
import { Pagination } from '@core/models/datatypes/pagination.interface';

@Component({
  selector: 'app-choose-contact-mask',
  templateUrl: './choose-contact-mask.component.html',
  styleUrls: ['./choose-contact-mask.component.scss'],
})
export class ChooseContactMaskComponent implements OnInit {

  @Input() contact?: Contact;
  @Input() fromProperty?: Property;

  protected contactsFromProperty: Contact[] = [];
  protected contacts: Contact[] = [];

  protected searchTerm = '';
  protected isLoading = false;
  protected pagination?: Pagination;
  protected currentPage = 1;
  private pageSize = 100;

  constructor(
    private contactService: ContactService,
    private modalController: ModalController,
  ) {

  }

  async ngOnInit() {
    await this.resetSearch();
  }



  dismiss() {
    return this.modalController.dismiss()
  }

  chooseContact(contact: Contact) {
    return this.modalController.dismiss(contact, 'select');
  }

  async resetSearch() {
    this.isLoading = true;
    this.searchTerm = '';
    this.currentPage = 1;
    const result = await this.contactService.getAll(undefined, false, this.currentPage, this.pageSize);
    this.contacts = result.contacts;
    this.pagination = result.pagination;
    if (this.fromProperty) {
      this.contactsFromProperty = await this.contactService.getContactsByPropertyId(this.fromProperty.id)
      for(const contact of this.contactsFromProperty) {
        const idx = this.contacts.findIndex(c => contact.id === c.id);
        if (idx !== -1) {
          this.contacts.splice(idx, 1);
        }
      }
    }
    this.isLoading = false;
  }

  async search() {
    const result = await this.contactService.getAll(this.searchTerm, false, this.currentPage, this.pageSize);
    this.contacts = result.contacts;
    this.pagination = result.pagination;
  }

  async goToNextPage() {
    if (!this.pagination) {
      return;
    }

    if (this.pagination.page === this.pagination.pageCount) {
      return;
    }
    this.currentPage++;
    await this.search();
  }

  async goToPreviousPage() {
    if (!this.pagination) {
      return;
    }
    if (this.pagination.page === 1) {
      return;
    }
    this.currentPage--;
    await this.search();
  }

  async changeAmountOfItems($event: number) {
    if (!this.pagination) {
      return;
    }
    if ($event === this.pagination.pageSize) {
      return;
    }

    this.currentPage = 1;
    this.pageSize = $event;
    await this.search();
  }

  async goToLastPage() {
    if (!this.pagination) {
      return;
    }
    if (this.pagination.page === this.pagination.pageCount) {
      return;
    }

    this.currentPage = this.pagination.pageCount;
    await this.search();
  }

  async goToFirstPage() {
    if (!this.pagination) {
      return;
    }
    if (this.pagination.page === 1) {
      return;
    }
    this.currentPage = 1;
    await this.search();
  }
}
