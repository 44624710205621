// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-row:hover {
  background-color: #E8EAF6;
  cursor: pointer;
}
.mat-row:active {
  background-color: var(--ion-color-primary-shade);
  color: var(--ion-color-primary-contrast);
  cursor: pointer;
}

.mat-cell, .mat-header-cell {
  padding: 0.5rem;
}

table {
  min-width: 100%;
}

.position-absolute {
  position: absolute;
  width: 100%;
}

.link {
  cursor: pointer;
}

.payment-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;
}

.mat-mdc-raised-button:not(:disabled) {
  background-color: var(--ion-color-dark) !important;
  color: var(--ion-color-dark-contrast) !important;
}

.mat-mdc-raised-button {
  margin: 5px 5px;
  min-width: 7rem;
}

.bold {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/@shared/components/relationship-mask/relationship-mask.component.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;EACA,eAAA;AAAJ;AAGE;EACE,gDAAA;EACA,wCAAA;EACA,eAAA;AADJ;;AAKA;EACE,eAAA;AAFF;;AAKA;EACE,eAAA;AAFF;;AAKA;EACE,kBAAA;EACA,WAAA;AAFF;;AAKA;EACE,eAAA;AAFF;;AAOA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;EACA,uBAAA;AAJF;;AAQA;EACE,kDAAA;EACA,gDAAA;AALF;;AAOA;EACE,eAAA;EACA,eAAA;AAJF;;AAQA;EACE,iBAAA;AALF","sourcesContent":[".mat-row {\n  &:hover {\n    background-color: #E8EAF6;\n    cursor: pointer;\n  }\n\n  &:active {\n    background-color: var(--ion-color-primary-shade);\n    color: var(--ion-color-primary-contrast);\n    cursor: pointer;\n  }\n}\n\n.mat-cell, .mat-header-cell {\n  padding: .5rem;\n}\n\ntable {\n  min-width: 100%;\n}\n\n.position-absolute {\n  position: absolute;\n  width: 100%;\n}\n\n.link {\n  cursor: pointer;\n}\n\n//mat component style in global.scss\n\n.payment-radio-group{\n  display: flex;\n  flex-direction: column;\n  margin: 15px 0;\n  align-items: flex-start;\n}\n\n\n.mat-mdc-raised-button:not(:disabled) {\n  background-color: var(--ion-color-dark) !important;\n  color: var(--ion-color-dark-contrast) !important;\n}\n.mat-mdc-raised-button {\n  margin: 5px 5px;\n  min-width: 7rem;\n\n}\n\n.bold {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
